import { createBrowserHistory } from "history";
import resolvePathname from "resolve-pathname";
const history = createBrowserHistory();

const stripTrailingSlash = (path) =>
  path.charAt(0) === "/" ? path.substr(1) : path;

function resolvePath(path) {
  const { pathname } = this.location;
  return stripTrailingSlash(resolvePathname(path, `${pathname}/`));
}

function pushUp({ search, state, pathname = ".." } = {}) {
  return this.push({
    pathname,
    search,
    state,
  });
}

function replaceUp({ search, state, pathname = ".." } = {}) {
  return this.replace({
    pathname: this.resolvePath(pathname),
    search,
    state,
  });
}

history.pushUp = pushUp.bind(history);
history.replaceUp = replaceUp.bind(history);
history.resolvePath = resolvePath.bind(history);

export default history;
