import {useQuery} from "react-query";
import {useClient} from "../../../hooks";

export const useListTeamQuery = ({ params, ...config } = {}) => {
  const client = useClient();

  return useQuery({
    queryKey: ['team', {
      params
    }],
    queryFn: (...queryKey) => client.get(...queryKey).get('data'),
    ...config,
  });
}