import React from "react";
import { Page } from "../../../components/common";
import { Step1, Step2, Step3, Step4 } from "./components";
import { useClient } from "../../../hooks";
import { get } from "lodash";
import { Alert } from "reactstrap";
import { useApplicationFormQuery } from "./hooks";
import {useSteps} from "../hooks";
import {handleNonFieldErrors} from "../util";

const steps = [Step1, Step2, Step3, Step4];

export const ApplicationFormView = ({ match }) => {
  const { id } = match.params;
  const url = `application_forms/${id}`;
  const client = useClient();
  const { nextStep, previousStep, step } = useSteps(0, steps);
  const { error, data, refetch } = useApplicationFormQuery(url);

  const container = (children) => (
    <Page title="Make Lunch Core Team/Coordinator Application Form">
      {children}
    </Page>
  );

  if (error && get(error, "response.status") === 404) {
    return container(
      <Alert color="warning">This application form link is not valid.</Alert>
    );
  }

  if (!data) return null;

  async function onSubmit(x) {
    try {
      await client.patch(url, x);
    } catch (ex) {
      handleNonFieldErrors(ex);
    }
    await refetch();
    nextStep();
  }

  function onBack() {
    previousStep();
  }

  if (data.submitted) {
    return container(
      <>
        <h3 className="tw-font-medium tw-text-xl tw-mb-4">Thanks!</h3>
        <p>
          Thank you for taking the time to complete the application. One of our
          team will be in touch shortly.
        </p>
        <p>
          Please do not hesitate to contact us at{" "}
          <a href="mailto:makelunch.tlg.org.uk">makelunch.tlg.org.uk</a> if you
          have any questions.
        </p>
      </>
    );
  }

  return container(
    <>
      <p>
        Ensuring clubs operate safely is one of our
        core values here at TLG Make Lunch and therefore we do all we can to
        ensure that teams are recruited as safely as possible. As you complete
        this form, please provide as much information as possible and give full
        answers as to why you want to volunteer and what experience you have.
      </p>
      <p>Please complete all sections of this application form.</p>
      <p>
        TLG are committed to keeping data secure, please visit{" "}
        <a href="https://www.tlg.org.uk/other/privacy-policy/volunteer-programmes-privacy-notice">
          https://www.tlg.org.uk/other/privacy-policy/volunteer-programmes-privacy-notice
        </a>{" "}
        for more information.
      </p>
      <p>
        We anticipate that this form should take you around 30 – 45 mins to complete. Your progress will be saved after each step if you need to come back to it later.
      </p>
      <hr />
      {steps.map((Component, index) => {
        if (index !== step) return null;
        return (
          <Component
            defaultValues={data}
            onSubmit={onSubmit}
            onBack={onBack}
            key={index}
          />
        );
      })}
    </>
  );
};
