import logo from "./assets/logo.jpg";
import QRCode from "react-qr-code";
import footer from "./assets/footer.png";
import React from "react";

export const Printout = ({url, organisationName}) => {
  return (
    <div className="tw-bg-white tw-p-8">
      <img src={logo} className="tw-max-w-md img-fluid" alt=""/>
      <div className="tw-text-center tw-text-4xl tw-mx-32 tw-leading-tight">
        <p>
          Welcome to TLG Make Lunch at {organisationName}.
          We are so glad to have you and your family
          with us today. If this is your first time with us,
          you will need to complete a registration form
          by scanning the QR code below. It should
          only take a few minutes.
        </p>
        <div className="tw-my-8">
          <div>
            <QRCode size={400} value={url}/>
          </div>
          <div className="tw-text-3xl tw-mt-2">
            <a href={url} className="tw-mt-4">
              {url}
            </a>
          </div>
        </div>
        <p>
          Thank you for taking the time to
          complete this form. We hope you
          enjoy your time with us today.
        </p>
      </div>
      <div className="tw-flex tw-justify-center">
        <img src={footer} className="img-fluid" alt=""/>
      </div>
    </div>
  )
}