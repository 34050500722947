import React from 'react';
import {useImage} from "react-image";

export const Preview = ({ id }) => {
  const { src, isLoading, error } = useImage({
    srcList: `/api/resource_files/${id}/preview`,
    useSuspense: false
  });

  if (isLoading) {
    return (
      <div className="img-thumbnail text-center tw-flex tw-items-center tw-justify-center tw-text-gray-500" style={{ width: 125, height: 50 }}>
        <span className="fa fa-spinner fa-spin" />
      </div>
    )
  }

  if (error) {
    return null;
  }

  return (
    <img alt="" src={src} className="img-thumbnail" width={125} />
  )
}