import React from "react";
import classNames from "classnames/dedupe";
import { useFormikContext } from "hooks";
import { get } from "lodash";

export const FormGroupCheckbox = ({ className, name, label, children, htmlFor }) => {
  return (
    <div className={classNames("form-group form-check", className)}>
      {label ? (
        <label htmlFor={htmlFor || name} className="form-check-label tw-select-none">
          {children}
          {label}
        </label>
      ) : (
        children
      )}
    </div>
  );
};

export const FormGroup = ({
  name,
  label,
  check,
  className,
  labelClassName,
  children,
  ...props
}) => {
  const { touched, errors, submitCount } = useFormikContext() || {};

  if (check) {
    return (
      <FormGroupCheckbox
        name={name}
        className={className}
        label={label}
        {...props}
      >
        {children}
      </FormGroupCheckbox>
    );
  }

  const error = (get(touched, name) || submitCount) && get(errors, name);

  return (
    <div
      className={classNames(
        "form-group",
        {
          "has-error": error,
        },
        className
      )}
      {...props}
    >
      {label ? (
        <label className={classNames("control-label", labelClassName)}>
          {label}
        </label>
      ) : null}
      {children}
    </div>
  );
};

export default FormGroup;
