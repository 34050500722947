import React from "react";
import { useQuery } from "react-query";
import {useClient, useUserContext} from "../../../hooks";
import { Alert } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { EditNote, Note } from "./components";
import { noop } from "lodash";

export const Notes = ({ application, onChanged = noop }) => {
  const url = "application_notes";
  const queryKey = [
    url,
    {
      params: {
        application,
      },
    },
  ];
  const client = useClient();
  const { isVPSupport } = useUserContext();
  const { data, refetch } = useQuery(queryKey, () =>
    client.get(...queryKey).get("data")
  );

  if (!data) return null;

  async function onSubmitNote(x) {
    const payload = {
      ...x,
      application,
    };
    await (!x.id
      ? client.post(url, payload)
      : client.put(`${url}/${x.id}`, payload));
    await refetch();
    NotificationManager.success("Note saved");
    onChanged();
  }

  async function onDeleteNote({ id }) {
    await client.delete(`${url}/${id}`);
    await refetch();
    NotificationManager.success("Note deleted");
    onChanged();
  }

  return (
    <div>
      {!data.length ? (
        <Alert color="info">No notes added to this application</Alert>
      ) : null}
      {data.map((i) => (
        <Note allowDelete={isVPSupport} value={i} key={i.id} onDelete={onDeleteNote} />
      ))}
      {isVPSupport ? (
        <EditNote defaultValues={{}} onSubmit={onSubmitNote} />
      ) : null}
    </div>
  );
};
