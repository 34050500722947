import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/dedupe";
import qs from "query-string";

export const AddNewButton = ({
  resource,
  className,
  match,
  to = "/new",
  query,
  children,
  ...props
}) => {
  const q = useMemo(() => (query ? `?${qs.stringify(query)}` : ""), [query]);

  return (
    <Link
      className={classNames("btn btn-tlg-teal", className)}
      to={`${match ? match.url : ""}${to}${q}`}
      {...props}
    >
      {children ? (
        children
      ) : (
        <>
          <span className="fa fa-plus mr-1" /> Add New {resource}
        </>
      )}
    </Link>
  );
};

export default AddNewButton;
