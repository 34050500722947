import React, { useMemo } from "react";
import moment from "moment";
import BaseDatePicker from "react-datepicker";
import { useController } from "react-hook-form";
import { useEventCallback } from "../../hooks";

export const DatePicker = ({
  dateFormat = "dd/MM/yyyy",
  modelDateFormat = "YYYY-MM-DD",
  name,
  rules,
  defaultValue,
  control,
  ...props
}) => {
  const { field } = useController({
    name,
    rules,
    defaultValue,
    control,
  });
  const { value, onBlur, onChange } = field;

  const selected = useMemo(
    () => (value ? moment(value, modelDateFormat).toDate() : null),
    [value, modelDateFormat]
  );

  const _onChange = useEventCallback(async (v) => {
    const m = moment(v);
    onChange(m.isValid() ? m.format(modelDateFormat) : null);
    onBlur();
  });

  return (
    <BaseDatePicker
      onChange={_onChange}
      selected={selected}
      dateFormat={dateFormat}
      dropdownMode="select"
      scrollableYearDropdown={false}
      showMonthDropdown
      showYearDropdown
      name={name}
      onBlur={onBlur}
      autoComplete="off"
      {...props}
    />
  );
};
