import React from 'react';

export const JobDescriptionLink = ({ jobRole, children, ...props }) => {
  return (
    <a
      href={(() => {
        const publicUrl = process.env.PUBLIC_URL;

        if (jobRole === "Coordinator") {
          return `${publicUrl}/media/ML_Coordinator_Role_Description_and_person_spec.pdf`;
        } else if (jobRole === "Core Team Member") {
          return `${publicUrl}/media/ML_Core_Team_Role_Description_and_person_spec.pdf`;
        }
      })()}
      target="_blank"
      rel="noreferrer noopener"
      {...props}
    >
      {children}
    </a>
  )
}