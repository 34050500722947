import React from "react";
import { useWatch } from "react-hook-form";
import wordsCount from "words-count";
import { FormText } from "../../../../@coherent/react-hook-form";

export const WordCount = ({ name, control, defaultValue = "" }) => {
  const text = useWatch({
    name,
    control,
    defaultValue,
  });

  return (
    <FormText>
      Word count: <strong>{wordsCount(text)}</strong>
    </FormText>
  );
};
