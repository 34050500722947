import React from 'react';
import {useFormContext} from "react-hook-form";
import cx from 'classnames';

export const Radio = ({ name, label, id, value, rules, className, ...props }) => {
  const context = useFormContext();
  const { register, watch } = context;
  const _value = watch(name);

  return (
    <div className={cx('form-check', className)}>
      <input type="radio" name={name} className="form-check-input" id={id} ref={register(rules)} value={value} defaultChecked={_value === value} {...props} />
      {label ? <label className="form-check-label" htmlFor={id}>{label}</label> : null}
    </div>
  )
}