import React, {useMemo} from 'react';
import {Page} from "../../components/common";
import {useListTeamQuery} from "./queries/useListTeamQuery";
import {groupBy} from "lodash";
import {CardImg, Col, Row} from "reactstrap";
import {useImage} from "react-image";
import {Content} from "../KitchenSink/User/components/Content";

const TeamMember = ({ value }) => {
  const { image__name, id, name, job_title, description } = value;
  const { src} = useImage({
    srcList: `/api/team/${id}/image/${image__name}`,
    useSuspense: false
  })

  return (
    <Row form>
      <Col xs="auto" style={{ width: '20rem'}}>
        {src ? <CardImg style={{
        }} alt="" top className="img-fluid img-thumbnail" src={src} /> : null}
      </Col>
      <Col>
        <div className="tw-text-lg tw-font-medium">
          <h4>{name}</h4>
          <p className="text-tlg-green tw--mt-2 mb-2">{job_title}</p>
        </div>
        <div className="tw-text-sm">
          <Content value={description} />
        </div>

      </Col>
    </Row>
  )
}

const Section = ({ team, title }) => {
  return (
    <section>
      <h3 className="text-muted tw-text-lg mb-2">{title}</h3>
      <Row form>
        {team.map(i => (
          <Col key={i.id} lg={12} className="mb-4">
            <TeamMember value={i} />
          </Col>
        ))}
      </Row>
    </section>
  )
}

export const MeetTheTeam = () => {
  const { data: team } = useListTeamQuery();
  const sections = useMemo(() => groupBy(team, 'section__name'), [team])

  if (!team) return null;

  return (
    <Page title="Meet the Team">
      {Object.entries(sections).map(([title, team], idx) => (
        <React.Fragment key={title}>
          {idx ? <hr/> : null}
          <Section title={title} team={team} />
        </React.Fragment>

      ))}
    </Page>
  )
}