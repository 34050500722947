import React from "react";
import { useTable, usePopover, useClient } from "hooks";
import { Field, Formik, Form } from "formik";
import { Page, SubmitButton, FormGroup, Select } from "components/common";
import { Table, Button, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

const MoveButton = ({ directories, onSubmit: onSubmitProp, ...props }) => {
  const { id, Popover, isOpen, setIsOpen } = usePopover();

  return (
    <>
      <Button id={id} onClick={() => setIsOpen(true)} {...props}>
        <span className="fa fa-copy mr-1" /> Move selected
      </Button>
      {isOpen ? (
        <Popover className="popover--lg">
          <PopoverBody style={{ width: "100%" }}>
            <Formik
              initialValues={{
                directory: "",
              }}
              onSubmit={async (...args) => {
                await onSubmitProp(...args);
                setIsOpen(false);
                NotificationManager.success("Moved successfully");
              }}
            >
              <Form>
                <FormGroup
                  label="Directory"
                  name="directory"
                  style={{ width: "30rem" }}
                >
                  <Field
                    name="directory"
                    component={Select}
                    validate={(v) => !v}
                    options={directories}
                    simpleValue
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <SubmitButton>Confirm</SubmitButton>
                </div>
              </Form>
            </Formik>
          </PopoverBody>
        </Popover>
      ) : null}
    </>
  );
};

export const List = ({
  match,
  directory,
  directories,
  onUnpublishedChanged,
  url='resource_directories',
  singular = 'Resource',
  plural = 'Resources'
}) => {
  const { key: directoryId, title: directoryName, unpublished, is_boxes_of_hope } = directory;
  const baseUrl = `${url}/${directoryId}`;

  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BooleanIcon,
    BulkDeleteButton,
    numSelected,
    selectedIds,
    refreshData,
  } = useTable({
    match,
    url: `${baseUrl}/files`,
    resource: singular,
  });
  const client = useClient();

  async function onMove({ directory }) {
    await client.post(`${url}/move`, {
      files: selectedIds,
      directory,
    });
    refreshData();
  }

  return (
    <Page title={`${plural} for ${directoryName}`}>
      {is_boxes_of_hope ? (
        <p className="text-muted tw--mt-4">Boxes of Hope</p>
      ) : null}
      <TableSearch className="fa-min" />
      <div className="tw-flex tw-mb-3 tw-items-start tw-flex-wrap">
        <AddNewButton />
        <div className="tw-mx-auto" />
        <MoveButton
          disabled={!numSelected}
          directories={directories}
          className="mr-2"
          onSubmit={onMove}
        />
        <BulkDeleteButton />
      </div>
      <FormGroup check label="Don't publish this directory">
        <input
          type="checkbox"
          className="form-check-input"
          checked={!!unpublished}
          onChange={(e) => {
            const { checked } = e.target;
            onUnpublishedChanged(checked);
          }}
        />
      </FormGroup>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="name">Name</th>
              <th sortBy="display">Display?</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>{i.name}</Link>
                </td>
                <td>
                  <BooleanIcon test={i.display} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
