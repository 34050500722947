import React from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {useMemo} from "react";
import {Col, Row} from "reactstrap";

const defaultColors =`#3366cc
#dc3912
#ff9900
#109618
#990099
#0099c6
#dd4477
#66aa00
#b82e2e
#316395
#994499
#22aa99
#aaaa11
#6633cc
#e67300
#8b0707
#651067
#329262
#5574a6
#3b3eac
#b77322
#16d620
#b91383
#f4359e
#9c5935
#a9c413
#2a778d
#668d1c
#bea413
#0c5922
#743411`.split('\n');

const Graph = ({ data, colors: colorsProp }) => {
  const colors = colorsProp || defaultColors;

  return (
    <div>
      <ResponsiveContainer minHeight={310}>
        <PieChart>
          <Pie data={data} label dataKey="count">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip formatter={() => ""} separator="" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export const ReturningRegistrationsGraph = ({
                                 data: dataProp,
                                 colors: colorsProp,
                               }) => {
  const colors = colorsProp || defaultColors;

  const [adults, children] = useMemo(() => {
    const { returning_adults, non_returning_adults, returning_children, non_returning_children } = dataProp;

    return [
      [
        {
          'name': `Returning Adults: ${returning_adults}`,
          'count': returning_adults
        },
        {
          'name': `Non-returning Adults: ${non_returning_adults}`,
          'count': non_returning_adults
        },
      ],
      [
        {
          'name': `Returning Children: ${returning_children}`,
          'count': returning_children
        },
        {
          'name': `Non-returning Children: ${non_returning_children}`,
          'count': non_returning_children
        },
      ],
    ]
  }, [dataProp]);

  return (
    <div>
      <h5 className="tw-text-left">
        Return Engagement Insights
      </h5>
      <h6>
        Returning adults and children are those that have attended more than one lunch club session
      </h6>
      <Row form>
        <Col>
          <Graph data={adults} colors={colors} />
        </Col>
        <Col>
          <Graph data={children} colors={colors} />
        </Col>
      </Row>
    </div>
  );
};