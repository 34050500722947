import { useState, useMemo } from "react";
import { stringify } from "query-string";
import { parseInt, compact } from "lodash";
import { isInt, isFloat } from "validator";
import useRouter from "./useRouter";

export const useQueryState = (
  key,
  initialState,
  { replace = true, isArray } = {}
) => {
  const { history, query } = useRouter();

  const initialValue = useMemo(() => {
    const value = query[key];
    if (value === undefined) return initialState;
    if (isArray) return compact(value.split(","));
    if (value === "true") return true;
    if (value === "false") return false;
    if (isInt(value)) return parseInt(value);
    if (isFloat(value)) return parseFloat(value);
    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setState(value) {
    history[replace ? "replace" : "push"]({
      search: stringify({
        ...query,
        [key]: value,
      }),
    });
    _setState(value);
  }

  const [state, _setState] = useState(initialValue);

  return [state, setState];
};

export default useQueryState;
