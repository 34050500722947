import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Field } from "formik";
import { FormGroup, Page, SubmitButton } from "components/common";
import { useFormHelpers, useAsyncEffect, useClient } from "hooks";
import { keyBy, keys, pickBy, sortBy } from "lodash";
import { NotificationManager } from "react-notifications";
import { List as SessionsList } from "pages/Sessions";
import Switch from "react-switch";
import { Link } from "react-router-dom";

export const GroupForm = ({ title }) => {
  return (
    <Page title={title}>
      <FormGroup name="name" label="Name">
        <Field name="name" className="form-control" />
      </FormGroup>
      <div className="tw-flex tw-justify-end">
        <SubmitButton>Save</SubmitButton>
      </div>
    </Page>
  );
};

export const Registered = ({ value }) => {
  const client = useClient();
  const mounted = useRef();
  const [registered, setRegistered] = useState();
  const [groupRegistered, setGroupRegistered] = useState();
  const { id } = value;

  useAsyncEffect(async () => {
    const registered = await client.get("registered").get("data");
    setGroupRegistered(keyBy(value.registered, "id"));
    setRegistered(sortBy(registered, "name"));
  }, []);

  const onUpdate = useCallback(async () => {
    await client.post(`groups/${id}/registered`, {
      registered: keys(pickBy(groupRegistered)),
    });
  }, [client, groupRegistered, id]);

  useEffect(() => {
    if (mounted.current) {
      onUpdate();
    }
    mounted.current = true;
  }, [mounted, onUpdate]);

  if (!registered) return null;

  return (
    <Page title="Registered for this Group">
      {registered.map((i) => (
        <div key={i.id} className="tw-flex tw-mb-3">
          <Switch
            checked={!!groupRegistered[i.id]}
            onChange={(checked) => {
              setGroupRegistered((v) => ({
                ...v,
                [i.id]: checked ? i : null,
              }));
            }}
          />
          <Link to={`/registered/${i.id}`} className="tw-ml-3">
            {i.name}
          </Link>
        </div>
      ))}
    </Page>
  );
};

export const Group = () => {
  const {
    validationObject,
    setInitialValues,
    initialValues,
    load,
    save,
    replacePathId,
    pageTitle,
  } = useFormHelpers({ url: "groups", resource: "Group" });

  useAsyncEffect(async () => {
    const initialValues = await load({
      name: "",
    });
    setInitialValues(initialValues);
  }, []);

  async function onSubmit(x, { setSubmitting }) {
    try {
      const resp = await save(x);
      replacePathId(resp.id);
      NotificationManager.success("Saved successfully");
    } finally {
      setSubmitting(false);
    }
  }

  if (!initialValues) return null;

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validationObject({
          name: (v) => !v,
        })}
      >
        {(formik) => <GroupForm title={pageTitle} {...formik} />}
      </Formik>
      {initialValues.id && <Registered value={initialValues} />}
      {initialValues.id && (
        <SessionsList
          match={{
            url: "/sessions",
          }}
          group={initialValues.id}
        />
      )}
    </>
  );
};

export default Group;
