import React, { useEffect, useRef, useState } from "react";
import { Page } from "../../components/common";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import {
  Checkbox,
  DatePicker,
  FormGroup,
  SubmitButton,
} from "../../@coherent/react-hook-form";
import { Col, Row, Table } from "reactstrap";
import { useClient } from "../../hooks";
import { Link } from "react-router-dom";
import {ClubHealthStatus} from "../../components/ClubHealthStatus";

function useOrganisationOpenStatus() {
  const form = useForm({
    defaultValues: {
      date_from: moment().startOf("month").format("YYYY-MM-DD"),
      date_to: moment().endOf("month").format("YYYY-MM-DD"),
      has_rl_open: false,
      has_session: false,
      exclude_empty_dates: false,
      is_extra_session: false,
    },
    shouldUnregister: false,
  });
  const { handleSubmit } = form;
  const [data, setData] = useState(null);
  const client = useClient();

  async function _onSubmit(x) {
    const resp = await client
      .post("organisation_open_status", {
        ...x,
        has_rl_open: x.has_rl_open || null,
        has_session: x.has_session || null,
        exclude_empty_dates: x.exclude_empty_dates || null,
        is_extra_session: x.is_extra_session || null,
      })
      .get("data");
    setData(resp);
  }

  const onSubmit = handleSubmit(_onSubmit);
  const onSubmitRef = useRef(onSubmit);

  useEffect(() => {
    onSubmitRef.current();
  }, [onSubmitRef]);

  return {
    form,
    onSubmit,
    data,
  };
}

export function OrganisationOpenStatus() {
  const context = useOrganisationOpenStatus();
  const { form, onSubmit, data } = context;

  return (
    <Page title="Confirmed Sessions Calendar">
      <p>
        Please pray for, support and encourage our clubs on the days they are
        open. If you are hoping to plan a visit to a club for yourself or
        others please ensure you coordinate with the Make Lunch team at{' '}
        <a href="mailto:makelunch@tlg.org.uk">makelunch@tlg.org.uk</a> before
        initiating any plans.
      </p>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Row form>
            <Col>
              <FormGroup label="Date from">
                <DatePicker
                  name="date_from"
                  rules={{
                    required: true,
                  }}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup label="Date to">
                <DatePicker
                  name="date_to"
                  rules={{
                    required: true,
                  }}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col xs="auto" className="tw-self-end">
              <SubmitButton className="tw-mb-4">Update</SubmitButton>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <FormGroup check label="CRL confirmed session" htmlFor="has_rl_open">
                <Checkbox
                  id="has_rl_open"
                  className="form-check-input"
                  name="has_rl_open"
                />
              </FormGroup>
            </Col>
            <Col xs="auto">
              <FormGroup check label="Session confirmed on KC" htmlFor="has_session">
                <Checkbox
                  id="has_session"
                  className="form-check-input"
                  name="has_session"
                />
              </FormGroup>
            </Col>
            <Col xs="auto">
              <FormGroup check label="Extra sessions" htmlFor="is_extra_session">
                <Checkbox
                  id="is_extra_session"
                  className="form-check-input"
                  name="is_extra_session"
                />
              </FormGroup>
            </Col>
            <Col xs="auto">
              <FormGroup
                check
                label="Hide empty dates"
                htmlFor="exclude_empty_dates"
              >
                <Checkbox
                  id="exclude_empty_dates"
                  className="form-check-input"
                  name="exclude_empty_dates"
                />
              </FormGroup>
            </Col>
          </Row>
        </form>
      </FormProvider>
      <Table size="sm" borderless>
        {!!data &&
          data.map((i) => (
            <React.Fragment key={i.date_of}>
              <tr
                style={{ borderBottom: "1px solid rgb(160,174,192)" }}
                className="tw-border-gray-500"
              >
                <td colSpan={5}>
                  <strong>{moment(i.date_of).format('DD/MM/YYYY')}</strong>{" "}
                  {!i.organisations.length ? (
                    <span className="text-muted small">
                      (no data for this date)
                    </span>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              {i.organisations.map((i) => (
                <tr key={i.id} style={{ borderBottom: "1px solid #e2e8f0" }}>
                  <td>
                    <Link to={`/organisations/${i.id}`}>
                      {i.organisation_name}
                    </Link>
                  </td>
                  <td>
                    {i.region}
                  </td>
                  <td>
                    <ClubHealthStatus value={i.club_health_status} />
                  </td>
                  <td>{i.has_rl_open ? "CRL confirmed session" : ""}</td>
                  <td>{i.has_session ? "Session confirmed on KC" : ""}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
      </Table>
    </Page>
  );
}
