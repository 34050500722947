import React from "react";
import { Formik, Field } from "formik";
import {
  FormGroup,
  Page,
  SubmitButton,
  DatePicker,
  Editor,
  Checkbox,
} from "components/common";
import { useFormHelpers, useAsyncEffect } from "hooks";
import { NotificationManager } from "react-notifications";

export const Form = ({ title }) => {
  return (
    <Page title={title}>
      <FormGroup name="title" label="Title">
        <Field name="title" className="form-control" />
      </FormGroup>
      <FormGroup name="date" label="Date">
        <Field name="date" component={DatePicker} className="form-control" />
      </FormGroup>
      <FormGroup name="content" label="Content">
        <Field name="content" component={Editor} />
      </FormGroup>
      <FormGroup name="live" label="Live?" check>
        <Field name="live" component={Checkbox} className="form-check-input" />
      </FormGroup>
      <div className="tw-flex tw-justify-end">
        <SubmitButton>Save</SubmitButton>
      </div>
    </Page>
  );
};

export const Edit = () => {
  const {
    validationObject,
    setInitialValues,
    initialValues,
    load,
    save,
    replacePathId,
    pageTitle,
  } = useFormHelpers({ url: "important_dates", resource: "Important Date" });

  useAsyncEffect(async () => {
    const initialValues = await load({
      title: "",
      content: null,
      live: true,
    });
    setInitialValues(initialValues);
  }, []);

  async function onSubmit(x, { setSubmitting }) {
    try {
      const resp = await save(x);
      replacePathId(resp.id);
      NotificationManager.success("Saved successfully");
    } finally {
      setSubmitting(false);
    }
  }

  if (!initialValues) return null;

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validationObject({
          title: (v) => !v,
          content: (v) => !v,
          date: (v) => !v,
        })}
      >
        {(formik) => <Form title={pageTitle} {...formik} />}
      </Formik>
    </>
  );
};

export default Edit;
