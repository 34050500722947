import React, { useRef } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useReactToPrint } from "react-to-print";
import { Printout } from "./Printout";
import QRCode from "react-qr-code";
import { useModal } from "../../../hooks";

export function SurveyLink({ hashid, organisationName, className }) {
  const { Modal, toggle, isOpen } = useModal();
  const { origin } = window.location;
  const url = `${origin}/survey/${hashid}`;
  const contentRef = useRef(null);
  const title = `Survey link for ${organisationName}`;
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: title,
  });

  return (
    <>
      {isOpen && (
        <Modal>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody className="text-center">
            <div>
              <QRCode value={url} />
            </div>
            <div className="tw-mt-2">
              <a href={url}>{url}</a>
            </div>
            <div className="tw-hidden">
              <div ref={contentRef}>
                <Printout url={url} organisationName={organisationName} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handlePrint}>
              <span className="fa fa-print" /> Print
            </Button>
            <Button onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      )}
      <Button color="primary" onClick={toggle} className={className}>
        <span className="fa fa-link mr-2" />
        Get Survey link
      </Button>
    </>
  );
}
