import React from "react";
import { useQuery } from "react-query";
import {Alert, Table} from "reactstrap";
import {ReferenceRow} from "./ReferenceRow";
import {useClient, useUserContext} from "../../../../hooks";
import {NotificationManager} from "react-notifications";

export const References = ({ match, application }) => {
  const { isVPSupport } = useUserContext();
  const url = "application_references";
  const queryKey = [
    url,
    {
      params: {
        application,
      },
    },
  ];
  const client = useClient();
  const { data, refetch } = useQuery(queryKey, () =>
    client.get(...queryKey).get("data")
  );

  async function onSendEmail(i) {
    await client.post(`${url}/${i.id}/send_email`);
    NotificationManager.success('Email sent');
    await refetch();
  }

  async function onCheckedChanged(i) {
    await client.patch(`${url}/${i.id}`, i);
  }

  if (!data) return null;

  return (
    <div>
      {!data.length ? (
        <Alert color="info">No references to this application</Alert>
      ) : (
        <Table size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Club</th>
              <th>Status</th>
              <th/>
            </tr>
          </thead>
          <tbody>
          {data.map(i => (
            i.email && i.full_name ? (
              <ReferenceRow allowSend={isVPSupport} match={match} onCheckedChanged={onCheckedChanged} onSendEmail={onSendEmail} value={i} key={i.id} />
              ) : null
          ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
