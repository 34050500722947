import React from "react";
import { useDropzone } from "react-dropzone";

export const FileUpload = ({
  field,
  form,
  currentFile: currentFileProp,
  imageStyle = {},
}) => {
  const { value, name } = field;
  const { setFieldValue, setFieldTouched, values } = form;

  const currentFile = currentFileProp || values[name + "__name"] || undefined;

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted([file], event) {
      const reader = new FileReader();
      reader.onload = () => {
        setFieldValue(
          name,
          {
            filename: file.name,
            content: reader.result,
          },
          false
        );
        setFieldTouched(name, true);
      };
      reader.readAsDataURL(file);
    },
  });

  return (
    <div>
      <div {...getRootProps()} className="btn btn-outline-secondary">
        <input {...getInputProps()} />
        {value ? (
          <div>{value.filename} selected.</div>
        ) : (
          "Click here to select a file."
        )}
      </div>
      {currentFile ? (
        <div>
          <a href={`/api/media/${currentFile}`} target="__blank">
            {currentFile}
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default FileUpload;
