import React, {useMemo} from 'react';
import {useClient, useModal} from "../../hooks";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useForm, FormProvider} from "react-hook-form";
import {FormGroup, Input, SubmitButton} from "@coherent/react-hook-form";
import {keys} from "lodash";
import {NotificationManager} from "react-notifications";
import {useQuery} from "react-query";

export const BulkConnectionStatusButton = ({ selected, onSubmitted, ...props }) => {
  const { isOpen, setIsOpen, Modal, toggle } = useModal();
  const methods = useForm();
  const { handleSubmit, control } = methods;
  const client = useClient();

  const { data: accessingSupportTypes } = useQuery(["registered/accessing_support_types"], () => client.get("registered/accessing_support_types").get('data'), {
    placeholderData: [],
  })
  const accessingSupportTypesOptions = useMemo(() => accessingSupportTypes.map(i => (
    <option value={i.value}>{i.label}</option>
  )), [accessingSupportTypes])

  const selectedIds = useMemo(() => keys(selected), [selected]);
  const numSelected = selectedIds.length;

  async function onSubmit(x) {
    const errors = [];

    for (const i of selectedIds) {
      try {
        await client.patch(`registered/${i}`, x)
      } catch (ex) {
        console.error(ex);
        errors.push([i, ex]);
      }
    }

    if (errors.length) {
      NotificationManager.error(`${errors.length} rows could not be updated`)
    } else {
      NotificationManager.success('Updated successfully');
    }
    setIsOpen(false);
    onSubmitted(errors);
  }

  return (
    <>
      {isOpen ? (
        <FormProvider {...methods}>
          <Modal size="lg">
            <ModalHeader toggle={toggle}>
              Update Chuch Connection status
            </ModalHeader>
            <ModalBody>
              <p>
                Update the Church Connection status for the selected registrations
              </p>
              <FormGroup name="accessing_wider_support_type">
                <Input as="select" defaultValue="" name="accessing_wider_support_type" className="form-control" rules={{
                  required: true
                }}>
                  <option />
                  {accessingSupportTypesOptions}
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <SubmitButton onClick={handleSubmit(onSubmit)} control={control}>
                Confirm
              </SubmitButton>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </FormProvider>
      ) : null}
      <Button title="Update Church Connection status for the selected registrations" disabled={!numSelected} onClick={toggle} {...props}>
        Update Church Connection status
      </Button>
    </>
  )
}