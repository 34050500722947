import React from "react";
import {useTable, useUserContext} from "hooks";
import { Page } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import invariant from "invariant";
import { STAFF, PASTORS } from "./constants";
import {
  ConvertToApplicationButton
} from "../../features/applications/applications";

export const List = ({ match, type }) => {
  invariant(
    type === PASTORS || type === STAFF,
    "type must be pastors or staff"
  );

  const isPastors = type === PASTORS;

  const resource = type === STAFF ? "Staff" : "Users";

  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
    BooleanIcon,
  } = useTable({
    match,
    resource,
    url: `users/${type}`,
  });
  const { isVP, isBoxesOfHopeStaff } = useUserContext();

  return (
    <Page title={resource}>
      <TableSearch className="fa-min" />
      {isVP || isBoxesOfHopeStaff ? (
        <div className="tw-flex tw-justify-between tw-mb-3">
          <AddNewButton />
          <BulkDeleteButton />
        </div>
      ) : null}
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="email">Email</th>
              <th sortBy="first_name">Name</th>
              {isPastors ? (
                <th
                  className="text-center"
                  sortBy="organisation__organisation_name"
                >
                  Org. Name
                </th>
              ) : null}
              {isPastors ? (
                <th
                  className="text-center"
                  sortBy="organisation__organisation_id"
                >
                  Org. ID
                </th>
              ) : null}
              {isPastors ? <th sortBy="user_type">User type</th> : null}
              <th className="text-center" sortBy="is_active">
                Is Active?
              </th>
              {!isPastors ? (
                <th sortBy="role">
                  Role
                </th>
              ) : (
                <th />
              )}
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>{i.email}</Link>
                </td>
                <td>{i.full_name}</td>
                {isPastors ? (
                  <>
                    <td>{i.organisation__organisation_name}</td>
                    <td>{i.organisation__organisation_id}</td>
                    <td>{i.user_type__label}</td>
                  </>
                ) : null}
                <td className="text-center">
                  <BooleanIcon test={i.is_active} />
                </td>
                {!isPastors ? (
                  <td>
                    {i.role__label}
                  </td>
                ) : (
                  <td className="tw-whitespace-no-wrap tw-p-0">
                    {i.can_convert_to_application ? (
                      <ConvertToApplicationButton user={i} />
                    ) : null}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default ({ type, ...props }) => (
  <List {...props} type={type} key={type} />
);
