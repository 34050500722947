import decode from "jwt-decode";
import moment from "moment";

export class Auth {
  constructor(token_key = "token") {
    this.token_key = token_key;
  }

  getToken = () => localStorage.getItem(this.token_key) || null;

  clearToken = () => localStorage.removeItem(this.token_key);

  setToken = (token) => localStorage.setItem(this.token_key, token);

  decodeToken = () => {
    const token = this.getToken();
    if (!token) return null;
    let decoded = null;
    let clear = false;
    try {
      decoded = decode(token);
      if (decoded.exp && moment(decoded.exp * 1000).isSameOrBefore())
        clear = true;
    } catch (err) {
      clear = true;
    }

    if (clear) {
      this.clearToken();
      return null;
    }

    return decoded;
  };
}

export default new Auth();
