import React from "react";
import { useTooltip } from "hooks";

export const SupportIcon = ({ active, label }) => {
  const { Tooltip, id } = useTooltip();

  if (!active) {
    return (
      <span className="fa fa-support fa-lg text-muted tw-opacity-25 fa-fw" />
    );
  }

  return (
    <>
      <span
        id={id}
        className="fa fa-support fa-lg text-success fa-fw tw-cursor-pointer"
      />
      <Tooltip placement="bottom">
        <div className="tw-whitespace-pre-line">{label}</div>
      </Tooltip>
    </>
  );
};
