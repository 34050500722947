import React, { useEffect } from "react";
import {
  compose,
  onlyUpdateForKeys,
  withHandlers,
  withPropsOnChange,
} from "recompose";
import { ApiClient } from "services";
import { isArray, isObject, isString, get } from "lodash";

const enhance = compose(
  withPropsOnChange(["client"], ({ client }) => ({
    client: client || ApiClient(),
  })),
  onlyUpdateForKeys([
    "filters",
    "ordering",
    "path",
    "offset",
    "limit",
    "search",
    "refreshToken",
    "url",
  ]),
  withHandlers({
    fetch: ({
      url,
      ordering: tmpOrdering,
      offset,
      limit,
      filters,
      onDataFetched,
      search,
      filterType = null,
      filterValue = null,
      client,
    }) => () => {
      const ordering = isString(tmpOrdering)
        ? tmpOrdering
        : tmpOrdering.column
        ? `${tmpOrdering.direction === 2 ? "-" : ""}${tmpOrdering.column}`
        : "";

      let params = { ordering, offset, limit, search };
      if (filterType !== null) {
        params[filterType] = filterValue;
      }
      if (isObject(filters)) {
        params = {
          ...params,
          ...filters,
        };
      }
      // onDataFetched({ count: 0, loading: true, results: [] });

      client
        .get(url, {
          params,
        })
        .then(({ data }) => {
          if (isArray(data)) {
            onDataFetched({ count: data.length, results: data });
          } else {
            onDataFetched(data);
          }
        })
        .catch((err) => {
          const status = get(err, "response.status");
          if (status && status >= 400 && status < 500) {
            onDataFetched({
              count: 0,
              loading: false,
              error: err,
              results: [],
            });
          } else {
            throw err;
          }
        });
    },
  }),
  (BaseComponent) => ({ fetch, ...props }) => {
    useEffect(() => {
      fetch();
    });

    return <BaseComponent {...props} />;
  }
);

export const TableDataFetch = () => null;

export default enhance(TableDataFetch);
