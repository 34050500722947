import React, {useMemo} from 'react';
import { uniqueId } from "lodash";

export const ReferenceChecked = ({ value, onChange }) => {
  const id = useMemo(() => uniqueId('reference_checked'), []);

  return (
    <div className="form-group form-check">
      <input type="checkbox" defaultChecked={!!value} onChange={e => {
        onChange(e.target.checked);
      }} className="form-check-input" id={id} />
      <label className="form-check-label" htmlFor={id}>Reference checked</label>
    </div>
  )
}