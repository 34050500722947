import React from "react";
import { useTable } from "hooks";
import { Page } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

export const TeamList = ({ match }) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
  } = useTable({
    match,
    url: `team`,
    resource: "Team Member",
  });

  return (
    <Page title="Team Members">
      <TableSearch className="fa-min" />
      <div className="tw-flex tw-justify-between tw-mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="name">Name</th>
              <th sortBy="job_title">Job title</th>
              <th sortBy="section__name">Section name</th>
              <th sortBy="order">Order</th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>
                    {i.name}
                  </Link>
                </td>
                <td>
                  {i.job_title}
                </td>
                <td>
                  {i.section__name}
                </td>
                <td>{i.order}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};
