import React from "react";
import { FormGroup, Radio } from "./index";

export const YesNoRadioQuestion = ({ name, required, label, children }) => {
  return (
    <FormGroup name={name} required={required} label={label}>
      <Radio
        rules={{ required }}
        name={name}
        label="Yes"
        id={`${name}_yes`}
        value="Yes"
      />
      <Radio
        rules={{ required }}
        name={name}
        label="No"
        id={`${name}_no`}
        value="No"
      />
      {children}
    </FormGroup>
  );
};
