import React from "react";
import { useQuery } from "react-query";
import { Page } from "components/common";
import { useClient } from "hooks";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useRouter } from "hooks";
import {
  ExistingEntryCheck,
} from "../components";
import useUserContext from "../../../hooks/useUserContext";
import {
  DatePicker,
  FormGroup,
  Select,
  SubmitButton,
} from '@coherent/react-hook-form';

export const ParcelProvisionsManualEntryEdit = () => {
  const client = useClient();
  const { match, history } = useRouter();
  const { id } = match.params;
  const { isTLG, isBoxesOfHope } = useUserContext();

  const form = useForm({
    mode: "onBlur",
    criteriaMode: "all",
  });
  const url = "parcel_provisions_manual_entries";

  const { reset, register, handleSubmit } = form;

  const { data: organisations } = useQuery("organisations", () =>
    client.get("organisations").get("data")
  );

  const { data: holidays } = useQuery("parcel_provisions/holidays", (url) =>
    client.get(url).get("data")
  );

  const { refetch, data } = useQuery(
    [url, id],
    async (url, id) => {
      return await client.get(`${url}/${id}`).get("data");
    },
    {
      enabled: organisations && holidays,
      onSuccess: (values) => reset(values),
    }
  );

  async function onSubmit(x) {
    const { id: _id } = await client.post(url, x).get("data");
    NotificationManager.success("Manual entry successfully saved");

    if (id === `${_id}`) {
      await refetch({ throwOnError: true });
    } else {
      history.replace(match.path.replace(":id", _id));
    }
  }

  if (!data) return null;

  if (!isBoxesOfHope) {
    register('unique_parcels_with_wellbeing_resources');
  }
  if (!isTLG) {
    for (const name of [
      'unique_volunteers',
      'unique_children_accessing_wider_support',
      'unique_adults_accessing_wider_support',
      'ytd_unique_volunteers',
      'ytd_unique_children_accessing_wider_support',
      'ytd_unique_adults_accessing_wider_support',
    ]) {
      register(name);
    }
  }

  return (
    <Page title="Parcel Provisions Manual Entry">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label="Year" name="year">
            <input
              type="number"
              className="form-control"
              ref={register({
                required: true,
                min: 2015,
                valueAsNumber: true,
              })}
              name="year"
            />
          </FormGroup>
          <FormGroup label="Dashboard date" name="date" help={
            'This date is used to determine if it should be included in the Dashboard custom date range. Please select a single date that is representative of the holiday period in which these numbers were recorded.'
          }>
            <DatePicker
              name="date"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="Club" name="organisation" required>
            <Select
              name="organisation"
              rules={{ required: true }}
              options={organisations}
              labelKey="organisation_name"
              valueKey="id"
              simpleValue
            />
          </FormGroup>
          <FormGroup label="Holiday" name="holiday" required>
            <Select
              name="holiday"
              rules={{ required: true }}
              options={holidays}
              simpleValue
            />
          </FormGroup>
          <ExistingEntryCheck
            url="parcel_provisions_manual_entries"
            id={Number(id)}
          />
          <FormGroup
            label="No of Provision Sessions"
            name="number_of_provision_sessions"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="number_of_provision_sessions"
            />
          </FormGroup>
          <FormGroup
            label="Total No of Parcels"
            name="number_of_parcels"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="number_of_parcels"
            />
          </FormGroup>
          <FormGroup
            label="Total No of Portions"
            name="number_of_portions"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="number_of_portions"
            />
          </FormGroup>
          {isBoxesOfHope && (
            <FormGroup
              label="Total No of Parcels with Well Being Resources"
              name="unique_parcels_with_wellbeing_resources"
              required
            >
              <input
                type="number"
                ref={register({ required: true, valueAsNumber: true, min: 0 })}
                className="form-control"
                name="unique_parcels_with_wellbeing_resources"
              />
            </FormGroup>
          )}
          <FormGroup
            label="No of Unique Families Served"
            name="unique_families_served"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="unique_families_served"
            />
          </FormGroup>
          <FormGroup
            label="No of Adult Portions"
            name="adult_portions"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="adult_portions"
            />
          </FormGroup>
          <FormGroup label="No of Unique Adults" name="unique_adults" required>
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="unique_adults"
            />
          </FormGroup>
          <FormGroup
            label="No of Unique Children"
            name="unique_children"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="unique_children"
            />
          </FormGroup>
          {isTLG && (
            <FormGroup
              label="No of Unique Volunteers"
              name="unique_volunteers"
              required
            >
              <input
                type="number"
                ref={register({ required: true, valueAsNumber: true, min: 0 })}
                className="form-control"
                name="unique_volunteers"
              />
            </FormGroup>
          )}
          <FormGroup
            label="No of Children's Portions"
            name="child_portions"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="child_portions"
            />
          </FormGroup>
          {isTLG && (
            <>
              <FormGroup
                label="Unique Children Accessing Wider Support"
                name="unique_children_accessing_wider_support"
                required
              >
                <input
                  type="number"
                  ref={register({ required: true, valueAsNumber: true, min: 0 })}
                  className="form-control"
                  name="unique_children_accessing_wider_support"
                />
              </FormGroup>
              <FormGroup
                label="Unique Adults Accessing Wider Support"
                name="unique_adults_accessing_wider_support"
                required
              >
                <input
                  type="number"
                  ref={register({ required: true, valueAsNumber: true, min: 0 })}
                  className="form-control"
                  name="unique_adults_accessing_wider_support"
                />
              </FormGroup>
            </>
          )}
          <FormGroup
            label="Year to Date No of Unique Families Served"
            name="ytd_unique_families_served"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="ytd_unique_families_served"
            />
          </FormGroup>
          <FormGroup
            label="Year to Date No of Unique Adults"
            name="ytd_unique_adults"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="ytd_unique_adults"
            />
          </FormGroup>
          <FormGroup
            label="Year to Date No of Unique Children"
            name="ytd_unique_children"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="ytd_unique_children"
            />
          </FormGroup>


          {isTLG && (
            <>
              <FormGroup
                label="Year to Date No of Unique Volunteers"
                name="ytd_unique_volunteers"
                required
              >
                <input
                  type="number"
                  ref={register({ required: true, valueAsNumber: true, min: 0 })}
                  className="form-control"
                  name="ytd_unique_volunteers"
                />
              </FormGroup>
              <FormGroup
                label="Year to Date Unique Children Accessing Wider Support"
                name="ytd_unique_children_accessing_wider_support"
                required
              >
                <input
                  type="number"
                  ref={register({ required: true, valueAsNumber: true, min: 0 })}
                  className="form-control"
                  name="ytd_unique_children_accessing_wider_support"
                />
              </FormGroup>
              <FormGroup
                label="Year to Date Unique Adults Accessing Wider Support"
                name="ytd_unique_adults_accessing_wider_support"
                required
              >
                <input
                  type="number"
                  ref={register({ required: true, valueAsNumber: true, min: 0 })}
                  className="form-control"
                  name="ytd_unique_adults_accessing_wider_support"
                />
              </FormGroup>
            </>
          )}

          <div className="tw-flex tw-justify-end">
            <SubmitButton>Submit</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
