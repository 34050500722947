import React, { useState, useRef, useCallback, useMemo } from "react";
import { Tooltip } from "reactstrap";
import { uniqueId } from "lodash";

export const useTooltip = ({ isOpen: isOpenProp } = {}) => {
  const [isOpen, setIsOpen] = useState(isOpenProp);
  const ctx = useRef();
  const toggle = () => setIsOpen((v) => !v);
  const id = useMemo(() => uniqueId("tooltip_"), []);
  ctx.current = { isOpen, toggle, target: id };

  const renderTooltip = useCallback(
    (props) => <Tooltip {...ctx.current} {...props} />,
    []
  );

  return {
    setIsOpen,
    isOpen,
    id,
    Tooltip: renderTooltip,
  };
};

export default useTooltip;
