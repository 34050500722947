import React from "react";
import { Formik, Field } from "formik";
import { FormGroup, Page, SubmitButton } from "components/common";
import { useFormHelpers, useAsyncEffect } from "hooks";
import { NotificationManager } from "react-notifications";

export const Form = ({ title }) => {
  return (
    <Page title={title}>
      <FormGroup name="name" label="Name">
        <Field name="name" className="form-control" validate={(v) => !v} />
      </FormGroup>
      <div className="tw-flex tw-justify-end">
        <SubmitButton>Save</SubmitButton>
      </div>
    </Page>
  );
};

export const Edit = () => {
  const {
    setInitialValues,
    initialValues,
    load,
    save,
    replacePathId,
    pageTitle,
  } = useFormHelpers({ url: "regional_leaders", resource: "Church Relationship Leads" });
  useAsyncEffect(async () => {
    const initialValues = await load({
      name: "",
    });
    setInitialValues(initialValues);
  }, []);

  async function onSubmit(x, { setSubmitting }) {
    try {
      const resp = await save(x);
      replacePathId(resp.id);
      NotificationManager.success("Saved successfully");
    } finally {
      setSubmitting(false);
    }
  }

  if (!initialValues) return null;

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {(formik) => <Form title={pageTitle} {...formik} />}
      </Formik>
    </>
  );
};

export default Edit;
