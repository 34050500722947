import React from "react";

export const VimeoVideo = ({ title, videoId, ...props }) => {
  return (
    <iframe
      title={title}
      src={"https://player.vimeo.com/video/" + videoId}
      frameBorder={0}
      allow="autoplay; fullscreen"
      allowFullScreen
      {...props}
    />
  )
}