import {Card, CardBody, CardHeader} from "reactstrap";
import React from "react";

export const Section = ({ title, children }) => {
  return (
    <Card className="tw-mb-4">
      <CardHeader className="bg-tlg-green text-white tw-font-medium">
        {title}
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};