import React from "react";
import { keys } from "lodash";
import { Button } from "reactstrap";
import DeleteModal from "./DeleteModal";

export const BulkDeleteButton = ({
  toggleModal,
  selected,
  url,
  onDeleted,
  isModalOpen,
  buttonProps,
  modalProps,
  readOnly,
  client,
  appendBody,
  ...props
}) => {
  const numSelected = keys(selected).length;

  if (readOnly) return null;

  return (
    <div {...props}>
      <Button
        onClick={toggleModal}
        disabled={!numSelected}
        color="tlg-orange"
        {...buttonProps}
      >
        <span className="fa fa-trash" /> Remove selected{" "}
        {!!numSelected && <span>({numSelected})</span>}
      </Button>
      <DeleteModal
        url={url}
        client={client}
        onDeleted={onDeleted}
        isOpen={isModalOpen}
        toggle={toggleModal}
        selected={selected}
        appendBody={appendBody}
      />
    </div>
  );
};

export default BulkDeleteButton;
