import React from "react";
import { useFormikContext } from "hooks";
import PendingButton from "./PendingButton";

export const SubmitButton = ({ pending, children, ...props }) => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <PendingButton
      onClick={submitForm}
      pending={!!(pending || isSubmitting)}
      color="tlg-teal"
      className="tw-text-white"
      {...props}
    >
      {children}
    </PendingButton>
  );
};

export default SubmitButton;
