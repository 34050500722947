import React from "react";
import { Page } from "../../components/common";
import { SurveyFields } from "./Survey";
import { useQuery } from "react-query";
import { useClient } from "../../hooks";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { formatDateTime } from "../applications/util";

export function SurveyView() {
  const { id } = useParams();
  const url = `participant_feedback/${id}`;
  const client = useClient();
  const form = useForm();
  const { reset, getValues, watch } = form;

  useQuery({
    queryKey: [url],
    queryFn: () => client.get(url).get("data"),
    config: {
      onSuccess: (v) => reset(v),
    },
  });

  if (!watch("id")) {
    return null;
  }

  return (
    <Page>
      <FormProvider {...form}>
        <p>
          Submitted on <strong>{formatDateTime(getValues("created"))}</strong>
        </p>
        <hr />
        <fieldset disabled>
          <SurveyFields />
        </fieldset>
      </FormProvider>
    </Page>
  );
}
