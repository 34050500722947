import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Alert, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {DatePicker, FormGroup, SubmitButton} from "../../@coherent/react-hook-form";
import {useClient} from "../../hooks";

export const HolidaySetup = ({ year, holiday, onSubmitted }) => {
  const form = useForm({
    defaultValues: {
      start_date: null,
      end_date: null
    },
    mode: "all",
  });
  const { handleSubmit } = form;
  const client = useClient();

  async function onSubmit(x) {
    await client.post(`holiday_allocation/${year}`, [{
      ...x,
      holiday,
      year,
      status: 'Open'
    }], {
      params: {
        partial: true
      }
    });
    await onSubmitted();
  }

  return (
    <Alert color="warning" className="small">
      <FormProvider {...form}>
        <p>
          You haven't yet added the dates for this holiday on your <Link to={{
            pathname: '/setup',
            state: {
              year
            }
        }}>Setup</Link> page.<br/>
          You can enter the dates for this holiday period here now:
        </p>
        <Row form>
          <Col lg>
            <FormGroup name="start_date" label="Start date">
              <DatePicker name="start_date" rules={{
                required: true
              }} className="form-control" />
            </FormGroup>
          </Col>
          <Col lg>
            <FormGroup name="end_date" label="End date">
              <DatePicker name="end_date" rules={{
                required: true
              }} className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <p>
          Please remember to use the <Link to={{
            pathname: '/setup',
            state: {
              year
            }
        }}>Setup</Link> tab to enter all the holiday periods you plan to be open this year. This will help you plan your sessions ahead of time and enable us to see which clubs are hoping to open each holiday this year.
        </p>
        <div className="tw-flex tw-justify-end">
          <SubmitButton size="sm" onClick={handleSubmit(onSubmit)}>
            Confirm holiday dates
          </SubmitButton>
        </div>
      </FormProvider>
    </Alert>
  )
}