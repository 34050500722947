import React, {useMemo} from 'react';
import {Button, ModalBody, ModalHeader} from "reactstrap";
import {useClient, useModal} from "../../hooks";
import {useQuery} from "react-query";
import {groupBy, map, tail} from "lodash";

const defaultUrl = 'dashboard/user_ethnicity_full_data';
const EthnicityCaptureOtherDataBody = ({ url = defaultUrl }) => {
  const client = useClient();
  const { data: _data, isFetching } = useQuery([url], () => client.get(url).get('data'), {
    placeholderData: {}
  })

  const otherData = useMemo(() => groupBy(_data.other, 'other_group'), [_data]);

  if (isFetching) {
    return (
      <div className="text-center">
        <span className="fa fa-spin fa-spinner mr-1" /> Loading...
      </div>
    )
  }

  return (
    <div>
      <table className="table table-sm table-borderless"
             style={{width: 'auto', maxWidth: '100%'}}>
        {_data.captured ? _data.captured.map(i => (
          <React.Fragment key={i.group}>
            <tr>
              <td className="tw-font-medium" rowSpan={i.values.length}>
                {i.group}
              </td>
              <td>
                {i.values[0].name} ({i.values[0].count})
              </td>
            </tr>
            {tail(i.values).map(i => (
              <tr key={i.name}>
                <td>
                  {i.name} ({i.count})
                </td>
              </tr>
            ))}
          </React.Fragment>
        )) : null}
      </table>
      <hr />
      <h4>Other Group details</h4>
      <table className="table table-sm table-borderless"
             style={{width: 'auto', maxWidth: '100%'}}>
        {map(otherData, ([first, ...rest], group) => (
          <React.Fragment key={group}>
            <tr>
              <td className="tw-font-medium" rowSpan={rest.length + 1}>
                {group}
              </td>
              <td>
                {first.other_detail}
              </td>
            </tr>
            {rest.map(i => (
              <tr key={i.other_detail}>
                <td>
                  {i.other_detail}
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </table>
    </div>
)

}

export const EthnicityCaptureOtherData = ({ url }) => {
  const {Modal, isOpen, toggle} = useModal();

  return (
    <>
      {isOpen && (
        <Modal size="lg">
          <ModalHeader toggle={toggle}>
            Ethnicity data
          </ModalHeader>
          <ModalBody>
            <EthnicityCaptureOtherDataBody url={url} />
          </ModalBody>
        </Modal>
      )}
      <Button color="link" size="sm" onClick={toggle}>
        View ethnicity data
      </Button>
    </>
  )
}