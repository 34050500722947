import React from 'react';
import {useClient, useModal} from "../../../../hooks";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormProvider, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import {SubmitButton} from "../../../../@coherent/react-hook-form";

export function ConvertToApplicationButton({ user }) {
  const { toggle, Modal, isOpen } = useModal();
  const form = useForm();
  const { handleSubmit } = form;
  const client = useClient();
  const history = useHistory();

  async function onSubmit() {
    const { id } = await client.post(`users/pastors/${user.id}/convert_to_application`).get('data');
    NotificationManager.success('User converted to application');
    history.push(`/applications/${id}`);
  }

  return (
    <FormProvider {...form}>
      {isOpen ? (
        <Modal>
          <ModalHeader toggle={toggle}>
            Convert to Application
          </ModalHeader>
          <ModalBody>
            Are you sure you want to convert this user to an application?
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={toggle}>
              Close
            </Button>
            <SubmitButton onClick={handleSubmit(onSubmit)}>
              Confirm
            </SubmitButton>
          </ModalFooter>
        </Modal>
      ) : null}
      <Button size="sm" onClick={toggle} color="primary" outline>
        Convert to Application
      </Button>
    </FormProvider>
  )
}