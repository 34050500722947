import { useState } from "react";

export const useSteps = (initialStep, steps) => {
  const [step, setStep] = useState(initialStep);

  return {
    nextStep: () => {
      setStep(Math.min(step + 1, steps.length - 1));
    },
    previousStep: () => {
      setStep(Math.max(step - 1, 0));
    },
    step,
  };
};
