import React from "react";
import { useTable } from "hooks";
import { Page } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

export const List = ({ match }) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
    BooleanIcon
  } = useTable({
    match,
    url: "holiday_texts",
    resource: "Holiday Text",
  });

  return (
    <Page title="Clubs">
      <TableSearch className="fa-min" />
      <div className="tw-flex tw-mb-3 tw-items-start tw-flex-wrap">
        <AddNewButton />
        <div className="tw-mx-auto" />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table>
          <thead>
          <tr>
            <th select />
            <th sortBy="holiday">Holiday</th>
            <th sortBy="year">Year</th>
            <th sortBy="start_date">Start</th>
            <th sortBy="end_date">End</th>
            <th>Inc. regions</th>
            <th>Exc. regions</th>
            <th>Messages sent</th>
          </tr>
          </thead>
          <tbody>
          {data.results.map((i) => (
            <tr key={i.id}>
              <td select />
              <td>
                <Link to={`${match.url}/${i.id}`}>{i.holiday}</Link>
              </td>
              <td>{i.year}</td>
              <td>
                {moment(i.start_date).format('DD/MM/YYYY')}
              </td>
              <td>
                {moment(i.end_date).format('DD/MM/YYYY')}
              </td>
              <td>
                {i.include_regions.join(', ')}
              </td>
              <td>
                {i.exclude_regions.join(', ')}
              </td>
              <td>
                <BooleanIcon test={i.first_text_sent} className="mr-1" />
                <BooleanIcon test={i.second_text_sent} className="mr-1" />
                <BooleanIcon test={i.third_text_sent} />
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
