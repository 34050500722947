import React, { useState } from "react";
import { Formik, Field } from "formik";
import {
  FormGroup,
  Page,
  SubmitButton,
  Checkbox, Editor,
} from "components/common";
import { useFormHelpers, useAsyncEffect } from "hooks";
import { NotificationManager } from "react-notifications";
import {isInt} from "validator";

export const Form = ({ title, values }) => {
  return (
    <Page title={title}>
      <FormGroup name="name" label="Name">
        <Field name="name" className="form-control" />
      </FormGroup>
      <FormGroup name="vimeo_video_id" label="Vimeo Video ID">
        <Field name="vimeo_video_id" className="form-control" />
      </FormGroup>
      <FormGroup name="content" label="Content">
        <Field name="content" component={Editor} />
      </FormGroup>
      <FormGroup name="display" label="Display as new video resource?" check>
        <Field
          name="display"
          component={Checkbox}
          className="form-check-input"
        />
      </FormGroup>
      <div className="tw-flex tw-justify-end">
        <SubmitButton>Save</SubmitButton>
      </div>
    </Page>
  );
};

const EditVideoResourceFileView = ({ directoryId, reload }) => {
  const {
    validationObject,
    setInitialValues,
    initialValues,
    load,
    save,
    replacePathId,
    pageTitle,
  } = useFormHelpers({
    url: `video_resource_directories/${directoryId}/files`,
    resource: "Video Resource",
  });

  useAsyncEffect(async () => {
    const initialValues = await load({
      name: "",
      vimeo: null,
      content: {
        blocks: [],
        entityMap: {},

      },
    });
    setInitialValues(initialValues);
  }, []);

  async function onSubmit(x, { setSubmitting }) {
    try {
      const resp = await save(x);
      replacePathId(resp.id, { reload: false });
      reload();
      NotificationManager.success("Saved successfully");
    } finally {
      setSubmitting(false);
    }
  }

  if (!initialValues) return null;

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validationObject({
          name: (v) => !v,
          vimeo_video_id: (v) => !isInt(v + "")
        })}
      >
        {(formik) => <Form title={pageTitle} {...formik} />}
      </Formik>
    </>
  );
};

export const EditVideoResourceFile = (props) => {
  const [key, setKey] = useState();
  return <EditVideoResourceFileView {...props} reload={() => setKey((v) => !v)} key={key} />;
};
