import { FormProvider, useForm } from "react-hook-form";
import {
  Step1Form,
  Step2Form,
  Step3Form,
  Step4Form,
} from "../../application_form/components";
import {
  Checkbox,
  FormGroup,
  SubmitButton,
} from "../../../../@coherent/react-hook-form";
import React from "react";
import {useUserContext} from "../../../../hooks";

export const ApplicationForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    shouldUnregister: true,
  });
  const { handleSubmit } = methods;
  const { isVPSupport } = useUserContext();

  return (
    <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={!isVPSupport}>
            <Step1Form required={false} />
            <hr />
            <Step2Form required={false} />
            <hr />
            <Step3Form required={false} />
            <hr />
            <Step4Form required={false} />
            <hr />
            <FormGroup
              name="submit"
              check
              label="Mark this application as submitted"
            >
              <Checkbox
                className="form-check-input"
                name="submit"
                defaultValue={!!defaultValues.submitted}
              />
            </FormGroup>
            <div className="tw-flex tw-justify-end">
              <SubmitButton>Submit</SubmitButton>
            </div>
          </fieldset>
        </form>
    </FormProvider>
  );
};
