import React from "react";

export const Checkbox = ({ field, form, id, ...props }) => {
  return (
    <input
      id={id || field.name}
      type="checkbox"
      name={field.name}
      checked={!!field.value}
      onChange={(e) => {
        form.setFieldValue(field.name, e.target.checked);
      }}
      {...props}
    />
  );
};

export default Checkbox;
