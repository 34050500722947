import React, { useMemo } from "react";
import { Switch, Route as BaseRoute, Redirect } from "react-router-dom";
import { Page } from "components/common";
import { isUndefined } from "lodash";
import { useUserContext } from "hooks";
import {
  Login,
  Sessions,
  ParcelProvisionSessions,
  Registered,
  Users,
  Groups,
  Dashboard,
  Setup,
  ReadySteadyCook,
  News,
  Videos,
  ChurchConnections,
  ImportantDates,
  Organisations,
  Welcome,
  KitchenSink,
  PasswordReset,
  GoldenNumbersManualEntryList,
  GoldenNumbersManualEntryEdit,
  ParcelProvisionsManualEntryList,
  ParcelProvisionsManualEntryEdit,
  OrganisationStatus,
  TextMessages,
  RegionalLeaders,
  HolidayTexts,
  TeamEdit,
  TeamList,
  TeamSectionList,
  TeamSectionEdit,
  MeetTheTeam, OrganisationOpenStatus
} from "./pages";
import { ApiClient } from "services";
import {EditVideoResourceFile} from "./pages/KitchenSink/Admin/EditVideo";
import ListVideos from "./pages/KitchenSink/User/ListVideos";
import {ApplicationsList, ApplicationView, ReferralView, ApplicationFormView, ReferenceFormView, DBSFormView} from "./features/applications";
import {ParticipantRegistration} from "./features/participant_registration/ParticipantRegistration";
import {
  RegisteredDuplicates
} from "./features/registered_duplicates/RegisteredDuplicates";
import {
  ParticipantFeedback,
  Survey,
  SurveyView
} from "./features/participant_feedback";

const Forbidden = () => (
  <Page>
    <div>
      <span className="fa fa-exclamation-circle fa-fw text-danger" /> Forbidden
    </div>
    You do not have permission to access this page.
  </Page>
);

export const NotFound = () => (
  <Page>
    <div className="tw-text-xl">
      <span className="fa fa-question-circle text-info fa-fw" /> Not Found
    </div>
    The page you have requested could not be found.
  </Page>
);

export const Route = ({
  path,
  component: Component,
  location,
  computedMatch,
  test,
  exact,
  ...passedProps
}) => {
  const client = useMemo(() => ApiClient(), []);

  return (
    <BaseRoute
      path={path}
      exact={exact}
      render={(props) => {
        if (!isUndefined(test) && !test) {
          return <Forbidden />;
        }

        return <Component client={client} {...passedProps} {...props} />;
      }}
    />
  );
};

export const Routes = () => {
  const {
    user,
    loading,
    isStaff,
    isPastor,
    allowMultipleGroups,
    isBoxesOfHope,
    isTLG,
    isVPSupport,
    isVP,
    isBoxesOfHopeStaff,
    isVPGeneral,
    isTLGStaff,
    isCoordinator,
    isCoreTeamMember,
    isSafeGuardingLeader,
    isPartnerships
  } = useUserContext();
  if (loading) return null;

  const redirectTo = isStaff ? "/dashboard" : (
    isBoxesOfHope ? '/dashboard' : '/welcome'
  );

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route exact path="/refer_applicant" component={ReferralView} />
      <Route exact path="/applications" component={ApplicationsList} test={isVPSupport || isVPGeneral || isCoordinator || isCoreTeamMember || isSafeGuardingLeader || isPartnerships} />
      <Route path="/applications/:id" component={ApplicationView} test={isVPSupport || isVPGeneral || isCoordinator || isCoreTeamMember || isSafeGuardingLeader || isPartnerships} />
      <Route exact path="/application_forms/:id" component={ApplicationFormView} />
      <Route exact path="/reference_forms/:id" component={ReferenceFormView} />
      <Route exact path="/dbs_forms/:id" component={DBSFormView} />
      <Route path="/registration/:organisation_hashid" component={ParticipantRegistration} />
      <Route path="/survey/:organisation_hashid" component={Survey} />
      <Route
        exact
        path="/password_reset_request"
        component={PasswordReset.PasswordResetRequest}
      />
      <Route
        exact
        path="/password_reset/:token"
        component={PasswordReset.PasswordReset}
      />
      {!user && <Redirect to="/login" />}
      <Route exact path="/sessions" component={Sessions.List} test={isPastor && isTLG && !isSafeGuardingLeader} />
      <Route
        exact
        path="/sessions/:id"
        component={Sessions.View}
        test={isPastor &&  isTLG && !isSafeGuardingLeader}
      />
      <Route
        exact
        path="/parcel_provision_sessions"
        component={ParcelProvisionSessions.List}
        test={isPastor && !isSafeGuardingLeader}
      />
      <Route
        exact
        path="/parcel_provision_sessions/:id"
        component={ParcelProvisionSessions.View}
        test={isPastor && !isSafeGuardingLeader}
      />
      <Route
        exact
        path="/registered"
        component={Registered.List}
        test={isPastor && !isSafeGuardingLeader}
      />
      <Route
        exact
        path="/registered/:id"
        component={Registered.Register}
        test={isPastor && !isSafeGuardingLeader}
      />
      <Route
        exact
        path="/registered_duplicates"
        component={RegisteredDuplicates}
      />
      <Route exact path="/setup" component={Setup} test={isPastor && isTLG && !isSafeGuardingLeader} />
      <Route
        exact
        path="/users/staff"
        component={Users.List}
        test={isVPSupport}
        type="staff"
      />
      <Route
        exact
        path="/users/staff/:id"
        component={Users.Edit}
        test={isVPSupport}
        type="staff"
      />
      <Route
        exact
        path="/users/pastors"
        component={Users.List}
        test={isVP || isBoxesOfHopeStaff}
        type="pastors"
      />
      <Route
        exact
        path="/users/pastors/:id"
        component={Users.Edit}
        test={isVP || isBoxesOfHopeStaff}
        type="pastors"
      />
      <Route
        exact
        path="/groups"
        component={Groups.List}
        test={isPastor && allowMultipleGroups && isTLG && !isSafeGuardingLeader}
      />
      <Route
        exact
        path="/groups/:id"
        component={Groups.Edit}
        test={isPastor && allowMultipleGroups &&  isTLG && !isSafeGuardingLeader}
      />
      <Route
        exact
        path="/dashboard"
        component={Dashboard}
        test={isStaff || isPastor}
      />
      <Route
        exact
        path="/ready_steady_cook"
        component={ReadySteadyCook}
        test={isPastor &&  isTLG && !isSafeGuardingLeader}
      />
      <Route exact path="/news" component={News.List} test={isTLG && isVPSupport} />
      <Route exact path="/news/:id" component={News.Edit} test={isTLG && isVPSupport} />
      <Route
        exact
        path="/organisations"
        component={Organisations.List}
        test={isVP || isBoxesOfHopeStaff}
      />
      <Route
        exact
        path="/organisations/:id"
        component={Organisations.Edit}
        test={isVP || isBoxesOfHopeStaff}
      />
      <Route
        exact
        path="/church_relationship_leads"
        component={RegionalLeaders.List}
        test={isVPSupport && isTLG}
      />
      <Route
        exact
        path="/church_relationship_leads/:id"
        component={RegionalLeaders.Edit}
        test={isVPSupport && isTLG}
      />
      <Route
        exact
        path="/golden_numbers_manual_entries"
        component={GoldenNumbersManualEntryList}
        test={isVPSupport && isTLG}
      />
      <Route
        exact
        path="/golden_numbers_manual_entries/:id"
        component={GoldenNumbersManualEntryEdit}
        test={isVPSupport && isTLG}
      />
      <Route
        exact
        path="/parcel_provisions_manual_entries"
        component={ParcelProvisionsManualEntryList}
        test={isVPSupport || isBoxesOfHopeStaff}
      />
      <Route
        exact
        path="/parcel_provisions_manual_entries/:id"
        component={ParcelProvisionsManualEntryEdit}
        test={isVPSupport || isBoxesOfHopeStaff}
      />
      <Route exact path="/videos" component={Videos.List} test={isVPSupport && isTLG} />
      <Route exact path="/videos/:id" component={Videos.Edit} test={isVPSupport && isTLG} />
      <Route
        path="/admin/resources"
        component={KitchenSink.Admin.Entry}
        test={isStaff && (isVPSupport || isBoxesOfHope)}
      />
      <Route
        path="/admin/video_resources"
        component={KitchenSink.Admin.Entry}
        key={'video_resource_directories'}
        test={isStaff && (isVPSupport || isBoxesOfHope)}
        url={'video_resource_directories'}
        singular={'Video Resource'}
        plural={'Video Resources'}
        editComponent={EditVideoResourceFile}
      />
      <Route
        path="/resources"
        component={KitchenSink.User.Entry}
        test={isPastor || isVPGeneral || isTLGStaff}
      />
      <Route
        path="/video_resources"
        component={KitchenSink.User.Entry}
        listComponent={ListVideos}
        key={"video_resource_directories"}
        url="video_resource_directories"
        test={isPastor || isVPGeneral || isTLGStaff}
      />
      <Route
        exact
        path="/important_dates"
        component={ImportantDates.List}
        test={isVPSupport && isTLG}
      />
      <Route
        exact
        path="/important_dates/:id"
        component={ImportantDates.Edit}
        test={isVPSupport && isTLG}
      />
      <Route
        exact
        path="/church_connections"
        component={ChurchConnections.List}
        test={isVPSupport && isTLG}
      />
      <Route
        exact
        path="/church_connections/:id"
        component={ChurchConnections.Edit}
        test={isVPSupport && isTLG}
      />
      <Route
        exact
        path="/organisation_status"
        component={OrganisationStatus}
        test={isVP && isTLG}
      />
      <Route
        exact
        path="/club_open_list"
        component={OrganisationOpenStatus}
        test={isStaff && isTLG}
      />
      <Route
        exact
        path="/organisation_status/inbox"
        component={TextMessages}
        test={isVP && isTLG}
      />
      <Route
        exact
        path="/holiday_texts"
        component={HolidayTexts.List}
        test={isVPSupport}
      />
      <Route
        exact
        path="/holiday_texts/:id"
        component={HolidayTexts.Edit}
        test={isVPSupport}
      />
      <Route
        exact
        path="/team_members"
        component={TeamList}
        test={isVPSupport}
      />
      <Route
        exact
        path="/team_members/:id"
        component={TeamEdit}
        test={isVPSupport}
      />
      <Route
        exact
        path="/team_sections"
        component={TeamSectionList}
        test={isVPSupport}
      />
      <Route
        exact
        path="/team_sections/:id"
        component={TeamSectionEdit}
        test={isVPSupport}
      />
      <Route
        exact
        path="/meet_the_team"
        component={MeetTheTeam}
      />
      <Route exact path="/welcome" component={Welcome} test={(isPastor || isVPGeneral || isTLGStaff) && isTLG} />
      <Route exact
             path="/participant_feedback"
             component={ParticipantFeedback}
             test={isPastor && isTLG} />
      <Route exact
             path="/participant_feedback/:id"
             component={SurveyView}
             test={isPastor && isTLG} />
      <Redirect from="/" to={redirectTo} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
