import React, { useState, useMemo, useRef } from "react";
import { Page, FormGroup } from "components/common";
import {
  keyBy,
  pickBy,
  keys,
  sortBy,
  find,
  filter,
  transform,
  compact,
} from "lodash";
import {
  useAsyncEffect,
  useTooltip,
  usePendingButton,
  useRouter,
  useOnUpdate,
  useUserContext,
} from "hooks";
import moment from "moment";
import Switch from "react-switch";
import Select from "react-select";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Alert, Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { SupportIcon } from "../../components";

const Warning = ({ children, ...props }) =>
  children === true ? null : (
    <Alert className="tw-mt-2 tw-py-1 tw-px-2" color="warning" {...props}>
      {children}
    </Alert>
  );

const Row = ({
  attending,
  setAttending,
  i,
  onRemove,
  toggle = true,
  sessionId,
}) => {
  const { id: dietaryId, Tooltip: DietaryTooltip } = useTooltip();
  const { id: medicalId, Tooltip: MedicalTooltip } = useTooltip();
  const { id: firstAidId, Tooltip: FirstAidTooltip } = useTooltip();
  const { id: foodHygieneId, Tooltip: FoodHygieneTooltip } = useTooltip();
  const { id: coreTeamId, Tooltip: CoreTeamTooltip } = useTooltip();
  const { id: photoId, Tooltip: PhotoTooltip } = useTooltip();

  const [RemoveButton] = usePendingButton();

  return (
    <tr key={i.id}>
      <td>
        <div key={i.id} className="tw-flex tw-mr-4 tw-mb-2">
          {toggle ? (
            <div className="tw-mr-3">
              <Switch
                checked={!!attending[i.id]}
                onChange={(checked) => {
                  setAttending((v) => ({
                    ...v,
                    [i.id]: checked ? i : null,
                  }));
                }}
              />
            </div>
          ) : null}
          <Link to={`/registered/${i.id}?session=${sessionId}`}>{i.name}</Link>
        </div>
      </td>
      <td>
        {i.has_dietary_requirements && i.dietary_requirements ? (
          <>
            <span
              id={dietaryId}
              className="fa fa-cutlery fa-lg fa-fw text-danger tw-cursor-pointer"
            />
            <DietaryTooltip placement="bottom">
              <div className="tw-whitespace-pre-line">
                {i.dietary_requirements}
              </div>
            </DietaryTooltip>
          </>
        ) : (
          <span className="fa fa-cutlery fa-lg text-muted tw-opacity-25 fa-fw" />
        )}
      </td>
      <td>
        {i.has_medical_conditions && i.medical_conditions ? (
          <>
            <span
              id={medicalId}
              className="fa fa-medkit fa-lg text-danger fa-fw tw-cursor-pointer"
            />
            <MedicalTooltip placement="bottom">
              <div className="tw-whitespace-pre-line">
                {i.medical_conditions}
              </div>
            </MedicalTooltip>
          </>
        ) : (
          <span className="fa fa-medkit fa-lg text-muted tw-opacity-25 fa-fw" />
        )}
      </td>
      <td>
        <SupportIcon
          active={i.accessing_wider_support}
          label={i.accessing_wider_support_type_label}
        />
      </td>
      <td>
        <span
          id={foodHygieneId}
          className={classNames("fa fa-fw fa-apple fa-lg", {
            "text-success": i.food_and_hygiene_trained,
            "text-muted tw-opacity-25": !i.food_and_hygiene_trained,
          })}
        />
        {i.food_and_hygiene_trained ? (
          <FoodHygieneTooltip placement="bottom">
            Food and hygiene level 2 trained
          </FoodHygieneTooltip>
        ) : null}
      </td>
      <td>
        <span
          id={firstAidId}
          className={classNames("fa fa-fw fa-user-md fa-lg", {
            "text-success": i.qualified_first_aider,
            "text-muted tw-opacity-25": !i.qualified_first_aider,
          })}
        />
        {i.qualified_first_aider ? (
          <FirstAidTooltip placement="bottom">
            Qualified first aider
          </FirstAidTooltip>
        ) : null}
      </td>
      <td>
        <span
          id={coreTeamId}
          className={classNames("fa fa-fw fa-user-plus fa-lg", {
            "text-success": i.core_team_member,
            "text-muted tw-opacity-25": !i.core_team_member,
          })}
        />
        {i.core_team_member ? (
          <CoreTeamTooltip placement="bottom">Core team member</CoreTeamTooltip>
        ) : null}
      </td>
      <td>
        <span
          id={photoId}
          className={classNames('fa fa-fw fa-photo fa-lg tw-cursor-pointer', {
            'text-success': i.tlg_nation_promotion || i.local_promotion,
            'text-muted tw-opacity-25': !(i.tlg_nation_promotion || i.local_promotion)
          })}
        />
        {i.tlg_nation_promotion || i.local_promotion ? (
          <PhotoTooltip placement="bottom">
            <ul className="pl-0 tw-list-none">
              {i.tlg_nation_promotion ? (
                <li>TLG National Promotion</li>
              ) : null}
              {i.local_promotion ? (
                <li>Local Promotion</li>
              ) : null}
            </ul>
          </PhotoTooltip>
        ) : null}
      </td>
      <td className="tw-hidden sm:tw-table-cell">
        <small className="text-muted">
          {i.attendance_type_full}
        </small>
      </td>
      <td>
        <RemoveButton
          outline
          color="tlg-orange"
          size="sm"
          onClick={() => onRemove(i)}
        >
          <span className="fa fa-fw fa-trash" />
        </RemoveButton>
      </td>
    </tr>
  );
};

export const View = ({ client, match }) => {
  const [_registered, setRegistered] = useState();
  const [attending, setAttending] = useState();
  const [session, setSession] = useState();
  const [holidayAllocation, setHolidayAllocation] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activityOptions, setActivityOptions] = useState();
  const [_notRegistered, setNotRegistered] = useState();
  const { id } = match.params;
  const sessionId = session ? session.id : null;
  const activities = session ? session.activities : null;
  const holiday = session ? session.holiday : null;
  const activities_other = session ? session.activities_other : null;
  const [activitiesOther, setActivitiesOther] = useState("");
  const [invalidHoliday, setInvalidHoliday] = useState(false);
  const showActivitiesOther = useMemo(
    () => !!find(activities, { label: "Other" }),
    [activities]
  );
  const { history } = useRouter();
  const { user } = useUserContext();

  const registered = useMemo(() => sortBy(_registered, "last_name"), [
    _registered,
  ]);
  const notRegistered = useMemo(() => sortBy(_notRegistered, "last_name"), [
    _notRegistered,
  ]);

  const [numChildren, numAdults] = useMemo(() => {
    if (!registered) return [null, null];
    const a = filter(registered, (i) => i);
    const adults = filter(a, { is_adult: true }).length;
    return [a.length - adults, adults];
  }, [registered]);

  const missingPeople = useMemo(() => {
    const result = transform(
      attending,
      (obj, i) => {
        if (!i) return;
        if (i.food_and_hygiene_trained) {
          obj[0] = false;
        }
        if (i.qualified_first_aider) {
          obj[1] = false;
        }
        if (i.core_team_member) {
          obj[2] = false;
        }
      },
      [
        "Food & Hygiene Level 2 Trained",
        "Qualified First Aider",
        "Core Team Member",
      ]
    );

    return compact(result).join(", ");
  }, [attending]);

  const ref = useRef();
  const mountedRef = useRef();
  ref.current = { showActivitiesOther };

  useAsyncEffect(async () => {
    const [session, activityOptions] = await Promise.all([
      client.get(`sessions/${id}`).get("data"),
      client.get(`sessions/activities`).get("data"),
    ]);

    const holidayAllocation = await client.get(`holiday_allocation/${session.year}`).get('data');
    setHolidayAllocation(holidayAllocation);
    setActivityOptions(activityOptions.map((i) => ({ label: i, value: i })));
    setAttending(keyBy(session.attending, "id"));

    if (!user.two_step_attendance) {
      setRegistered(sortBy(session.attending, "last_name"));
    } else {
      setRegistered(sortBy(session.registered, "last_name"));
    }

    session.activities = session.activities.map((i) => ({
      label: i,
      value: i,
    }));
    setActivitiesOther(session.activities_other);
    setNotRegistered(session.not_registered);
    setSession(session);
    mountedRef.current = true;
  }, [mountedRef]);

  useOnUpdate(
    async () => {
      await client.post(`sessions/${sessionId}/attending`, {
        attending: keys(pickBy(attending)),
      });
    },
    [attending],
    !session
  );

  useOnUpdate(
    async () => {
      if (!user.two_step_attendance) return;
      await client.post(`sessions/${sessionId}/registered`, {
        registered: registered.map((i) => i.id),
      });
    },
    [registered],
    !session
  );

  useOnUpdate(
    async () => {
      await client.patch(`sessions/${sessionId}`, {
        activities: activities.map((i) => i.label),
        activities_other: showActivitiesOther ? activities_other : "",
      });
    },
    [activities, activities_other],
    !session
  );

  useOnUpdate(
    async () => {
      await client.patch(`sessions/${sessionId}`, {
        holiday
      });
    },
    [holiday],
    !session
  );

  function onRemove(i) {
    setRegistered((j) => j.filter((k) => k.id !== i.id));
    setAttending((j) => ({
      ...j,
      [i.id]: undefined,
    }));
    setNotRegistered((j) => [...j, i]);
  }

  if (!session) return null;

  const title = (
    <>
      Attendance for{" "}
      {session.group ? (
        <Link to={`/groups/${session.group}`}>{session.group__name}:</Link>
      ) : (
        "Session Date:"
      )}{" "}
      {moment(session.date).format("DD/MM/YY")}
    </>
  );

  return (
    <Page title={title} className="tw-max-w-3xl">
      <h3 className="tw-font-medium tw-opacity-50 tw-text-lg tw-mb-4 tw--mt-3">
        Children Attending: {numChildren} &nbsp; Adults Attending: {numAdults}
      </h3>
      <FormGroup label="Holiday">
        <Select
          options={[
            ...holidayAllocation,
            {
              holiday: 'Extra Sessions'
            }
          ]}
          getOptionLabel={v => v.holiday}
          getOptionValue={v => v.holiday}
          onChange={value => {
            let invalidHoliday = false;

            if (value && value.start_date) {
              const { start_date, end_date } = value;
              const { date } = session;

              if (!moment(date).isBetween(start_date, end_date, undefined, '[]')) {
                invalidHoliday = true;
              }
            }

            setInvalidHoliday(invalidHoliday);

            if (!invalidHoliday) {
              setSession((j) => ({
                ...j,
                holiday: value ? value.holiday : null,
              }));
            }
          }}
          defaultValue={session}
          className="react-select"
          classNamePrefix="react-select"
        />
      </FormGroup>
      {invalidHoliday && (
        <Alert color="warning">
          This session date does not fall into the dates you have input for this holiday.<br/>
          Please go to the <Link to={{
            pathname: '/setup',
            state: {
              year: moment(session.date).year()
            }
        }}>Setup</Link> page and enter the holiday date range for each holiday that you intend to open your Make Lunch Club and/or provide parcels.
        </Alert>
      )}
      <FormGroup label="Additional Activities">
        <Select
          options={activityOptions}
          isMulti
          onChange={(activities) => {
            setSession((j) => ({
              ...j,
              activities,
            }));
          }}
          value={session.activities}
          className="react-select"
          classNamePrefix="react-select"
        />
        <p className="form-text text-muted small">
          Please select ALL activities that you provided during this session
        </p>
      </FormGroup>
      {showActivitiesOther && (
        <FormGroup>
          <input
            placeholder="Other activity description..."
            type="text"
            className="form-control"
            value={activitiesOther}
            onChange={(e) => {
              setActivitiesOther(e.target.value);
            }}
            onBlur={() => {
              if (activitiesOther !== session.activities_other) {
                setSession((i) => ({
                  ...i,
                  activities_other: activitiesOther,
                }));
              }
            }}
          />
        </FormGroup>
      )}
      <div className="tw-flex tw-mb-6 tw-flex-wrap">
        <FormGroup
          label="Add Attendance"
          className="tw-flex-grow tw-mb-0 tw-mr-2"
        >
          <Select
            options={notRegistered}
            onChange={(i) => {
              if (i) {
                setRegistered((j) => [...j, i]);
                setNotRegistered((j) => j.filter((k) => k.id !== i.id));

                if (!user.two_step_attendance) {
                  setAttending((v) => ({
                    ...v,
                    [i.id]: i,
                  }));
                }
                setIsMenuOpen(true);
              }
            }}
            menuIsOpen={isMenuOpen}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            getOptionLabel={(v) => v.name}
            getOptionValue={(v) => v.id}
            value
            className="react-select"
            classNamePrefix="react-select"
          />
        </FormGroup>
        <UncontrolledDropdown className="tw-self-end">
          <DropdownToggle caret color="primary">
            Register
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              onClick={() => {
                history.push(`/registered/new?session=${sessionId}`);
              }}
            >
              New Child
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                history.push(`/registered/new?session=${sessionId}&adult=true`);
              }}
            >
              New Adult
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
        {user.two_step_attendance ? (
            <Button color="primary" outline size="sm" className="mb-2" onClick={() => {
                const attending = registered.reduce((acc, i) => {
                    acc[i.id] = i;
                    return acc;
                }, {});
                setAttending(attending)
            }}>
                Set all as attending
            </Button>
        ) : null}
      <table className="table table-sm table-borderless tw-w-auto">
        <tbody>
          {registered.map((i) => (
            <Row
              key={i.id}
              i={i}
              sessionId={sessionId}
              onRemove={onRemove}
              attending={attending}
              setAttending={setAttending}
              toggle={user.two_step_attendance}
            />
          ))}
        </tbody>
      </table>
      {missingPeople ? (
        <Warning>
          <strong>The following adults must be added to this session:</strong>{" "}
          {missingPeople}
        </Warning>
      ) : null}
    </Page>
  );
};

export default View;
