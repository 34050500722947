import React, { useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

export default ({
  dateFormat = "yyyy-MM-dd",
  modelDateFormat = "YYYY-MM-DD",
  field: { value, onBlur, name },
  form: { setFieldValue },
  ...props
}) => {
  const selected = useMemo(
    () => (value ? moment(value, modelDateFormat).toDate() : null),
    [value, modelDateFormat]
  );

  const _onChange = useCallback(
    (v) => {
      const m = moment(v);
      setFieldValue(name, m.isValid() ? m.format(modelDateFormat) : null);
    },
    [modelDateFormat, setFieldValue, name]
  );

  return (
    <DatePicker
      onChange={_onChange}
      selected={selected}
      dateFormat={dateFormat}
      onBlur={onBlur}
      name={name}
      autoComplete="off"
      {...props}
    />
  );
};
