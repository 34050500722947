import React, { useEffect } from "react";
import {useTable, useUserContext} from "hooks";
import { Page } from "components/common";
import {Alert, Col, Row} from "reactstrap";
import {Heading} from "./components/Heading";
import {Section} from "./components/Section";
import {Content} from "./components/Content";
import {Preview} from "./Preview";

export const List = ({ match, directory }) => {
  const { TableSearch, TableDataFetch, data, search, setFilters } = useTable({
    match,
    url: "resource_files",
    resource: "Resources",
    limit: 99999,
    ordering: "name",
  });
    const { isTLG, isBoxesOfHope } = useUserContext();

  useEffect(() => {
    setFilters((v) => ({
      ...v,
      resource_directory: directory && !search ? directory.key : undefined,
    }));
  }, [directory, setFilters, search]);

  return (
    <div>
      <Page title="Resources">
        <TableSearch className="fa-min" />
          {isTLG && (
              <p className="mb-0">

                  Welcome to our Resource section, we hope you will find everything you
                  need here to help you run a safe, effective, vibrant and fun Make
                  Lunch Club in your community. We never stop developing our resources
                  so we encourage you to keep visiting this section regularly so you
                  don't miss out on up to date guidance, ideas and inspiration!
              </p>
          )}
          {isBoxesOfHope && (
              <p className="mb-0">
                  Welcome to our Resource section, we hope you will find everything you need here to help you run Boxes of Hope!
              </p>
          )}

        <TableDataFetch />
      </Page>
      <Section
        title={
          search
            ? "Search Results"
            : directory
            ? `Resources for ${directory.title}`
            : "New Resources"
        }
      >
        {data.results.length === 0 ? (
          <Alert color="info" className="mb-0">
            No resources found
          </Alert>
        ) : null}
        {data.results.map((i, idx) => (
          <div key={i.id}>
            {idx > 0 && <hr />}
            <Heading>{i.name}</Heading>
            <Row form>
              <Col xs={false} lg="auto">
                <Preview id={i.id} />
              </Col>
              <Col>
                <Content value={i.content} />
              </Col>
            </Row>


            <div className="mt-2">
              <a
                href={`/api/media/${i.file__name}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="fa fa-file-text mr-1" /> View resource
              </a>
            </div>
          </div>
        ))}
      </Section>
    </div>
  );
};

export default List;
