import React, { useState, useEffect } from "react";
import { useOnMount } from "hooks";
import { Formik } from "formik";
import { Page } from "components/common";
import { Row, Col } from "reactstrap";
import Tree from "rc-tree";
import { without } from "lodash";
import List from "./List";

export const KitchenSink = ({ client, formik, history, match, url = 'resource_directories', listComponent : ListComponent = List }) => {
  const { values, resetForm } = formik;
  const [selectedDirectory, setSelectedDirectory] = useState();
  const [expandedKeys, setExpandedKeys] = useState([]);

  async function load() {
    const traverse = (i) => ({
      key: i.id,
      title: i.name,
      children: i.children.map(traverse),
      isLeaf: false,
    });

    const values = await client.get(url).get("data").map(traverse);

    resetForm({ values });
  }

  useOnMount(load);

  useEffect(() => {
    if (!selectedDirectory) {
      history.replace(match.url);
    } else {
      history.replace(`${match.url}/${selectedDirectory.key}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDirectory]);

  if (!values) return null;

  return (
    <div className="container">
      <Row form>
        <Col xs="auto">
          <Page>
            <Tree
              expandedKeys={expandedKeys}
              treeData={values}
              showIcon={false}
              onSelect={(_, event) => {
                const { eventKey: key, title } = event.node.props;

                if (!event.selected) {
                  setSelectedDirectory(null);
                  setExpandedKeys((v) => without(v, key));
                } else if (event.selected) {
                  setSelectedDirectory({
                    key,
                    title,
                  });

                  setExpandedKeys((v) => [...v, key]);
                }
              }}
            />
          </Page>
        </Col>
        <Col>
          <ListComponent directory={selectedDirectory} />
        </Col>
      </Row>
    </div>
  );
};

export default (props) => {
  return (
    <Formik>{(formik) => <KitchenSink {...props} formik={formik} />}</Formik>
  );
};
