import React from "react";
import { useTable } from "hooks";
import { Page } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

export const List = ({ match }) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
    BooleanIcon,
  } = useTable({
    match,
    url: "news",
    resource: "News",
  });

  return (
    <Page title="News">
      <TableSearch className="fa-min" />
      <div className="tw-flex tw-mb-3 tw-items-start tw-flex-wrap">
        <AddNewButton />
        <div className="tw-mx-auto" />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="title">Title</th>
              <th sortBy="live">Live?</th>
              <th sortBy="community_news">Is Community News?</th>
              <th sortBy="created">Created</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>{i.title}</Link>
                </td>
                <td>
                  <BooleanIcon test={i.live} hideFailed />
                </td>
                <td>
                  <BooleanIcon test={i.community_news} hideFailed />
                </td>
                <td>{moment(i.created).format("DD/MM/YYYY HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
