import React, { useState, memo, useMemo } from "react";
import {useForm, FormProvider, useWatch, useController} from "react-hook-form";
import { useQuery } from "react-query";
import { Page } from "components/common";
import useClient from "../../hooks/useClient";
import {
  FormGroup,
  Select,
  SubmitButton,
} from "@coherent/react-hook-form";
import {range, without} from "lodash";
import {
  Table,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import download from "downloadjs";
import useModal from "../../hooks/useModal";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import ReactDayPicker from "react-day-picker";
import {ClubHealthStatus} from "../../components/ClubHealthStatus";

const STATUS_OPTIONS = [
  { label: "Open", value: "open" },
  { label: "Closed", value: "closed" },
];
const CONFIRMED_OPTIONS = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const UpdateModal = ({ setIsOpen, item, onSubmit }) => {
  const defaultValues = useMemo(
    () => ({
      status: item.holiday_confirmation__is_open ? "open" : "closed",
      start_date: item.holiday_allocation__start_date,
      end_date: item.holiday_allocation__end_date,
      confirmed_by_rl: item.holiday_confirmation__confirmed_by_rl ? "yes" : "no",
      dates: item.holiday_confirmation__dates || []
    }),
    [item]
  );

  const methods = useForm({
    defaultValues,
    shouldUnregister: false,
  });
  const { handleSubmit, watch } = methods;
  const status = watch("status");

  return (
    <>
      <FormProvider {...methods}>
        <ModalBody>
          <FormGroup label="CRL Open Status" name="status">
            <Select
              name="status"
              options={STATUS_OPTIONS}
              simpleValue
              rules={{ validate: (v) => v !== null }}
            />
          </FormGroup>
          {status === "open" ? (
            <>
              <DayPicker name="dates" />
            </>
          ) : null}
          <FormGroup label="Confirmed by CRL" name="confirmed_by_rl">
            <Select
              options={CONFIRMED_OPTIONS}
              rules={{ required: true }}
              simpleValue
              name="confirmed_by_rl"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Close
          </Button>
          <SubmitButton onClick={handleSubmit(onSubmit)}>Update</SubmitButton>
        </ModalFooter>
      </FormProvider>
    </>
  );
};

const UpdateButton = ({ item, refetch }) => {
  const { Modal, setIsOpen, isOpen } = useModal();
  const year = useWatch({
    name: "year",
  });

  const client = useClient();

  async function onSubmit({
    status,
    confirmed_by_text,
    start_date,
    end_date,
    ...x
  }) {
    await client.post(`holiday_allocation/${year}/manual_update`, {
      year,
      holiday: item.holiday,
      organisation: item.organisation,
      is_open: status === "open",
      confirmed_by_text: confirmed_by_text === "yes",
      ...x,
    });
    await refetch({ throwOnError: true });
    NotificationManager.success("Updated successfully");
    setIsOpen(false);
  }

  return (
    <>
      {isOpen ? (
        <Modal>
          <ModalHeader>
            Update {item.holiday} for {item.organisation__organisation_name}
          </ModalHeader>
          <UpdateModal onSubmit={onSubmit} item={item} setIsOpen={setIsOpen} />
        </Modal>
      ) : null}
      <Button color="link" size="sm" onClick={() => setIsOpen(true)}>
        Update
      </Button>
    </>
  );
};

const StatusTable = memo(({ refetch, data }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Club</th>
          <th>Health Status</th>
          <th>Holiday</th>
          <th>Club Open Status</th>
          <th>CRL Open Status</th>
          <th>Confirmed by CRL</th>
          <th>Has data</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data.map((i) => (
          <tr key={i.id}>
            <td>
              <Link to={`/organisations/${i.organisation}`}>
                {i.organisation__organisation_name}
              </Link>
            </td>
            <td>
              <ClubHealthStatus value={i.club_health_status} />
            </td>
            <td>{i.holiday}</td>
            <td>{i.holiday_allocation ? "Open" : "Closed"}</td>
            <td>
              {i.holiday_confirmation__is_open === null ? '' : (i.holiday_confirmation__is_open ? "Open" : "Closed")}
              {i.holiday_confirmation__is_open ? ` (${i.holiday_confirmation__dates.length})` : ''}
            </td>
            <td>{i.holiday_confirmation__confirmed_by_rl ? "Yes" : "No"}</td>
            <td>{i.has_data ? "Yes" : "No"}</td>
            <td className="tw-w-1">
              <UpdateButton item={i} refetch={refetch} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
});

const AggregateCard = ({ title, children }) => {
  return (
    <Card className="tw-h-full">
      <CardHeader>{title}</CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

const AggregateValue = ({ label, children }) => {
  return (
    <div>
      <span className="tw-font-semibold">{label}:</span> {children}
    </div>
  );
};

function DayPicker({ name, rules, control }) {
  const { field } = useController({
    name,
    rules,
    control,
  });
  const { value, onChange } = field;

  const selectedDays = useMemo(() => value.map((i) => moment(i).toDate()), [
    value,
  ]);

  return (
    <div className="tw-flex tw-justify-center">
      <div>
        <ReactDayPicker
          selectedDays={selectedDays}
          onDayClick={(day, { selected, disabled }) => {
            const v = moment(day).format("YYYY-MM-DD");
            onChange(selected ? without(value, v) : [...value, v]);
          }}
        />
        <p className="text-muted small text-center mb-4">
          {selectedDays.length} dates selected
        </p>
      </div>
    </div>
  )
}

export const OrganisationStatus = () => {
  const client = useClient();
  const [criteria, setCriteria] = useState();
  const [years, setYears] = useState();
  const {
    data: { organisations, holidays, regionalLeaders, regions, club_health_statuses },
  } = useQuery(
    ["organisation_status_data"],
    async () => {
      return Promise.props({
        organisations: client.get("organisations").get("data"),
        regionalLeaders: client
          .get("regional_leaders")
          .get("data")
          .map((i) => ({
            label: i.name,
            value: i.id,
          })),
        holidays: client
          .get("holiday_allocation/holidays")
          .get("data")
          .map((i) => ({
            label: i,
            value: i,
          })),
        regions: client.get("organisations/regions").get("data"),
        currentYear: moment().year(),
        club_health_statuses: client.get('organisations/club_health_statuses').get('data')
      });
    },
    {
      onSuccess: ({ currentYear }) => {
        setYears(
          range(2020, currentYear + 2).map((i) => ({
            label: i,
            value: i,
          }))
        );

        setValue("year", currentYear);
      },
      placeholderData: {},
    }
  );

  const { data, isFetching, refetch } = useQuery(
    ["organisation_status", criteria],
    async () => {
      if (!criteria) return null;
      return client
        .post("dashboard/organisation_status", criteria)
        .get("data")
        .map((i, id) => ({
          ...i,
          id,
        }));
    }
  );

  async function onExport() {
    const data = await client
      .post("dashboard/export_organisation_status", criteria)
      .get("data");
    download(data, "organisation_status_export.csv", "text/csv");
  }

  const methods = useForm({
    defaultValues: {
      organisations: [],
      holidays: [],
      regions: [],
      regional_leaders: [],
      club_health_status: [],
      year: null,
      confirmed_by_rl: null,
      is_open: null,
      has_data: null
    },
    shouldUnregister: false,
    mode: "onBlur",
  });
  const { setValue, handleSubmit } = methods;

  const aggregates = useMemo(() => {
    if (!data) return null;
    const processedOrganisations = {};

    return data.reduce(
      (acc, i) => {
        const {
          organisation,
          holiday_confirmation__confirmed_by_rl,
          holiday_confirmation__is_open,
          holiday_allocation,
          has_data,
        } = i;

        if (!processedOrganisations[organisation]) {
          acc.organisations += 1;
          processedOrganisations[organisation] = true;
        }

        if (holiday_confirmation__is_open !== null) {
          if (holiday_confirmation__is_open) {
            acc.rl_open += 1
          } else {
            acc.rl_closed += 1
          }
        }

        if (holiday_allocation) {
          acc.open += 1;
        } else {
          acc.closed += 1;
        }

        if (holiday_confirmation__confirmed_by_rl) {
          acc.confirmed_yes += 1;
        } else {
          acc.confirmed_no += 1;
        }

        if (has_data) {
          acc.has_data_yes += 1;
        } else {
          acc.has_data_no += 1;
        }

        return acc;
      },
      {
        organisations: 0,
        open: 0,
        closed: 0,
        rl_open: 0,
        rl_closed: 0,
        confirmed_yes: 0,
        confirmed_no: 0,
        has_data_yes: 0,
        has_data_no: 0,
      }
    );
  }, [data]);

  return (
    <Page title="Club Open Status">
      <FormProvider {...methods}>
        <FormGroup label="Year" name="year">
          <Select
            name="year"
            options={years}
            simpleValue
            rules={{
              required: true,
            }}
          />
        </FormGroup>
        <FormGroup label="Holidays" name="holidays">
          <Select name="holidays" isMulti simpleValue options={holidays} />
        </FormGroup>
        <FormGroup label="Regions" name="regions">
          <Select name="regions" isMulti simpleValue options={regions} />
        </FormGroup>
        <FormGroup label="Church Relationship Leads" name="regional_leaders">
          <Select
            name="regional_leaders"
            isMulti
            simpleValue
            options={regionalLeaders}
          />
        </FormGroup>
        <FormGroup label="Clubs" name="organisations">
          <Select
            name="organisations"
            isMulti
            simpleValue
            valueKey="id"
            labelKey="organisation_name"
            options={organisations}
          />
        </FormGroup>
        <FormGroup label="Club Open Status" name="is_open">
          <Select
            name="is_open"
            simpleValue
            isClearable
            options={STATUS_OPTIONS}
          />
        </FormGroup>
        <FormGroup label="CRL Open Status" name="rl_is_open">
          <Select
            name="rl_is_open"
            simpleValue
            isClearable
            options={STATUS_OPTIONS}
          />
        </FormGroup>
        <FormGroup label="Confirmed by CRL" name="confirmed_by_rl">
          <Select
            name="confirmed_by_rl"
            simpleValue
            isClearable
            options={CONFIRMED_OPTIONS}
          />
        </FormGroup>
        <FormGroup label="Club Health Status" name="club_health_status">
          <Select name="club_health_status" isMulti simpleValue options={club_health_statuses} />
        </FormGroup>
        <FormGroup label="Has data" name="has_data">
          <Select
            name="has_data"
            simpleValue
            isClearable
            options={CONFIRMED_OPTIONS}
          />
        </FormGroup>
        <div className="tw-flex tw-justify-end mb-4">
          <SubmitButton
            pending={isFetching}
            onClick={handleSubmit((v) => setCriteria({
              ...v,
              confirmed_by_rl: !v.confirmed_by_rl ? null : v.confirmed_by_rl === 'yes',
              has_data: !v.has_data ? null : v.has_data === 'yes',
              is_open: !v.is_open ? null : v.is_open === 'open',
              rl_is_open: !v.rl_is_open ? null : v.rl_is_open === 'open',
            }))}
          >
            Run
          </SubmitButton>
        </div>
        {data ? (
          <>
            <hr />
            {data.length ? (
              <SubmitButton className="mb-4" onClick={handleSubmit(onExport)}>
                <span className="fa fa-download tw-mr-2" />
                Export
              </SubmitButton>
            ) : null}
            <Row form className="mb-4">
              <Col>
                <AggregateCard title="Total Clubs">
                  <AggregateValue label="Count">
                    {aggregates.organisations}
                  </AggregateValue>
                </AggregateCard>
              </Col>
              <Col>
                <AggregateCard title="Club Open Status">
                  <AggregateValue label="Open">
                    {aggregates.open}
                  </AggregateValue>
                  <AggregateValue label="Closed">
                    {aggregates.closed}
                  </AggregateValue>
                </AggregateCard>
              </Col>
              <Col>
                <AggregateCard title="CRL Open Status">
                  <AggregateValue label="Open">
                    {aggregates.rl_open}
                  </AggregateValue>
                  <AggregateValue label="Closed">
                    {aggregates.rl_closed}
                  </AggregateValue>
                </AggregateCard>
              </Col>
              <Col>
                <AggregateCard title="Confirmed by CRL">
                  <AggregateValue label="Yes">
                    {aggregates.confirmed_yes}
                  </AggregateValue>
                  <AggregateValue label="No">
                    {aggregates.confirmed_no}
                  </AggregateValue>
                </AggregateCard>
              </Col>
              <Col>
                <AggregateCard title="Has data">
                  <AggregateValue label="Yes">
                    {aggregates.has_data_yes}
                  </AggregateValue>
                  <AggregateValue label="No">
                    {aggregates.has_data_no}
                  </AggregateValue>
                </AggregateCard>
              </Col>
            </Row>
            <StatusTable refetch={refetch} data={data} />
          </>
        ) : null}
      </FormProvider>
    </Page>
  );
};
