import React from "react";
import { useTable } from "hooks";
import { Page } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

export const List = ({ match }) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
  } = useTable({
    match,
    url: "regional_leaders",
    resource: "Church Relationship Lead",
  });

  return (
    <Page title="Church Relationship Leads">
      <TableSearch className="fa-min" />
      <div className="tw-flex tw-mb-3 tw-items-start tw-flex-wrap">
        <AddNewButton />
        <div className="tw-mx-auto" />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="name">Name</th>
              <th>Has active Clubs?</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>{i.name}</Link>
                </td>
                <td>
                  {i.active ? (
                    <span className="fa fa-check-circle text-success" />
                  ) : (
                    <span className="fa fa-times-circle text-danger" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
