import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import React from "react";
import {
  Checkbox,
  FormGroup,
  SubmitButton,
} from "../../../../@coherent/react-hook-form";
import { Button } from "reactstrap";
import { Reference } from "./Reference";

export const Step4Form = ({ required = true }) => {
  const { fields } = useFieldArray({
    name: "references",
  });

  return (
    <>
      <h3 className="tw-text-lg mb-4">References</h3>
      <p>
        We request that you fill in the details of two referees that you have
        known for{" "}
        <strong>
          at least 2 years. One must be your current church leader* and the
          second should be an employer/former employer/someone who has known you
          in a volunteer capacity.
        </strong>{" "}
        We do not accept any references from family members.
      </p>
      <p className="small">
        We reserve the right to make additional character enquiries
        considered necessary and we may also call your referees.
      </p>
      {fields.map((i, idx) => (
        <Reference
          field={i}
          key={i.id}
          index={idx}
          required={idx < 2 && required}
        />
      ))}
      <FormGroup
        label={
          <>
            As a Make Lunch volunteer, I would like to hear all about TLG's inspiring stories of transformation
            from across the UK in the following ways
          </>
        }
        name="contact"
      >
        <FormGroup
          check
          htmlFor="contact_email"
          label="Email - You’ll receive our inspiring email updates celebrating powerful stories"
        >
          <Checkbox
            name="contact_email"
            id="contact_email"
            className="form-check-input"
          />
        </FormGroup>
        <FormGroup
          check
          htmlFor="contact_post"
          label="Post - You’ll receive our FREE fantastic Hope & a future magazine"
        >
          <Checkbox
            name="contact_post"
            id="contact_post"
            className="form-check-input"
          />
        </FormGroup>
        <FormGroup
          check
          htmlFor="contact_phone"
          label="Phone - Hear the difference you are making to TLG – don’t worry we won’t call you frequently"
        >
          <Checkbox
            name="contact_phone"
            id="contact_phone"
            className="form-check-input"
          />
        </FormGroup>
        <FormGroup
          check
          htmlFor="contact_text"
          label="Text/SMS Message - I'm happy for TLG to text me occasionally"
        >
          <Checkbox
            name="contact_text"
            id="contact_text"
            className="form-check-input"
          />
        </FormGroup>
      </FormGroup>
    </>
  );
};

export const Step4 = ({ onSubmit: onSubmitProp, defaultValues, onBack }) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
    shouldUnregister: true,
  });

  const { handleSubmit } = methods;

  async function onSubmit(x) {
    return onSubmitProp({
      ...x,
      submit: true,
    });
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step4Form />
        <div className="tw-flex tw-justify-between">
          <Button onClick={onBack}>Back</Button>
          <SubmitButton>Submit</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
