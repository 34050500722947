import { FormProvider, useForm } from "react-hook-form";
import React from "react";
import { SubmitButton } from "../../../../@coherent/react-hook-form";
import { TextQuestion } from "../../components/TextQuestion";
import { Button } from "reactstrap";

export const Step2Form = ({ required = true }) => {
  return (
    <>
      <h3 className="tw-text-lg mb-4">Questions regarding how you know the applicant</h3>
      <p>
        Please note, we request that referees have known the applicant for at least 2 years and should be a employer/former employer/someone who has known them in a volunteer capacity. We do not accept any references from family members and you should state in what capacity you have known them as per the above and not just as a 'friend'.
      </p>
      <hr/>
      <TextQuestion name="applicant_name" label="Name of the Applicant" required={required} />
      <TextQuestion name="relationship" label="In what capacity do you know the applicant" required={required} />
      <TextQuestion name="years_known" label="How long have you known the applicant" required={required} />
    </>
  );
};

export const Step2 = ({ onSubmit, defaultValues, onBack }) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
    shouldUnregister: true,
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step2Form />
        <div className="tw-flex tw-justify-between">
          <Button onClick={onBack}>Back</Button>
          <SubmitButton>Continue</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
