import { FormProvider, useForm } from "react-hook-form";
import { ReferralForm } from "../../referrals/components";
import React from "react";

export const Referral = ({ defaultValues }) => {
  const methods = useForm({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <ReferralForm disabled />
    </FormProvider>
  );
};
