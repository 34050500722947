import React, { useMemo } from "react";
import ReactSelect from "react-select";
import { keyBy, map } from "lodash";
import classNames from "classnames";

export const Select = ({
  form,
  field,
  options,
  labelKey = "label",
  valueKey = "value",
  simpleValue,
  className,
  isMulti,
  ...props
}) => {
  const { name, value } = field;
  const { setFieldValue, setFieldTouched } = form;

  const keyedOptions = useMemo(
    () => (options && simpleValue ? keyBy(options, valueKey) : {}),
    [options, valueKey, simpleValue]
  );
  const selected = useMemo(() => {
    if (simpleValue) {
      if (isMulti) {
        return map(value, (i) => keyedOptions[i]);
      } else {
        return keyedOptions[value];
      }
    }
    return value;
  }, [simpleValue, keyedOptions, value, isMulti]);

  return (
    <ReactSelect
      name={name}
      options={options}
      onChange={(v) => {
        if (simpleValue) {
          if (isMulti) {
            setFieldValue(
              name,
              map(v, (i) => i[valueKey]),
              false
            );
          } else {
            setFieldValue(name, v ? v[valueKey] : null, false);
          }
        } else {
          setFieldValue(name, v, false);
        }

        setFieldTouched(name, true);
      }}
      onBlur={() => setFieldTouched(name, true)}
      value={selected}
      getOptionLabel={(v) => v[labelKey]}
      getOptionValue={(v) => v[valueKey]}
      className={classNames("react-select", className)}
      classNamePrefix="react-select"
      isMulti={isMulti}
      {...props}
    />
  );
};

export default Select;
