import React from 'react';
import {reduce} from "lodash";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  XAxis, YAxis
} from "recharts";
import {useMemo} from "react";
import numeral from "numeral";

const defaultColors =`#3366cc
#dc3912
#ff9900
#109618
#990099
#0099c6
#dd4477
#66aa00
#b82e2e
#316395
#994499
#22aa99
#aaaa11
#6633cc
#e67300
#8b0707
#651067
#329262
#5574a6
#3b3eac
#b77322
#16d620
#b91383
#f4359e
#9c5935
#a9c413
#2a778d
#668d1c
#bea413
#0c5922
#743411`.split('\n');

export const ActivitiesOrganisationGraph = ({
                                 data: dataProp,
                                 colors: colorsProp,
                               }) => {
  const colors = colorsProp || defaultColors;

  const graph = useMemo(() => {
    const { total, activities } = dataProp;
    const ret = reduce(
      activities,
      (acc, i) => {
        const { activity, count } = i;

        const percentage = (count / total) || 0;
        const label = `${activity}: ${numeral(percentage).format('0[.][0]%')}`;
        acc.labels.add(label)
        acc.data[0][label] = percentage * 100;
        return acc;
      },
      {
        data: [{
          name: 'Activity'
        }],
        labels: new Set()
      }
    );

    ret.labels = [...ret.labels];

    return ret;
  }, [dataProp]);

  return (
    <ResponsiveContainer minHeight={400}>
      <BarChart data={graph.data}>
        <YAxis allowDecimals={false} unit="%" />
        <XAxis dataKey="name" />
        {graph.labels.map((i, index) => (
          <Bar key={`bar-${index}`} dataKey={i} fill={colors[index % colors.length]} />
        ))}
        <Legend layout="vertical" />
      </BarChart>
    </ResponsiveContainer>
  );
};