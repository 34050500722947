import React, { useState, useMemo } from "react";
import {useOnMount, useUserContext} from "hooks";
import {Card, CardBody, CardHeader, CardColumns, Col, Row} from "reactstrap";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import {VimeoVideo} from "../../components/VimeoVideo";
import {Preview} from "../KitchenSink/User/Preview";
import {useQuery} from "react-query";
import {Link} from "react-router-dom";

const Section = ({ title, children, scroll }) => {
  return (
    <Card className="tw-mb-4">
      <CardHeader className="bg-tlg-green text-white tw-font-medium">
        {title}
      </CardHeader>
      <CardBody
        style={
          scroll
            ? {
                maxHeight: "25rem",
                overflowY: "auto",
              }
            : null
        }
      >
        {children}
      </CardBody>
    </Card>
  );
};

const Heading = ({ children }) => (
  <h3 className="tw-font-light tw-text-3xl">{children}</h3>
);

const Content = ({ value: valueProp }) => {
  const value = useMemo(() => draftToHtml(valueProp), [valueProp]);

  return <div dangerouslySetInnerHTML={{ __html: value }} />;
};

const SaferRecruitmentStatus = ({ value }) => {
  const { application_completed, references_completed, dbs_confirmed } = value;

  const positive = <span className="fa fa-check-circle text-success" />
  const negative = <span className="fa fa-times-circle text-danger" />

  const referencesComplete = (
    <span>
      <strong>{references_completed}</strong> reference(s) received
    </span>
  );

  const create = (test, positiveMessage, negativeMessage) => {
    return (
      <li>
        {test ? (
          <>
            {positive} {positiveMessage}
          </>
        ) : (
          <>
            {negative} {negativeMessage}
          </>
        )}
      </li>
    )
  }

  return (
    <ul className="tw-list-none pl-0">
      {create(application_completed, 'Application completed', 'Application not complete')}
      {create(references_completed > 1, referencesComplete, referencesComplete)}
      {create(dbs_confirmed, 'DBS confirmed', 'DBS not confirmed')}
    </ul>
  )
}

export const Welcome = ({ client }) => {
  const [data, setData] = useState();
  const {
    church_connections,
    news,
    community_news,
    videos,
    important_dates,
    resources,
  } = data || {};
  const { isCoordinator } = useUserContext();

  useOnMount(async () => {
    const data = await client.get("welcome").get("data");
    setData(data);
  });

  const { data: saferRecruitmentStatus } = useQuery(['me/safer_recruitment_status'], () => (
    client.get('me/safer_recruitment_status').get('data')
  ), {
    useErrorBoundary: false
  })

  console.log(saferRecruitmentStatus);

  if (!data) return null;

  return (
    <div className="container-fluid">
      <CardColumns>
        <Section title="News & Events" scroll>
          {news
            ? news.map((i, idx) => (
                <div key={i.id}>
                  {idx > 0 && <hr />}
                  {i.image__name ? (
                    <img
                      src={"/api/media/" + i.image__name}
                      className="img-fluid tw-mb-4"
                      alt=""
                    />
                  ) : null}
                  <Heading>{i.title}</Heading>
                  <Content value={i.content} />
                </div>
              ))
            : null}
        </Section>
        <Section title="Community News" scroll>
          {community_news
            ? community_news.map((i, idx) => (
                <div key={i.id}>
                  {idx > 0 && <hr />}
                  {i.image__name ? (
                    <img
                      src={"/api/media/" + i.image__name}
                      className="img-fluid tw-mb-4"
                      alt=""
                    />
                  ) : null}
                  <Heading>{i.title}</Heading>
                  <Content value={i.content} />
                </div>
              ))
            : null}
        </Section>
        {saferRecruitmentStatus ? (
          <Section title="Safer Recruitment Status">
            <SaferRecruitmentStatus value={saferRecruitmentStatus} />
          </Section>
        ): null}

        <Section title="TLG TV">
          {videos &&
            videos.map((i, idx) => (
              <div key={i.id}>
                {idx > 0 && <hr />}
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                  <VimeoVideo style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }} title="TLG TV" videoId={i.vimeo_video_id} />
                </div>
                {i.title ? <Heading>{i.title}</Heading> : null}
                {i.content ? <Content value={i.content} /> : null}
              </div>
            ))}
        </Section>
        <Section title="Church Connections" scroll>
          {church_connections
            ? church_connections.map((i, idx) => (
                <div key={i.id}>
                  {idx > 0 && <hr />}
                  {i.image__name ? (
                    <img
                      src={"/api/media/" + i.image__name}
                      className="img-fluid tw-mb-4"
                      alt=""
                    />
                  ) : null}
                  <Heading>{i.title}</Heading>
                  <Content value={i.content} />
                </div>
              ))
            : null}
        </Section>
        <Section title="New Resources" scroll>
          {resources
            ? resources.map((i, idx) => (
                <div key={i.id}>
                  {idx > 0 && <hr />}
                  <Heading>{i.name}</Heading>
                  <Row form>
                    <Col xs={false} lg="auto">
                      <Preview id={i.id} />
                    </Col>
                    <Col>
                      <Content value={i.content} />
                    </Col>
                  </Row>
                  <div className="mt-2">
                    <a
                      href={`/api/media/${i.file__name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fa fa-file-text mr-1" /> View resource
                    </a>
                  </div>
                </div>
              ))
            : null}
        </Section>
        <Section title="Important Dates" scroll>
          {important_dates
            ? important_dates.map((i, idx) => (
                <div key={i.id}>
                  {idx > 0 && <hr />}
                  <Heading>{i.title}</Heading>
                  <Content value={i.content} />
                  <div>
                    <span className="fa fa-calendar text-tlg-teal fa-fw" />{" "}
                    {moment(i.date).format("DD/MM/YYYY")}
                  </div>
                </div>
              ))
            : null}
        </Section>
        <Section title="Get in Touch">
          Need to get in touch with TLG? Contact us at{" "}
          <a href="mailto:makelunch@tlg.org.uk">makelunch@tlg.org.uk</a>
          <br />
          Telephone: 01274 900389, website{" "}
          <a href="https://www.tlg.org.uk">www.tlg.org.uk</a>
          <br/>
          {isCoordinator && (
            <Link to="/refer_applicant">Refer a new Core Team Member</Link>
          )}
        </Section>
        <Section title="Join the Community">
          Make Lunch has an active Facebook page for all Core team members which
          provides a great opportunity to connect with and encourage other clubs
          across the network. Search "TLG Lunchmakers" on Facebook and join the
          community today!{" "}
          <a href="https://www.facebook.com/groups/MLLunchMakers">
            <span
              className="fa fa-facebook-official"
              style={{ color: "#4267B2" }}
            />
          </a>
        </Section>
      </CardColumns>
    </div>
  );
};

export default Welcome;
