import QRCode from "react-qr-code";
import footer from "./assets/img_1.png";
import React from "react";

export function Printout({ url, organisationName }) {
  return (
    <div className="tw-p-8 tw-bg-white tw-text-center tw-text-center tw-leading-normal tw-font-medium tw-text-4xl tw-">
      <img src={footer} className="img-fluid tw-w-full" alt="" />
      <div className="tw-mt-8">
        <div className="tw-mx-32">
          <p className="tw-text-tlg-green tw-font-bold tw-text-6xl tw-mb-8">
            Help us improve!
          </p>
          <p className="tw-mb-8">Complete Our Feedback Survey</p>
          <p className="tw-mb-8">
            We care about your experience! Scan the QR code or visit the link
            below to share your feedback and shape the future of our club.
          </p>
          <p>Thank you for your participation!</p>
          <div className="tw-my-8">
            <div>
              <QRCode size={400} value={url} />
            </div>
            <div className="tw-text-3xl tw-mt-2">
              <a href={url} className="tw-mt-4">
                {url}
              </a>
            </div>
          </div>
        </div>
        <p className="tw-mb-16">
          We're so pleased you came to {organisationName} today. We hope you
          have enjoyed the session and look forward to seeing you again next
          time
        </p>
      </div>
      <img src={footer} className="img-fluid tw-w-full" alt="" />
    </div>
  );
}
