import { Controller, useFormContext } from "react-hook-form";
import { keyBy, map } from "lodash";
import ReactSelect from "react-select";
import classNames from "classnames/dedupe";
import React, { useMemo } from "react";

export const Select = ({
  name,
  rules,
  options,
  labelKey = "label",
  valueKey = "value",
  simpleValue,
  className,
  isMulti,
  ...props
}) => {
  const { watch } = useFormContext();
  const value = watch(name);

  const keyedOptions = useMemo(
    () => (options && simpleValue ? keyBy(options, valueKey) : {}),
    [options, valueKey, simpleValue]
  );
  const selected = useMemo(() => {
    if (!value) return null;
    if (simpleValue) {
      if (isMulti) {
        return map(value, (i) => keyedOptions[i]);
      } else {
        return keyedOptions[value];
      }
    }
    return value;
  }, [simpleValue, keyedOptions, value, isMulti]);

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ onChange, onBlur }) => {
        return (
          <ReactSelect
            name={name}
            options={options}
            onChange={(v) => {
              if (simpleValue) {
                if (isMulti) {
                  onChange(map(v, (i) => i[valueKey]));
                } else {
                  onChange(v ? v[valueKey] : null);
                }
              } else {
                onChange(v);
              }
            }}
            onBlur={onBlur}
            value={selected}
            getOptionLabel={(v) => v[labelKey]}
            getOptionValue={(v) => v[valueKey]}
            className={classNames("react-select", className)}
            classNamePrefix="react-select"
            isMulti={isMulti}
            {...props}
          />
        );
      }}
    />
  );
};
