import {
  FormProvider,
  useForm,
} from "react-hook-form";
import React from "react";
import {
  SubmitButton,
} from "../../../../@coherent/react-hook-form";
import {TextQuestion} from "../../components/TextQuestion";
import {TextareaQuestion} from "../../components/TextareaQuestion";

export const Step1Form = ({ required = true }) => {
  return (
    <>
      <h3 className="tw-text-lg mb-4">Your Details</h3>
      <TextQuestion name="full_name" label="Your Name" required={required} />
      <TextQuestion name="occupation" label="Occupation" required={required} />
      <TextQuestion name="phone" label="Contact Number" required={required} />
      <TextareaQuestion help="Including postcode" name="address" label="Address" required={required} />
    </>
  );
};

export const Step1 = ({ onSubmit, defaultValues }) => {
  const methods = useForm({
    mode: "all",
    defaultValues,
    shouldUnregister: true,
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step1Form />
        <div className="tw-flex tw-justify-end">
          <SubmitButton>Continue</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
