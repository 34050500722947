import { FormProvider, useForm } from "react-hook-form";
import {
  FormGroup,
  Input,
  SubmitButton,
} from "../../../../@coherent/react-hook-form";
import React from "react";

export const EditNote = ({ defaultValues, onSubmit: onSubmitProp }) => {
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  async function onSubmit(x) {
    await onSubmitProp(x);
    reset();
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup name="note" required label="Note">
          <Input
            name="note"
            rules={{ required: true }}
            className="form-control"
            as="textarea"
            rows={3}
            defaultValue=""
          />
        </FormGroup>
        <div className="tw-flex tw-justify-end">
          <SubmitButton>
            {defaultValues.id ? "Update note" : "Add note"}
          </SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
