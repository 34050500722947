import React from "react";

export const Radio = ({ field, value, form, id, ...props }) => {
  return (
    <input
      id={id || field.name}
      type="radio"
      name={field.name}
      checked={field.value === value}
      onChange={(e) => {
        form.setFieldValue(field.name, value);
      }}
      {...props}
    />
  );
};
