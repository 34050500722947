import React from 'react';
import {reduce} from "lodash";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  XAxis, YAxis
} from "recharts";
import {useMemo} from "react";
import numeral from 'numeral';

const defaultColors =`#3366cc
#dc3912
#ff9900
#109618
#990099
#0099c6
#dd4477
#66aa00
#b82e2e
#316395
#994499
#22aa99
#aaaa11
#6633cc
#e67300
#8b0707
#651067
#329262
#5574a6
#3b3eac
#b77322
#16d620
#b91383
#f4359e
#9c5935
#a9c413
#2a778d
#668d1c
#bea413
#0c5922
#743411`.split('\n');

export const ActivitiesGraph = ({
                                 data: dataProp,
                                 colors: colorsProp,
                               }) => {
  const colors = colorsProp || defaultColors;

  const [data, activityNames] = useMemo(() => {
    const { total, activities } = dataProp;
    const activityNames = new Set();

    const data = reduce(
      activities,
      (acc, i, key) => {
        const { activity, is_other, count } = i;

        if (is_other) return acc;


        const percentage = (count / total) || 0;
        const activityName = `${activity}: ${numeral(percentage).format('0[.][0]%')}`;

        activityNames.add(activityName)

        acc[activityName] = percentage * 100;
        return acc;
      },
      {
        name: 'Activity'
      }
    );

    return [[data], [...activityNames]];
  }, [dataProp]);

  return (
    <ResponsiveContainer minHeight={400}>
      <BarChart data={data}>
        <YAxis unit="%" />
        <XAxis dataKey="name" />
        {activityNames.map((i, index) => (
          <Bar key={`bar-${index}`} dataKey={i} fill={colors[index % colors.length]} />
        ))}
        <Legend layout="vertical" />
      </BarChart>
    </ResponsiveContainer>
  );
};