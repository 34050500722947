import React from 'react';
import {useController, useFormContext} from "react-hook-form";

export const Checkbox = ({ name, rules, control, defaultValue, onFocus, onChange, ...props }) => {
  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
    onFocus,
  });
  const { setValue } = useFormContext();
  const { value, ref } = field;

  return (
    <input type="checkbox" name={name} checked={!!value} ref={ref} onChange={e => {
      setValue(e.target.name, e.target.checked, {
        shouldDirty: true,
        shouldValidate: true
      });

      if (onChange) {
        onChange(e);
      }
    }} {...props} />
  )
}