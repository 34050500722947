import axios from "axios";
import { get, merge } from "lodash";
import history from "config/history";
import Auth from "./Auth";

export default ({
  bypassAuthCheck = false,
  redirectOnUnauthorized = true,
  baseURL = "/api",
  headers,
  ...props
} = {}) => {
  const token = Auth.getToken();
  headers = merge(
    {},
    token && !bypassAuthCheck ? { Authorization: `JWT ${token}` } : {},
    headers
  );
  const config = {
    baseURL,
    headers,
    ...props,
  };

  const client = axios.create(config);
  if (!bypassAuthCheck && redirectOnUnauthorized) {
    client.interceptors.response.use(null, (err) => {
      if (get(err, "response.status") === 401) {
        err.handled = true;
        Auth.clearToken();
        history.push("/login");
      }
      throw err;
    });
  }

  client.parseError = (ex) => {
    const code = get(ex, "response.data.code");
    const detail = get(ex, "response.data.detail");
    const status = get(ex, "response.status");
    return { code, detail, status };
  };

  return client;
};
