import React, { useState, useMemo, useLayoutEffect } from "react";
import { Page, FormGroup, PopoverInput, Select } from "components/common";
import { keyBy, sortBy, find, uniqueId, isEmpty } from "lodash";
import { useAsyncEffect, useTooltip, usePendingButton } from "hooks";
import {
  useFormik,
  FormikProvider,
  Field,
  FieldArray,
  useField,
  useFormikContext,
} from "formik";
import moment from "moment";
import { Row, Col, Button } from "reactstrap";
import BaseSelect from "react-select";
import { Link } from "react-router-dom";
import { isInt } from "validator";
import ExportButton from "./ExportButton";
import numeral from "numeral";
import { SupportIcon } from "../../components";
import useUserContext from "../../hooks/useUserContext";

const validateInt = ({ required = true, min = 0 } = {}) => (v) => {
  if (v === null || v === undefined) v = "";
  v = v.toString();
  if (!required && v === "") return undefined;
  return isInt(v, { min }) ? undefined : "invalid";
};

const FastField = ({ name, type, validate, onChange, onBlur, ...props }) => {
  const [field, , helpers] = useField({
    name,
    validate,
    type,
  });
  const { value } = field;
  const { setValue, setTouched } = helpers;
  const [_value, _setValue] = useState(value);
  const { setFieldValue } = useFormikContext();

  useLayoutEffect(() => {
    setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <input
      {...field}
      value={_value}
      onChange={(e) => {
        _setValue(e.target.value);
        if (onChange) {
          onBlur(e);
        }
      }}
      onBlur={(e) => {
        setFieldValue(name, _value, false);
        setTouched(true);
        if (onBlur) {
          onBlur(e);
        }
      }}
      {...props}
    />
  );
};

const AttendanceRow = ({ name, validate, onRemove }) => {
  const [RemoveButton] = usePendingButton();
  const { id: dietaryId, Tooltip: DietaryTooltip } = useTooltip();

  const [{ value }] = useField({
    name,
    validate,
  });
  const { registered, adults, children, num_meals, full_dietary_requirements } = value;
  const {
    accessing_wider_support,
    accessing_wider_support_type_label,
  } = registered;

  const n = (v) => `${name}.${v}`;

  const portions = useMemo(
    () => numeral(adults).add(children).multiply(num_meals).value() || 0,
    [num_meals, adults, children]
  );

  return (
    <tr>
      <td>
        <div className="tw-flex tw-mr-4 tw-mb-2">
          <Link to={`/registered/${registered.id}`}>{registered.name}</Link>
        </div>
      </td>
      <td>
        <SupportIcon
          active={accessing_wider_support}
          label={accessing_wider_support_type_label}
        />
      </td>
      <td>
        {full_dietary_requirements ? (
          <>
            <span
              id={dietaryId}
              className="fa fa-cutlery fa-lg fa-fw text-danger tw-cursor-pointer"
            />
            <DietaryTooltip placement="bottom">
              <div className="tw-whitespace-pre-line tw-text-left">
                {full_dietary_requirements}
              </div>
            </DietaryTooltip>
          </>
        ) : (
          <span className="fa fa-cutlery fa-lg text-muted tw-opacity-25 fa-fw" />
        )}
      </td>
      <td className="text-right">{adults}</td>
      <td className="text-right">{children}</td>
      <td style={{ width: "13rem" }}>
        <FormGroup className="mb-0" name={n("num_meals")}>
          <FastField
            name={n("num_meals")}
            className="form-control text-right form-control-sm"
            validate={validateInt()}
          />
        </FormGroup>
      </td>
      <td className="text-right">{portions}</td>
      <td>
        <RemoveButton
          outline
          color="tlg-orange"
          size="sm"
          onClick={() => onRemove(value)}
        >
          <span className="fa fa-fw fa-trash" />
        </RemoveButton>
      </td>
    </tr>
  );
};

const initialValues = {};

export const View = ({ client, match }) => {
  const [availableRegister, setAvailableRegister] = useState([]);
  const [availableVolunteers, setAvailableVolunteers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVolunteerMenuOpen, setIsVolunteerMenuOpen] = useState(false);
  const [activityOptions, setActivityOptions] = useState();
  const { id } = match.params;

  const [[total_adults, total_children, total_portions], _setStats] = useState([
    0,
    0,
    0,
  ]);

  const formik = useFormik({
    initialValues,
  });
  const {
    values,
    resetForm,
    validateForm,
    setSubmitting,
    isSubmitting,
  } = formik;

  const { isTLG } = useUserContext();

  const { registered, activities, date, volunteers } = values;

  const sortedRegistered = useMemo(() => {
    if (!registered) return [];

    const result = registered.map(({ id, registered, key }, idx) => ({
      idx,
      id,
      key,
      last_name: registered.last_name,
    }));
    return sortBy(result, "last_name");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registered ? registered.length : 0]);

  const keyedVolunteers = useMemo(() => keyBy(volunteers, "id"), [volunteers]);

  const unusedVolunteers = useMemo(
    () => availableVolunteers.filter((i) => !keyedVolunteers[i.id]),
    [availableVolunteers, keyedVolunteers]
  );

  const showActivitiesOther = useMemo(
    () => !!find(activities, { label: "Other" }),
    [activities]
  );

  const keyedRegistered = useMemo(
    () => keyBy(registered || [], (i) => i.registered.id),
    [registered]
  );

  const notRegistered = useMemo(() => {
    return sortBy(
      availableRegister.filter((i) => !keyedRegistered[i.id]),
      "last_name"
    );
  }, [availableRegister, keyedRegistered]);

  useAsyncEffect(async () => {
    if (values === initialValues) return;

    let isValid = false;

    try {
      isValid = isEmpty(await validateForm(values));
    } catch (ex) {
      // do nothing
    }

    if (!isValid) return;

    setSubmitting(true);

    try {
      const { registered: _registered, activities, volunteers } = values;
      const registered = _registered.map((i) => ({
        ...i,
        registered: i.registered.id,
        num_meals: i.num_meals === "" ? null : i.num_meals,
      }));

      const resp = await client
        .put(`parcel_provisions/${id}`, {
          ...values,
          activities: activities.map((i) => i.value),
          volunteers: volunteers.map((i) => i.id),
          registered,
        })
        .get("data");

      setStats(resp);
    } finally {
      setSubmitting(false);
    }
  }, [values]);

  useAsyncEffect(async () => {
    const [values, activityOptions, availableRegister] = await Promise.all([
      client.get(`parcel_provisions/${id}`).get("data"),
      client.get(`sessions/activities`).get("data"),
      client
        .get("registered", {
          params: {
            include_parcel_provision_family_count: true,
          },
        })
        .get("data"),
    ]);
    setAvailableRegister(
      availableRegister.filter((i) => i.parcel_delivery_contact && !i.soft_archived)
    );
    setAvailableVolunteers(availableRegister.filter((i) => i.is_volunteer));
    setActivityOptions(activityOptions.map((i) => ({ label: i, value: i })));
    values.activities = values.activities.map((i) => ({ label: i, value: i }));

    setStats(values);
    resetForm({
      values,
    });
  }, []);

  function setStats(values) {
    const { total_adults, total_children, total_portions } = values;
    _setStats([total_adults, total_children, total_portions]);
  }

  if (values === initialValues) return null;

  return (
    <Page
      title={<>Parcel Provision for Date: {moment(date).format("DD/MM/YY")}</>}
      className="tw-max-w-3xl"
    >
      <FormikProvider value={formik}>
        <h3 className="tw-font-medium tw-opacity-50 tw-text-lg tw-mb-4 tw--mt-3">
          Total Children: {total_children} &nbsp; Total Adults: {total_adults}{" "}
          &nbsp; Total Portions: {total_portions}
        </h3>
        <FormGroup
          label="Average No of Meals Provided Per Parcel"
          name="average_meals"
        >
          <PopoverInput
            popover={
              <>
                If unsure, enter your best estimate. This field will default the
                number field in the view of registrants column below, which you
                can edit individually as needed.
              </>
            }
          >
            <FastField
              name="average_meals"
              className="form-control"
              validate={validateInt()}
            />
          </PopoverInput>
        </FormGroup>
        <FormGroup
          label="Resources/Activities Added to Parcels"
          name="activities"
        >
          <Field
            component={Select}
            options={activityOptions}
            isMulti
            name="activities"
          />
        </FormGroup>
        {showActivitiesOther && (
          <FormGroup>
            <FastField
              name="activities_other"
              placeholder="Other activity description..."
              className="form-control"
            />
          </FormGroup>
        )}

        {isTLG && (
          <FieldArray name="volunteers">
            {({ push, remove }) => (
              <FormGroup label="Volunteers">
                <BaseSelect
                  options={unusedVolunteers}
                  onChange={(i) => {
                    if (i) {
                      push(i);
                      setIsVolunteerMenuOpen(true);
                    }
                  }}
                  menuIsOpen={isVolunteerMenuOpen}
                  onMenuOpen={() => setIsVolunteerMenuOpen(true)}
                  onMenuClose={() => setIsVolunteerMenuOpen(false)}
                  getOptionLabel={(v) => v.name}
                  getOptionValue={(v) => v.id}
                  className="react-select"
                  classNamePrefix="react-select"
                />
                <div className="mt-1">
                  {volunteers.map((i, idx) => (
                    <React.Fragment>
                      {idx ? ", " : ""}
                      <Link to={`/registered/${i.id}`}>{i.name}</Link>
                      <Button
                        onClick={() => remove(idx)}
                        size="sm"
                        color="link"
                        className="text-warning"
                      >
                        <span className="fa fa-trash" />
                      </Button>
                    </React.Fragment>
                  ))}
                </div>
              </FormGroup>
            )}
          </FieldArray>
        )}

        <FieldArray name="registered">
          {({ push, remove }) => (
            <>
              <Row form className="mb-4">
                <Col>
                  <FormGroup label="Add Attendance" className="mb-0">
                    <BaseSelect
                      options={notRegistered}
                      onChange={(i) => {
                        if (i) {
                          const adults = i.parcel_total_adults;
                          const children = i.parcel_total_children;

                          push({
                            num_meals: values.average_meals,
                            registered: i,
                            adults,
                            children,
                            key: uniqueId("key"),
                          });
                          setIsMenuOpen(true);
                        }
                      }}
                      menuIsOpen={isMenuOpen}
                      onMenuOpen={() => setIsMenuOpen(true)}
                      onMenuClose={() => setIsMenuOpen(false)}
                      getOptionLabel={(v) => v.name}
                      getOptionValue={(v) => v.id}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </FormGroup>
                </Col>
                <Col md="auto" className="align-self-end">
                  <Link
                    to={`/registered/new?adult=true&parcel_provision_session=${values.id}`}
                    className="btn btn-primary"
                  >
                    Register New Parcel Delivery Contact
                  </Link>
                </Col>
              </Row>

              <table className="table table-borderless table-sm">
                {registered.length ? (
                  <thead>
                    <tr>
                      <th />
                      <th />
                      <th />
                      <th className="text-right">Adults</th>
                      <th className="text-right">Children</th>
                      <th className="text-right">Family Meals Provided</th>
                      <th className="text-right">Portions</th>
                    </tr>
                  </thead>
                ) : null}
                <tbody>
                  {sortedRegistered.map(({ idx, key, id }) => (
                    <AttendanceRow
                      onRemove={() => remove(idx)}
                      key={key || id}
                      name={`registered.${idx}`}
                    />
                  ))}
                </tbody>
              </table>
            </>
          )}
        </FieldArray>
      </FormikProvider>
      <div className="tw-flex tw-justify-end">
        <ExportButton
          disabled={isSubmitting || registered.length === 0}
          id={id}
        />
      </div>
    </Page>
  );
};

export default View;
