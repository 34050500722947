import React from "react";
import { Page, FormGroup, SubmitButton } from "components/common";
import { Field, Formik, Form } from "formik";
import { useUserContext } from "hooks";
import { Auth } from "services";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { isEmail } from "validator";

export const LoginForm = () => {
  return (
    <Form>
      <FormGroup label="Email" name="email">
        <Field name="email" className="form-control" />
      </FormGroup>
      <FormGroup label="Password" name="password">
        <Field name="password" type="password" className="form-control" />
      </FormGroup>
      <div className="tw-flex tw-justify-between">
        <Link to="/password_reset_request">Reset password</Link>
        <SubmitButton type="submit">Login</SubmitButton>
      </div>
    </Form>
  );
};

export const Login = ({ client, history }) => {
  const { reload, setLoggedInThisSession } = useUserContext();

  async function onSubmit(x, { setSubmitting }) {
    try {
      const { token } = await client.post("auth", x).get("data");
      Auth.setToken(token);
      setLoggedInThisSession(true);
      reload();
      history.push("/");
    } catch (ex) {
      const { status } = client.parseError(ex);

      if (status === 400) {
        NotificationManager.error(
          "Unable to log in with the provided credentials"
        );
      } else {
        throw ex;
      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Page title="Login" className="tw-max-w-md">
      <Formik
        validate={({ email, password }) => {
          const errors = {};
          if (!isEmail(email)) errors.email = true;
          if (!password) errors.password = true;
          return errors;
        }}
        initialValues={{ email: "", password: "" }}
        onSubmit={onSubmit}
        render={(formik) => <LoginForm {...formik} />}
      />
    </Page>
  );
};

export default Login;
