import React from "react";
import { Formik, Field } from "formik";
import { FormGroup, Page, SubmitButton, Select } from "components/common";
import { useFormHelpers, useAsyncEffect } from "hooks";
import { NotificationManager } from "react-notifications";
import { useQuery } from "react-query";
import useClient from "../../hooks/useClient";
import Checkbox from "../../components/common/Checkbox";
import useUserContext from "../../hooks/useUserContext";
import {FormText} from "../../@coherent/react-hook-form";
import {isEmail, isInt} from "validator";
import {get} from "lodash";

export const mealsAndMoreFundingOptions = ['Spring 2022', 'Summer & Autumn 2022', '2023', '2024/2025'].map(i => ({
  label: i,
  value: i
}));

export const Form = ({ title, regionalLeaders, regions, clubHealthStatuses }) => {
  const { isTLG, isVP, isBoxesOfHopeStaff } = useUserContext();

  return (
    <Page title={title}>
      <FormGroup name="organisation_name" label="Name">
        <Field
          name="organisation_name"
          className="form-control"
          validate={(v) => !v}
        />
      </FormGroup>
      <FormGroup name="organisation_id" label="ID">
        <Field
          name="organisation_id"
          className="form-control"
          validate={(v) => !v}
        />
      </FormGroup>
      <FormGroup label="Region" name="region">
        <Field
          name="region"
          component={Select}
          options={regions}
          simpleValue
        />
      </FormGroup>
      <FormGroup name="regional_leader" label="Church Relationship Lead">
        <Field
          component={Select}
          name="regional_leader"
          options={regionalLeaders}
          labelKey="name"
          valueKey="id"
          simpleValue
        />
      </FormGroup>
      <FormGroup name="club_health_status" label="Club health status">
        <Field
          component={Select}
          name="club_health_status"
          options={clubHealthStatuses}
          simpleValue
        />
      </FormGroup>
      <FormGroup name="dbs_contact_name" label="Church DBS contact name">
        <Field
          name="dbs_contact_name"
          className="form-control"
        />
      </FormGroup>
      <FormGroup name="dbs_contact_email" label="Church DBS contact email">
        <Field
          name="dbs_contact_email"
          className="form-control"
          validate={v => !(v === "" || isEmail(v))}
        />
      </FormGroup>
      <FormGroup name="tags" label="Meals & More Funding">
        <Field component={Select} options={mealsAndMoreFundingOptions} isMulti simpleValue name="tags" />
      </FormGroup>
      <FormGroup name="inactive_after_year" label="Inactive After Year">
        <Field name="inactive_after_year" className="form-control" validate={v => !(!v || isInt(`${v}`, { min: 2019}))} />
        <FormText>
          If specified, this clubs demographics will be excluded from the dashboard and club status.
        </FormText>
      </FormGroup>
      {isTLG ? (
        <FormGroup check label="Is Boxes of Hope club?">
          <Field component={Checkbox} name="is_boxes_of_hope" className="form-check-input" />
        </FormGroup>
      ) : null}
      {isVP || isBoxesOfHopeStaff ? (
        <div className="tw-flex tw-justify-end">
          <SubmitButton>Save</SubmitButton>
        </div>
      ) : null}
    </Page>
  );
};

export const Edit = () => {
  const {
    setInitialValues,
    initialValues,
    load,
    save,
    replacePathId,
    pageTitle,
  } = useFormHelpers({ url: "organisations", resource: "Club" });
  const client = useClient();

  const { data: regionalLeaders } = useQuery("regional_leaders", async () =>
    client.get("regional_leaders").get("data")
  );

  const { data: clubHealthStatuses } = useQuery({
    queryKey: "organisations/club_health_statuses",
    queryFn: () => client.get("organisations/club_health_statuses").get("data")
  });

  const { data: regions } = useQuery("organisations/regions", async () =>
    client.get("organisations/regions").get("data")
  );

  useAsyncEffect(async () => {
    const initialValues = await load({
      organisation_name: "",
      organisation_id: "",
      regional_leader: null,
      inactive_after_year: "",
      dbs_contact_name: "",
      dbs_contact_email: "",
      tags: []
    });
    setInitialValues(initialValues);
  }, []);

  async function onSubmit(x, { setSubmitting }) {
    try {
      const resp = await save({
        ...x,
        inactive_after_year: x.inactive_after_year === '' ? null : x.inactive_after_year,
      });
      replacePathId(resp.id);
      NotificationManager.success("Saved successfully");
    } catch (ex) {
      const { organisation_name, organisation_id } = get(ex.response, 'data.detail') || {};

      if (organisation_name) {
        NotificationManager.error('A club with this name already exists')
      }
      if (organisation_id) {
        NotificationManager.error('A club with this ID already exists')
      }
      ex.handled = true;
      throw ex;
    } finally {
      setSubmitting(false);
    }
  }

  if (!initialValues || !regionalLeaders) return null;

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {(formik) => (
          <Form
            regionalLeaders={regionalLeaders}
            clubHealthStatuses={clubHealthStatuses}
            title={pageTitle}
            regions={regions}
            {...formik}
          />
        )}
      </Formik>
    </>
  );
};

export default Edit;
