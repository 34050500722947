import {FormProvider, useForm, useWatch} from "react-hook-form";
import React from "react";
import {
  DatePicker,
  FormGroup,
  SubmitButton,
  Radio,
} from "../../../../@coherent/react-hook-form";
import { TextQuestion } from "../../components/TextQuestion";
import { Button } from "reactstrap";
import { YesNoRadioQuestion } from "../../../../@coherent/react-hook-form/YesNoRadioQuestion";

export const Step3Form = ({ required = true }) => {
  const { dbs_certificate } = useWatch({
    name: ["dbs_certificate"],
  });

  return (
    <>
      <h3 className="tw-text-lg mb-4">DBS/PVG/NI Access Check</h3>
      <p>
        Our safer recruitment policy for Coordinators and Core Team Members requires that you have had a recent DBS/PVG/NI Access (within the last 3 years) OR that you are signed up for one of the DBS/PVG update services. If you do not have either of these, please contact the Safeguarding lead at your Church to help you with this process.
      </p>
      <YesNoRadioQuestion
        name="dbs_certificate"
        label="Do you have a DBS/PVG/NI Access Certificate for Child Workforce?"
        required={required}
      >
        {dbs_certificate === "Yes" && (
          <>
            <TextQuestion
              name="dbs_certificate_number"
              label="Please provide Certificate Number"
              required={required}
            />
            <FormGroup
              name="dbs_certificate_issue_date"
              required
              label="Please provide date your Certificate was issued"
              help="dd/mm/yyyy format"
            >
              <DatePicker
                rules={{
                  required: required,
                }}
                name="dbs_certificate_issue_date"
                className="form-control"
                defaultValue={null}
              />
            </FormGroup>
          </>
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion
        name="dbs_update_service"
        label="Are you subscribed to the DBS/PVG update service?"
        required={required}
      >
        <Radio
          rules={{ required }}
          name="dbs_update_service"
          label="Don't know"
          id={`dbs_update_service_dont_know`}
          value="Don't know"
        />
      </YesNoRadioQuestion>
    </>
  );
};

export const Step3 = ({ onSubmit, defaultValues, onBack }) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
    shouldUnregister: true,
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step3Form />
        <div className="tw-flex tw-justify-between">
          <Button onClick={onBack}>Back</Button>
          <SubmitButton>Continue</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
