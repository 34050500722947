import React from 'react';
import {Col, Row} from "reactstrap";
import {ActivitiesGraph} from "./ActivitiesGraph";
import {ActivitiesOrganisationGraph} from "./ActivitiesOrganisationGraph";

export const ActivityGraphs = ({ activityCounts, activityOrganisationCounts }) => {
  return (
    <div>
      <h5 className="tw-text-left mb-4">
        Activities Insights <span className="tw-text-base">- What types of activities are being provided at Make Lunch alongside the Hot and Healthy Meal?</span>
      </h5>
      <Row form>
        <Col>
          <h6>
            Activities by Session (%)
          </h6>
          <div>
            <ActivitiesGraph data={activityCounts} />
          </div>
        </Col>
        <Col>
          {activityOrganisationCounts ? (
            <>
              <h6>
                Activities by Organisation (%)
              </h6>
              <div>
                <ActivitiesOrganisationGraph data={activityOrganisationCounts} />
              </div>
            </>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};