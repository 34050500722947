import React, { useState } from "react";
import { useTable, useUserContext, useRouter, useOnMount } from "hooks";
import { Page, FormGroup } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import StartSession from "components/StartSession";
import Select from "react-select";

export const List = ({ match, group, client, location }) => {
  const { allowMultipleClubs } = useUserContext();
  const { query } = useRouter();
  const [registered, setRegistered] = useState();
  const [holiday, setHoliday] = useState();
  const [holidayAllocation, setHolidayAllocation] = useState();
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableDataFetch,
    BulkDeleteButton,
    data,
    refreshData,
    setFilters,
    filters,
  } = useTable({
    match,
    resource: "Lunch Club Session",
    url: "sessions",
    filters: {
      group,
      registered: query.registered || undefined,
    },
  });

  useOnMount(async () => {
    if (query.registered) {
      const registered = await client
        .get(`registered/${query.registered}`)
        .get("data");
      setRegistered(registered);
    }
    const holidayAllocation = await client
      .get("holiday_allocation")
      .get("data");
    setHolidayAllocation(holidayAllocation);
  });

  if (!holidayAllocation) return null;

  return (
    <Page
      title={
        registered
          ? `Lunch Club Sessions Attended by ${registered.name}`
          : "Lunch Club Sessions"
      }
    >
      <h3 className="tw-font-medium tw-opacity-50 tw-text-lg tw-mb-4 tw--mt-3">
        Click on a session date below to edit or view attendance.
      </h3>
      {!registered ? (
          <div className="tw-flex tw-mb-4">
            <StartSession groupId={group} onBulkCreated={refreshData} />
            <div className="tw-mx-auto" />
            <BulkDeleteButton />
          </div>
      ) : null}
      <FormGroup label="Holiday">
        <Select
          isClearable
          options={holidayAllocation}
          onChange={(v) => {
            setHoliday(v);
            setFilters({
              ...filters,
              year: v ? v.year : undefined,
              holiday: v ? v.holiday : undefined,
            });
          }}
          value={holiday}
          getValueLabel={(v) => v.id}
          getOptionLabel={(v) => `${v.year} - ${v.holiday}`}
        />
      </FormGroup>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="date">Session Date</th>
              <th sortBy="holiday">Holiday</th>
              {!group && allowMultipleClubs ? (
                <th sortBy="group__name">Group</th>
              ) : null}
              <th>Children Attended</th>
              <th>Adults Attended</th>
              <th>Total Attended</th>
              <th>Activities</th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>
                    {moment(i.date).format("DD/MM/YY")}
                  </Link>
                </td>
                <td>{i.holiday}</td>
                {!group && allowMultipleClubs ? (
                  <td>
                    <Link to={`/groups/${i.group}`}>{i.group__name}</Link>
                  </td>
                ) : null}
                <td>{i.num_children_attending}</td>
                <td>{i.num_adults_attending}</td>
                <td>{i.num_attending}</td>
                <td>{i.activities.join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
