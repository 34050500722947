import React, { useMemo, useState } from "react";
import {
  useModal,
  useClient,
  useRouter,
  useOnMount,
  useFormikContext,
} from "hooks";
import { SubmitButton, FormGroup, Select } from "components/common";
import ReactDayPicker from "react-day-picker";
import { Formik, Field } from "formik";
import { Button } from "reactstrap";
import { without } from "lodash";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { YEAR_OPTIONS, THIS_YEAR } from "config/contants";

const DayPicker = ({ field, disabledDays, form }) => {
  const { name, value } = field;
  const { setFieldValue, setFieldTouched } = form;

  const selectedDays = useMemo(() => value.map((i) => moment(i).toDate()), [
    value,
  ]);

  return (
    <>
      <div className="tw-flex tw-justify-center">
        <ReactDayPicker
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          onDayClick={(day, { selected, disabled }) => {
            if (disabled) return;
            const v = moment(day).format("YYYY-MM-DD");
            setFieldValue(
              name,
              selected ? without(value, v) : [...value, v],
              false
            );
            setFieldTouched(name, true);
          }}
        />
      </div>
    </>
  );
};

const Form = ({ label, url, client }) => {
  const [_disabledDays, setDisabledDays] = useState();
  const { values, setFieldValue } = useFormikContext();
  const { year, holiday } = values;

  useOnMount(async () => {
    const [disabledDays] = await Promise.all([
      client.get(`${url}/dates`).get("data"),
    ]);
    setDisabledDays(disabledDays.map((i) => moment(i).toDate()));
  });

  const holidayAllocation = useMemo(
    () =>
      [
        ["Jan/Feb", moment(`${year}-01-01`)],
        ["Mar/April", moment(`${year}-03-01`)],
        ["May/June", moment(`${year}-05-01`)],
        ["July/Aug", moment(`${year}-07-01`)],
        ["Sept/Oct", moment(`${year}-09-01`)],
        ["Nov/Dec", moment(`${year}-11-01`)],
      ].map((i) => ({
        holiday: i[0],
        start_date: moment(i[1]).format("YYYY-MM-DD"),
        end_date: moment(i[1])
          .add(2, "months")
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
      })),
    [year]
  );

  const disabledDays = useMemo(() => {
    if (!holiday) return _disabledDays;
    return [
      ..._disabledDays,
      {
        before: moment(holiday.start_date).toDate(),
        after: moment(holiday.end_date).toDate(),
      },
    ];
  }, [_disabledDays, holiday]);

  return (
    <>
      <FormGroup label="Year" name="year">
        <Field
          component={Select}
          simpleValue
          options={YEAR_OPTIONS}
          onChange={(v) => {
            setFieldValue("year", v ? v.value : null, false);
            setFieldValue("holiday", null, false);
            setFieldValue("selectedDays", []);
          }}
          name="year"
        />
      </FormGroup>
      <FormGroup label="Holiday" name="holiday">
        <Field
          component={Select}
          labelKey="holiday"
          valueKey="holiday"
          options={holidayAllocation}
          name="holiday"
          onChange={(v) => {
            setFieldValue("holiday", v, false);
            setFieldValue("selectedDays", []);
          }}
        />
      </FormGroup>
      {holiday ? (
        <FormGroup label={label} name="selectedDays">
          <div className="form-control-plaintext">
            <Field
              disabledDays={disabledDays}
              name="selectedDays"
              component={DayPicker}
            />
          </div>
        </FormGroup>
      ) : null}
    </>
  );
};

export const StartParcelProvisionSession = ({
  onBulkCreated,
  clone,
  children,
  ...props
}) => {
  const {
    Modal,
    isOpen,
    setIsOpen,
    ModalBody,
    ModalHeader,
    ModalFooter,
  } = useModal();
  const { history } = useRouter();
  const client = useClient();

  const url = "parcel_provisions";

  async function onSubmit({ selectedDays, holiday }, { setSubmitting }) {
    if (selectedDays.length === 1) {
      try {
        const { id } = await client
          .post(url, {
            date: selectedDays[0],
            holiday: holiday.holiday,
            clone,
            activities: [],
            registered: [],
          })
          .get("data");
        history.push(`/parcel_provision_sessions/${id}`);
        setIsOpen(false);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        let failed = 0;

        await Promise.map(
          selectedDays,
          (date) =>
            client
              .post(url, {
                date,
                clone,
                holiday: holiday.holiday,
                activities: [],
                registered: [],
              })
              .catch(() => {
                failed++;
              }),
          {
            concurrency: 3,
          }
        );

        if (failed) {
          NotificationManager.error(`${failed} sessions failed be be created`);
        } else {
          NotificationManager.success(
            `${selectedDays.length} sessions created successfully`
          );
        }
        if (onBulkCreated) {
          onBulkCreated();
        }
        setIsOpen(false);
      } finally {
        setSubmitting(false);
      }
    }
  }

  return (
    <>
      {isOpen && (
        <Formik
          validate={({ selectedDays, holiday, year }) => {
            const errors = {};
            if (!selectedDays.length) errors.selectedDays = true;
            if (!year) errors.year = true;
            if (!holiday) errors.holiday = true;
            return errors;
          }}
          initialValues={{ selectedDays: [], year: THIS_YEAR, holiday: null }}
          onSubmit={onSubmit}
        >
          {({ values }) => {
            const numDays = values.selectedDays.length;
            const label = numDays
              ? `${numDays} Parcel Provision Day(s) Selected`
              : `Select Parcel Provision Day(s)`;
            return (
              <Modal>
                <ModalHeader>Create New Parcel Provision(s)</ModalHeader>
                <ModalBody>
                  <Form clone={clone} label={label} url={url} client={client} />
                </ModalBody>
                <ModalFooter>
                  <Button color="light" onClick={() => setIsOpen(false)}>
                    Close
                  </Button>
                  <div className="tw-mx-auto" />
                  <SubmitButton>Confirm</SubmitButton>
                </ModalFooter>
              </Modal>
            );
          }}
        </Formik>
      )}
      <Button color="primary" onClick={() => setIsOpen(true)} {...props}>
        <span className="fa fa-plus tw-mr-1" /> {children}
      </Button>
    </>
  );
};

export default StartParcelProvisionSession;
