import { FormProvider, useForm } from "react-hook-form";
import React, { useState } from "react";
import { SubmitButton } from "../../../../@coherent/react-hook-form";

export const ConfirmDeleteButton = ({ onConfirmed: onConfirmedProp }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const [confirmPending, setConfirmPending] = useState(false);

  function onConfirmed() {
    if (!confirmPending) {
      setConfirmPending(true);
      return;
    }
    return onConfirmedProp();
  }

  return (
    <FormProvider {...methods}>
      <SubmitButton
        size="sm"
        color="danger"
        onClick={handleSubmit(onConfirmed)}
      >
        <span className="fa fa-trash" />
        {confirmPending ? <span className="ml-2">Confirm delete?</span> : null}
      </SubmitButton>
    </FormProvider>
  );
};
