import { FormProvider, useForm } from "react-hook-form";
import React, { useState } from "react";
import { useClient } from "../../../hooks";
import { get } from "lodash";
import { Page } from "../../../components/common";
import { Alert } from "reactstrap";
import { SubmitButton } from "../../../@coherent/react-hook-form";
import { ReferralForm } from "./components/ReferralForm";

export const ReferralView = () => {
  const methods = useForm({
    mode: "onBlur",
  });
  const [success, setSuccess] = useState(false);
  const { handleSubmit, formState, setError } = methods;
  const { isValid, submitCount } = formState;
  const client = useClient();

  async function onSubmit(x) {
    try {
      await client.post("referrals", x).get("data");
      setSuccess(true);
    } catch (ex) {
      const { coordinator_email } = get(ex, "response.data.detail") || {};

      if (coordinator_email) {
        setError("coordinator_email", {
          type: "server",
          message: coordinator_email,
        });
      } else {
        throw ex;
      }
    }
  }

  const container = (children) => (
    <Page title="Refer a Make Lunch Coordinator or a Core Team Member">
      {children}
    </Page>
  );

  if (success) {
    return container(
      <>
        <p>Your referral has been submitted.</p>
        <p>
          Thank you so much for your referral. We have sent an email to the
          Coordinator to let them know that a new volunteer has been referred to submit an application.
          The applicant will receive an email shortly with a link to complete the application form.
        </p>
        <p>
          If you have any questions, please, do not hesitate to email{" "}
          <a href="mailto:makelunch@tlg.org.uk">makelunch@tlg.org.uk</a>.
        </p>
        <p>
          Thank you,
          <br />
          The Make lunch team
        </p>
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      {container(
        <>
          <p>
            Thank you for choosing to partner with Make Lunch, for Safeguarding reasons we
            require all Coordinators and Core Team Members to be referred for the role by an appropriate person, as follows:
          </p>
          <ul>
            <li>
              A Make Lunch Coordinator must be referred by a Church Leader at the partner
              church.
            </li>
            <li>
              Make Lunch Core team members can be referred either by the Church Leader or by
              the Make Lunch Coordinator.
            </li>
          </ul>
          <p>
            All referrers should have known the applicant for at least 6 months.
          </p>
          <p>
            Please provide the following details for the applicant you would like to refer. Once
            these details are submitted the applicant will be emailed an online Make Lunch
            application form and the Coordinator will be informed that a referral has been made.
          </p>
          <hr />
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <ReferralForm />
            {submitCount && !isValid ? (
              <Alert color="danger">Please complete all fields.</Alert>
            ) : null}
            <div className="tw-flex tw-justify-end">
              <SubmitButton>Submit referral</SubmitButton>
            </div>
          </form>
        </>
      )}
    </FormProvider>
  );
};
