import React from "react";
import { Page } from "../../../components/common";
import {Step1, Step2, Step3} from "./components";
import { useClient } from "../../../hooks";
import { get } from "lodash";
import { Alert } from "reactstrap";
import { useReferenceFormQuery } from "./hooks";
import {handleNonFieldErrors} from "../util";
import {useSteps} from "../hooks";

const steps = [Step1, Step2, Step3];

export const ReferenceFormView = ({ match }) => {
  const { id } = match.params;
  const url = `reference_forms/${id}`;
  const client = useClient();
  const { nextStep, previousStep, step } = useSteps(0, steps);
  const { error, data, refetch } = useReferenceFormQuery(url);

  const container = (children) => (
    <Page title="Make Lunch Reference Form">
      {children}
    </Page>
  );

  if (error && get(error, "response.status") === 404) {
    return container(
      <Alert color="warning">This reference form link is not valid.</Alert>
    );
  }

  if (!data) return null;

  async function onSubmit(x) {
    try {
      await client.patch(url, x);
    } catch (ex) {
      handleNonFieldErrors(ex);
    }
    await refetch();
    nextStep();
  }

  function onBack() {
    previousStep();
  }

  if (data.submitted) {
    return container(
      <>
        <h3 className="tw-font-medium tw-text-xl tw-mb-4">Thanks!</h3>
        <p>
          Thank you for taking the time to complete this reference form!
        </p>
        <p>
          The Make Lunch Team
        </p>
      </>
    );
  }

  return container(
    <>
      <p>This voluntary role within Make Lunch with TLG involves direct contact with vulnerable young people and children and it is necessary that you provide true and accurate information about the applicant.</p>
      <p>Any further information about this role can be found at <a href="https://www.tlg.org.uk/your-church/make-lunch">https://www.tlg.org.uk/your-church/make-lunch</a>.</p>
      <p>As a referee you are reminded that you have a responsibility to ensure that the reference is accurate and does not contain any material misstatement or omission. Roles working with children and young people are exempt from Section 4(2) of the Rehabilitation of Offenders Acts 1974 and all criminal information must be declared.</p>
      <p>By submitting this reference you are declaring that you are happy that this information may be shared with the applicant.</p>
      <hr />
      {steps.map((Component, index) => {
        if (index !== step) return null;
        return (
          <Component
            defaultValues={data}
            onSubmit={onSubmit}
            onBack={onBack}
            key={index}
          />
        );
      })}
    </>
  );
};
