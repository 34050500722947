import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import React from "react";
import {
  DatePicker,
  FormGroup,
  SubmitButton,
  Radio, Select, Checkbox,
} from "../../../../@coherent/react-hook-form";
import { TextQuestion } from "../../components/TextQuestion";
import { TextareaQuestion } from "../../components/TextareaQuestion";
import moment from "moment";
import { get } from "lodash";
import {useQuery} from "react-query";
import {useClient} from "../../../../hooks";
import { isEmail } from 'validator';

export const Step1Form = ({ required = true }) => {
  const { errors, control, setValue, trigger } = useFormContext();
  const { organisation_doesnt_exist } = useWatch({
    name: ["organisation_doesnt_exist"],
    control,
  });
  const client = useClient();

  const organisationsQueryKey = ['application_forms/organisations']
  const { data: organisations } = useQuery(organisationsQueryKey, () => (
    client.get(...organisationsQueryKey).get('data')
  ), { useErrorBoundary: false })

  return (
    <>
      <h3 className="tw-text-lg mb-4">Your Details</h3>
      <FormGroup className="mb-0" required={required} name="organisation" label="Which church's Make Lunch team are you applying to join?">
        <Select simpleValue isDisabled={!!organisation_doesnt_exist} name="organisation" options={organisations} rules={{
          required: organisation_doesnt_exist ? false : required
        }} />
      </FormGroup>
      <FormGroup check label="I can't find the Make Lunch team in this list" htmlFor="organisation_doesnt_exist">
        <Checkbox onChange={() => {
          setValue('organisation', null);
          setTimeout(() => {
            trigger('organisation');
          }, 100)
        }} name="organisation_doesnt_exist" className="form-check-input" id="organisation_doesnt_exist" />
      </FormGroup>
      {organisation_doesnt_exist ? (
        <TextQuestion required={required} label="Please enter the Make Lunch team name" name="organisation_name" />
      ): null}
      <TextQuestion name="first_name" label="First Name" required={required} />
      <TextQuestion name="last_name" label="Last Name" required={required} />
      <TextQuestion name="email" label="Email Address" required={required} validate={v => isEmail(v)} />
      <FormGroup
        name="role"
        required={required}
        label="What role are you applying for?"
      >
        <Radio
          name="role"
          label="Coordinator"
          id={`role_coordinator`}
          value="Coordinator"
        />
        <Radio
          rules={{ required: true }}
          name="role"
          label="Core Team Member"
          id={`role_core_team_member`}
          value="Core Team Member"
        />
      </FormGroup>
      <TextQuestion name="aliases" label={`Any former/"known as" names?"`} />
      <FormGroup
        name="date_of_birth"
        label="Date of birth"
        required={required}
        help={
          <>
            dd/mm/yyyy format. We welcome applicants from 18 years and over.
            {get(errors, "date_of_birth.type") === "ofAge" && (
              <>
                <br />
                The selected date of birth is under 18 years old.
              </>
            )}
          </>
        }
      >
        <DatePicker
          rules={{
            required: required,
            validate: {
              ofAge: (v) => {
                if (!required) return true;
                if (!v) return false;
                const d = moment.duration(moment().startOf("day") - moment(v));
                return d.years() >= 18;
              },
            },
          }}
          name="date_of_birth"
          className="form-control"
          defaultValue={null}
        />
      </FormGroup>
      <TextareaQuestion
        name="address"
        label="Address"
        help="Including postcode"
        required={required}
      />
      <TextQuestion name="mobile" required={required} label="Mobile" />
      <TextQuestion name="phone" label="Telephone" />
      <TextareaQuestion
        help="Minimum of 50 words"
        minWords={50}
        required={required}
        name="reason"
        label={<>Why would you like to join your church's Make Lunch team?</>}
      />
      <TextareaQuestion
        help="Minimum of 50 words"
        minWords={50}
        required={required}
        name="past_experience"
        label={
          <>
            What current and past experiences do you have of working with
            children and young people, whether paid or as a volunteer?
          </>
        }
      />
      <TextareaQuestion
        help="Minimum of 50 words"
        minWords={50}
        required={required}
        name="faith"
        label={
          <>
            Our role description talks about Christian faith and life style;
            we’d love to hear about what your Christian faith means to you.
          </>
        }
      />
      <TextareaQuestion
        name="consistency_details"
        label={
          <>
            Commitment to the Make Lunch project and consistency in the team is really important in
            this role. At least one Core Team Member will need to be present at every session. If there is anything
            which may inhibit your capacity to volunteer, please can you share details below.
          </>
        }
      />
    </>
  );
};

export const Step1 = ({ onSubmit, defaultValues }) => {
  const methods = useForm({
    mode: "all",
    defaultValues,
    shouldUnregister: true,
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step1Form />
        <div className="tw-flex tw-justify-end">
          <SubmitButton>Continue</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
