import React, {useMemo} from 'react';
import {Button, ModalBody, ModalHeader} from "reactstrap";
import {useModal} from "../../hooks";
import {chain} from "lodash";

const ActivitiesOtherDataBody = ({ data: dataProp }) => {
  const data = useMemo(() => (
    chain(dataProp)
      .filter(i => i.is_other)
      .groupBy('activity')
      .map((v, k) => ({
        activity: k,
        count: v.length
      }))
      .sortBy(i => i.activity.toLowerCase())
      .value()
  ), [dataProp])
  return (
    <table className="table table-sm table-borderless" style={{ width: 'auto', maxWidth: '100%' }}>
      <thead>
        <tr>
          <th>Activity</th>
          <th className="text-right">Count</th>
        </tr>
      </thead>
      <tbody>
      {data.map(i => (
        <tr key={i.activity}>
          <td className="tw-font-medium">
            {i.activity}
          </td>
          <td className="text-right">
            {i.count}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  )

}

export const ActivitiesOtherData = ({ data }) => {
  const { Modal, isOpen, toggle } = useModal();

  return (
    <>
      {isOpen && (
        <Modal size="lg">
          <ModalHeader toggle={toggle}>
            Other activity data
          </ModalHeader>
          <ModalBody>
            <ActivitiesOtherDataBody data={data} />
          </ModalBody>
        </Modal>
      )}
      <Button color="link" size="sm" onClick={toggle}>
        View other activity data
      </Button>
    </>
  )
}