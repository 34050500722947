import {formatDateTime} from "../../util";
import React from "react";

export const LatestNote = ({value}) => {
  const {user_email, created, note} = value;

  return (
    <p className="small text-muted">
      By <strong>{user_email}</strong> on {formatDateTime(created)}
      <br/>
      {note}
    </p>
  );
};