import { FormGroup, Input } from "../../../@coherent/react-hook-form";
import React from "react";
import { identity } from "lodash";

export const TextQuestion = ({
  name,
  label,
  required,
  help,
  helpPosition,
  defaultValue = "",
  getInputProps = identity,
  children,
  validate,
  type='text',
  readOnly,
  optional,
  autoFocus,
  labelClassName,
}) => {
  const inputProps = getInputProps({
    name,
    type,
    readOnly,
    className: "form-control",
    defaultValue,
    rules: {
      required,
      validate,
    },
    autoFocus
  });

  return (
    <FormGroup labelClassName={labelClassName} optional={optional} name={name} label={label} required={required} help={help} helpPosition={helpPosition}>
      <Input {...inputProps} />
      {children}
    </FormGroup>
  );
};
