import { useFormContext } from "react-hook-form";
import React from "react";
import { PendingButton } from "components/common";

export const SubmitButton = ({ pending, children, ...props }) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <PendingButton
      type="submit"
      pending={!!(pending || isSubmitting)}
      color="tlg-teal"
      className="tw-text-white"
      {...props}
    >
      {children}
    </PendingButton>
  );
};
