import React, { useState } from "react";
import {
  Page,
  FormGroup,
  SubmitButton,
  Checkbox,
  Select,
} from "components/common";
import { Formik, Field, ErrorMessage } from "formik";
import {Button, Alert, FormText} from "reactstrap";
import { NotificationManager } from "react-notifications";
import invariant from "invariant";
import { isEmail } from "validator";
import {useFormHelpers, useAsyncEffect, useClient, useUserContext} from "hooks";
import CreatableSelect from 'react-select/creatable';

import { STAFF, PASTORS } from "./constants";
import {useQuery} from "react-query";
import {useInactiveReasons} from "./api";

const Warning = ({ children, ...props }) =>
  children === true ? null : (
    <Alert className="tw-mt-2 tw-py-1 tw-px-2" color="warning" {...props}>
      {children}
    </Alert>
  );

const EditForm = ({
  values,
  setFieldValue,
  setSettingPassword,
  settingPassword,
  isPastor,
  organisations,
  userTypes,
}) => {
  const client = useClient();
  const { isVP, isBoxesOfHopeStaff } = useUserContext();

  function onCancelPassword() {
    setSettingPassword(false);
    setFieldValue("password", undefined);
  }

  function onChangePassword() {
    setSettingPassword(true);
    setFieldValue("password", "");
  }
  const { is_active } = values;

  const { data: roles } = useQuery(['users/staff/roles'], url => client.get(url).get('data'), {
    enabled: !isPastor
  })

  const { data: inactiveReasons } = useInactiveReasons();

  return (
    <div>
      <FormGroup label="Email *" name="email">
        <Field name="email" className="form-control" />
        <ErrorMessage component={Warning} name="email" />
      </FormGroup>
      <FormGroup label="First Name *" name="first_name">
        <Field name="first_name" className="form-control" />
      </FormGroup>
      <FormGroup label="Last Name *" name="last_name">
        <Field name="last_name" className="form-control" />
      </FormGroup>
      {values.id ? (
        settingPassword ? (
          <FormGroup label="Password *" name="password">
            <Field name="password" className="form-control" />
            <ErrorMessage component={Warning} name="password" />
            <Button
              onClick={onCancelPassword}
              color="link"
              size="sm"
              className="tw-p-0"
            >
              Cancel
            </Button>
          </FormGroup>
        ) : (
          <FormGroup label="Password" name="password">
            <div className="form-control-plaintext">
              <Button
                onClick={onChangePassword}
                color="link"
                size="sm"
                className="tw-p-0"
              >
                Change password
              </Button>
            </div>
          </FormGroup>
        )
      ) : (
        <FormGroup label="Password *" name="password">
          <Field name="password" className="form-control" />
          <ErrorMessage component={Warning} name="password" />
        </FormGroup>
      )}
      {isPastor ? (
        <>
          <FormGroup label="Club" name="organisation">
            <Field
              name="organisation"
              component={Select}
              isDisabled={!!values.id}
              options={organisations}
              validate={(v) => !v}
              simpleValue
            />
          </FormGroup>
          <FormGroup label="User type" name="user_type">
            <Field
              component={Select}
              name="user_type"
              simpleValue
              options={userTypes}
              validate={(v) => !v}
            />
          </FormGroup>
          {isCoordinator(values.user_type) ? (
            <FormGroup label="Mobile number" name="mobile_number">
              <Field
                name="mobile_number"
                validate={(v) => {
                  return /\d{10,}/.test();
                }}
                className="form-control"
              />
              <p className="form-text tw-text-sm text-muted">
                Only digits are allowed and must contain the country code, e.g.
                44774696....
              </p>
              <ErrorMessage component={Warning} name="mobile_number" />
            </FormGroup>
          ) : null}
          <FormGroup check label="Allow Multiple Groups?">
            <Field
              component={Checkbox}
              name="allow_multiple_groups"
              className="form-check-input"
            />
          </FormGroup>
          <FormGroup check label="Enable 2-step Attendance?">
            <Field
              component={Checkbox}
              name="two_step_attendance"
              className="form-check-input"
            />
          </FormGroup>
        </>
      ) : (
        <>
          <FormGroup label="Permission role" name="role">
            <Field
              name="role"
              component={Select}
              options={roles}
              validate={(v) => !v}
              simpleValue
            />
          </FormGroup>
        </>

      )}
      <FormGroup check label="Is Active?">
        <Field
          component={Checkbox}
          name="is_active"
          className="form-check-input"
        />
      </FormGroup>
      {!is_active && isPastor ? (
        <FormGroup label="Reason" name="inactive_reason">
          <Field name="inactive_reason" render={({ field }) => {
            const { value, name } = field;

            return <CreatableSelect options={inactiveReasons}
                                    isClearable
                                    onChange={(v) => {
                                      setFieldValue(name, v ? v.value : null)
                                    }}
                                    value={value ? {
                                      value: value,
                                      label: value
                                    } : null} />
          }} />
          <FormText>
            Select an option from the dropdown or type to enter a custom reason.
          </FormText>
        </FormGroup>
      ) : null}
      {isVP || isBoxesOfHopeStaff ? (
        <div className="tw-flex tw-justify-end">
          <SubmitButton>Save</SubmitButton>
        </div>
      ) : null}
    </div>
  );
};

const isCoordinator = (v) => v === "coordinator";

export const Edit = ({ match, client, type, history }) => {
  invariant(
    type === PASTORS || type === STAFF,
    "type must be pastors or staff"
  );
  const isPastor = type === PASTORS;

  const url = `users/${type}`;
  const {
    load,
    save,
    parseError,
    pageTitle,
    replacePathId,
    initialValues,
    setInitialValues,
  } = useFormHelpers({
    url,
    resource: isPastor ? "User" : "Staff",
  });
  const [settingPassword, setSettingPassword] = useState(false);
  const [userTypes, setUserTypes] = useState();
  const [organisations, setOrganisations] = useState();

  async function onSubmit(x, { setSubmitting, setFieldError }) {
    try {
      const resp = await save({
        ...x,
        mobile_number: x.mobile_number ? x.mobile_number : null,
      });
      replacePathId(resp.id);
      NotificationManager.success("Saved successfully");
    } catch (ex) {
      const { code, detail } = parseError(ex);

      if (code === "invalid") {
        setFieldError("password", detail.password);
        setFieldError(
          "mobile_number",
          detail.mobile_number
            ? "A user with this mobile number already exists"
            : false
        );
        setFieldError(
          "email",
          detail.email ? "A user with this email already exists" : false
        );
      } else {
        throw ex;
      }
    } finally {
      setSubmitting(false);
    }
  }

  useAsyncEffect(async () => {
    const [item, userTypes, organisations] = await Promise.all([
      load({
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        mobile_number: "",
        is_active: true,
        two_step_attendance: false,
        organisation: null,
        user_type: null,
        allow_multiple_groups: false,
        inactive_reason: null
      }),
      client.get("users/pastors/user_types").get("data"),
      client
        .get("organisations")
        .get("data")
        .map((i) => ({
          label: i.organisation_name,
          value: i.id,
        })),
    ]);
    setUserTypes(userTypes);
    setOrganisations(organisations);
    setInitialValues(item);
  }, []);

  if (!initialValues) return null;

  return (
    <Page title={pageTitle}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={({ id, first_name, last_name, email, password }) => {
          const errors = {};
          if (!first_name) errors.first_name = true;
          if (!last_name) errors.last_name = true;
          if (!isEmail(email)) errors.email = true;
          if ((!id || settingPassword) && !password) errors.password = true;
          return errors;
        }}
      >
        {(formik) => (
          <EditForm
            {...formik}
            organisations={organisations}
            isPastor={isPastor}
            userTypes={userTypes}
            settingPassword={settingPassword}
            setSettingPassword={setSettingPassword}
          />
        )}
      </Formik>
    </Page>
  );
};

export default Edit;
