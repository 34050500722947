import React from "react";
import { Page } from "../../components/common";
import { useClient, useTable, useUserContext } from "../../hooks";
import { SurveyLink } from "./components";
import { Table } from "reactstrap";
import { formatDateTime } from "../applications/util";
import { Link } from "react-router-dom";

export function ParticipantFeedback({ match }) {
  const { user } = useUserContext();
  const client = useClient();

  const {
    TableSearch,
    TableContainer,
    data,
    ViewingRecords,
    Pagination,
    TableDataFetch,
  } = useTable({
    url: "participant_feedback",
    match,
    client,
  });

  return (
    <Page title="Participant Feedback">
      <p>Welcome to the Make Lunch Participant Feedback Tool!</p>
      <p>
        Click the <strong>Get Survey Link</strong> button to access your Clubs
        unique feedback portal. You will be provided with both a QR code and a
        weblink to a short survey which your Make Lunch families can quickly and
        easily complete on their phones during a Make Lunch session. Simply
        click print to create a poster which you can display at your club
        whenever you would like to invite feedback.
      </p>
      <p>
        Once families have submitted their forms you will be able to access
        local results of the feedback survey on your dashboard, along with all
        the other useful data that Kitchen cupboard is generating to help you
        manage and evaluate your local Make Lunch provision.
      </p>
      <p>
        Why not organise a child only game at the end of your last summer Make
        Lunch session and invite all the parents to take 5 minutes to share
        their feedback. Or perhaps you prefer to have the feedback poster
        displayed all year and invite families one at a time to access it when
        it feels appropriate for them. Its up to you how you do it but taking
        time to listen to families and hear their feedback shows families how
        much you value them and helps both you and us shape Make Lunch for the
        future!
      </p>
      <hr />
      <TableSearch className="fa-min" placeholder="Search by name" />
      {user.organisation__hashid ? (
        <SurveyLink
          className="mb-4"
          hashid={user.organisation__hashid}
          organisationName={user.organisation__organisation_name}
        />
      ) : null}
      <TableContainer>
        <Table size="sm">
          <thead>
            <tr>
              <th sortBy="created">Submitted</th>
              <th sortBy="name">Name</th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td>
                  <Link to={`${match.url}/${i.id}`}>
                    {formatDateTime(i.created)}
                  </Link>
                </td>
                <td>{i.name || "Anonymous"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
}
