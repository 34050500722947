import React from "react";
import classNames from "classnames";

export const ViewingRecords = ({
  limit,
  offset,
  count,
  className,
  noCount,
  ...props
}) => (
  <div className={classNames("text-muted small", className)} {...props}>
    Viewing {count > 0 ? offset + 1 : 0} -{" "}
    {Math.min(offset + limit, noCount ? offset + limit : count)}
    {!noCount && <span> of {count} records</span>}
  </div>
);

export default ViewingRecords;
