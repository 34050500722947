import React, { useRef, useEffect } from "react";
import { Editor as BaseEditor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

export const Editor = ({ form, field, ...props }) => {
  const { value, name } = field;
  const { setFieldValue, setFieldTouched } = form;
  const mounted = useRef();
  const initialEditorState = mounted.current
    ? null
    : value === null
    ? EditorState.createEmpty()
    : EditorState.createWithContent(convertFromRaw(value));

  useEffect(() => {
    mounted.current = true;
  }, []);

  return (
    <BaseEditor
      name={name}
      defaultEditorState={initialEditorState}
      onBlur={(e, editorState) => {
        const content = editorState.getCurrentContent();
        setFieldValue(name, convertToRaw(content), false);
        setFieldTouched(name);
      }}
      {...props}
    />
  );
};

export default Editor;
