import { useFormContext, useWatch } from "react-hook-form";
import {
  Checkbox,
  FormGroup,
  Input,
} from "../../../../@coherent/react-hook-form";
import { Radio } from "../../../../@coherent/react-hook-form/Radio";
import { isEmail } from "validator";
import { get } from "lodash";
import React from "react";
import {JobDescriptionLink} from "../../components/JobDescriptionLink";

export const ReferralForm = ({ disabled = false }) => {
  const role = useWatch({
    name: "role",
  });
  const { formState } = useFormContext();
  const { errors } = formState;

  return (
    <fieldset disabled={disabled}>
      <FormGroup required name="referrer_role" label="Your Role">
        <Radio
          label="Church Leader"
          name="referrer_role"
          id="referrer_role_church_leader"
          value="Church Leader"
        />
        <Radio
          label="Coordinator"
          name="referrer_role"
          id="referrer_role_coordinator"
          value="Coordinator"
          rules={{
            required: true,
          }}
        />
      </FormGroup>
      <FormGroup required name="referrer_name" label="Your Name">
        <Input
          defaultValue=""
          name="referrer_name"
          className="form-control"
          rules={{
            required: true,
          }}
        />
      </FormGroup>
      <FormGroup required name="first_name" label="First Name of the Applicant">
        <Input
          defaultValue=""
          name="first_name"
          className="form-control"
          rules={{
            required: true,
          }}
        />
      </FormGroup>
      <FormGroup required name="last_name" label="Last Name of the Applicant">
        <Input
          defaultValue=""
          name="last_name"
          className="form-control"
          rules={{
            required: true,
          }}
        />
      </FormGroup>
      <FormGroup required name="email" label="Email Address of the Applicant">
        <Input
          defaultValue=""
          type="email"
          name="email"
          className="form-control"
          rules={{
            required: true,
            validate: isEmail,
          }}
        />
      </FormGroup>
      <FormGroup required name="role" label="Role of the Applicant">
        <Input
          defaultValue=""
          as="select"
          name="role"
          className="form-control"
          rules={{
            required: true,
          }}
        >
          <option />
          <option value="Coordinator">Coordinator</option>
          <option value="Core Team Member">Core Team Member</option>
        </Input>
      </FormGroup>
      {role === "Core Team Member" ? (
        <>
          <FormGroup
            required
            name="coordinator_name"
            label="Name of Coordinator"
          >
            <Input
              defaultValue=""
              name="coordinator_name"
              className="form-control"
              rules={{
                required: true,
              }}
            />
          </FormGroup>
          <FormGroup
            help={
              get(errors, "coordinator_email.type") === "server"
                ? errors.coordinator_email.message
                : null
            }
            required
            name="coordinator_email"
            label="Email address of Coordinator"
          >
            <Input
              defaultValue=""
              type="email"
              name="coordinator_email"
              className="form-control"
              rules={{
                required: true,
                validate: isEmail,
              }}
            />
          </FormGroup>
        </>
      ) : null}
      <FormGroup
        htmlFor="confirm_referral"
        name="confirm_referral"
        check
        required
        label={
          <>
            I can confirm that I have talked to the applicant about this role
            and they are happy for me to pass on their name and email address in
            order to receive an application.
          </>
        }
      >
        <Checkbox
          defaultValue={false}
          rules={{
            validate: (v) => !!v,
          }}
          name="confirm_referral"
          className="form-check-input"
          id="confirm_referral"
        />
      </FormGroup>
      {role ? (
        <FormGroup
          htmlFor="confirm_documents"
          check
          required
          name="confirm_documents"
          label={
            <>
              I can confirm that I have read the relevant {role} documents (<JobDescriptionLink jobRole={role}>click here</JobDescriptionLink>)
            </>
          }
        >
          <Checkbox
            defaultValue={false}
            rules={{
              validate: (v) => !!v,
            }}
            name="confirm_documents"
            className="form-check-input"
            id="confirm_documents"
          />
        </FormGroup>
      ) : null}
      <FormGroup
        htmlFor="confirm_confidence"
        check
        required
        name="confirm_confidence"
        label={
          <>
            I can confirm that I have known this person for at least 6 months
            and I am confident in recommending this person to be a Make Lunch
            Core Team Member/Coordinator and feel they meet the requirements of
            the role.
          </>
        }
      >
        <Checkbox
          defaultValue={false}
          rules={{
            validate: (v) => !!v,
          }}
          name="confirm_confidence"
          className="form-check-input"
          id="confirm_confidence"
        />
      </FormGroup>
    </fieldset>
  );
};
