import React from "react";
import { TextQuestion } from "../../components/TextQuestion";
import { TextareaQuestion } from "../../components/TextareaQuestion";
import {isEmail} from "validator";

export const Reference = ({ field, index, required }) => {
  const name = `references.${index}`;
  const n = (v) => `${name}.${v}`;
  const label = (v) => `Referee ${index + 1}: ${v}`;

  let positionLabel = 'Position';
  let relationshipLabel = 'Capacity in which you know the referee'

  if (index === 0) {
    positionLabel += ' (this should be your Church Leader or someone on the Leadership Team)';
    relationshipLabel += ' (e.g. Church Leader, Pastor etc.)'
  } else if (index === 1) {
    relationshipLabel += ' (e.g. in what context have you worked with or volunteered with this person?)'
  }

  return (
    <>
      {index ? <hr style={{ borderTopWidth: '2px'}} className="tw-border-gray-500 tw-my-8" /> : null}
      {index === 2 ? (
        <>
          <p className="small">
            If you have been at your current church for less than 2 years then please also provide details of your previous church
            leader in the 3rd reference space below. A Youth/children’s leader or small group leader who has known you for at
            least 2 years will be acceptable.
          </p>
        </>
      ) : null}
      <TextQuestion
        name={n("full_name")}
        required={required}
        label={label("Full Name")}
        defaultValue={field.full_name}
      />
      <TextQuestion
        type="email"
        name={n("email")}
        required={required}
        label={label("Email address")}
        defaultValue={field.email}
        validate={v => {
          if (!required && v === '') return true;
          return isEmail(v);
        }}
      />
      <TextQuestion
        name={n("organisation")}
        required={required}
        label={label("Organisation")}
        defaultValue={field.organisation}
      />
      <TextQuestion
        name={n("position")}
        required={required}
        label={label(positionLabel)}
        defaultValue={field.position}
      />
      <TextQuestion
        name={n("relationship")}
        required={required}
        label={label(relationshipLabel)}
        defaultValue={field.relationship}
      />
      <TextQuestion
        name={n("years_known")}
        required={required}
        label={label("How many years have they known you?")}
        defaultValue={field.years_known}
      />
      <TextQuestion
        name={n("phone")}
        required={required}
        label={label("Telephone / Phone number")}
        defaultValue={field.phone}
      />
      <TextareaQuestion
        name={n("address")}
        required={required}
        label={label("Address")}
        help="Including post code"
        defaultValue={field.address}
      />
    </>
  );
};
