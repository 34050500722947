import React, { useState, useCallback, useRef } from "react";
import { PendingButton } from "components/common";

export const usePendingButton = () => {
  const [pending, setPending] = useState();
  const ctx = useRef();
  ctx.current = { pending, setPending };

  const renderPendingButton = useCallback(
    ({ onClick: onClickProp, ...props }) => {
      const { pending, setPending } = ctx.current;

      const onClick = async (...args) => {
        setPending(true);
        try {
          await onClickProp(...args);
        } finally {
          setPending(false);
        }
      };

      return (
        <PendingButton
          pending={pending}
          onClick={onClickProp ? onClick : onClickProp}
          {...props}
        />
      );
    },
    []
  );

  return [renderPendingButton, setPending, pending];
};

export default usePendingButton;
