import {useClient, useModal} from "../../../hooks";
import {FormProvider, useForm} from "react-hook-form";
import React, {useMemo} from "react";
import {keys} from "lodash";
import {NotificationManager} from "react-notifications";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {SubmitButton} from "../../../@coherent/react-hook-form";

export const BulkArchiveButton = ({archived, baseUrl, content= "", attr = 'archived', selected, onSubmitted, ...props}) => {
  const {isOpen, setIsOpen, Modal, toggle} = useModal();
  const methods = useForm();
  const {handleSubmit, control} = methods;
  const client = useClient();

  const selectedIds = useMemo(() => keys(selected), [selected]);
  const numSelected = selectedIds.length;

  const label = archived ? 'Unarchive' : 'Archive';

  async function onSubmit() {
    const errors = [];

    for (const i of selectedIds) {
      try {
        await client.patch(`${baseUrl}/${i}`, {
          [attr]: !archived
        })
      } catch (ex) {
        console.error(ex);
        errors.push([i, ex]);
      }
    }

    if (errors.length) {
      NotificationManager.error(`${errors.length} rows could not be updated`)
    } else {
      NotificationManager.success('Updated successfully');
    }
    setIsOpen(false);
    onSubmitted(errors);
  }

  return (
    <>
      {isOpen ? (
        <FormProvider {...methods}>
          <Modal>
            <ModalHeader toggle={toggle}>
              {label} selected?
            </ModalHeader>
            <ModalBody>
              <p>
                Are you sure you want to {label} the <strong>{numSelected}</strong> selected rows?
              </p>
              {!archived && content ? (
                <p>{content}</p>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <SubmitButton onClick={handleSubmit(onSubmit)} control={control}>
                Confirm
              </SubmitButton>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </FormProvider>
      ) : null}
      <Button title={content} disabled={!numSelected} onClick={toggle} {...props}>
        {label} selected
        {numSelected ? ` (${numSelected})` : ''}
      </Button>
    </>
  )
}