import React from "react";
import classNames from "classnames";

export const CollapseHeader = ({ setIsOpen, isOpen, title, className }) => {
  return (
    <div
      className={classNames(
        "card-header tw--mx-6 tw-flex tw-justify-between tw-cursor-pointer",
        className
      )}
      onClick={() => setIsOpen(!isOpen)}
    >
      <span>
        {isOpen ? "Hide" : "View"} {title}
      </span>
      {isOpen ? (
        <span className="fa fa-fw fa-chevron-up" />
      ) : (
        <span className="fa fa-fw fa-chevron-down" />
      )}
    </div>
  );
};
