import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { Formik, Field, Form, FieldArray, useField } from "formik";
import {
  FormGroup,
  Checkbox,
  DatePicker,
  Page,
  Select,
  InputGroup,
  SubmitButton,
  PendingButton,
  PopoverInput, Radio,
} from "components/common";
import {
  useFormHelpers,
  useAsyncEffect,
  useRouter,
  useClient,
  useModal,
  useQueryState,
  useUserContext,
  usePopover,
  useTooltip,
} from "hooks";
import { isEmail, isInt } from "validator";
import { NotificationManager } from "react-notifications";
import {
  keyBy,
  sortBy,
  keys,
  pickBy,
  pick,
  get,
  mapValues,
  find,
} from "lodash";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { age } from "./utils";
import {
  Row,
  Col,
  Collapse,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import classNames from "classnames";
import { CollapseHeader } from "../../components/common";
import { SupportIcon } from "../../components";
import BaseSelect from "react-select";
import {RegistrationReasons as BaseRegistrationReasons} from "../../features/participant_registration/RegistrationReasons";
import {FormProvider, useForm} from "react-hook-form";

const autoComplete = "nope";

const ANOTHER_NONE = "ANOTHER_NONE";
const ANOTHER_ADULT = "ANOTHER_ADULT";
const ANOTHER_CHILD = "ANOTHER_CHILD";

const validateInt = ({ required = true, min = 0 } = {}) => (v) => {
  if (v === null || v === undefined) v = "";
  v = v.toString();
  if (!required && v === "") return undefined;
  return isInt(v, { min }) ? undefined : "invalid";
};

const RegistrationReasons = React.memo(({ defaultValues, onChange }) => {
  const form = useForm({ defaultValues });
  const { watch } = form;

  const values = watch();

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  return (
    <FormProvider {...form}>
      <BaseRegistrationReasons />
    </FormProvider>
  )
});

export const Groups = ({ value }) => {
  const client = useClient();
  const mounted = useRef();
  const [groups, setGroups] = useState();
  const [groupRegistered, setGroupRegistered] = useState();
  const { id } = value;

  useAsyncEffect(async () => {
    const groups = await client.get("groups").get("data");
    setGroupRegistered(keyBy(value.groups, "id"));
    setGroups(sortBy(groups, "name"));
  }, []);

  const onUpdate = useCallback(async () => {
    await client.post(`registered/${id}/groups`, {
      groups: keys(pickBy(groupRegistered)),
    });
  }, [client, groupRegistered, id]);

  useEffect(() => {
    if (mounted.current) {
      onUpdate();
    }
    mounted.current = true;
  }, [mounted, onUpdate]);

  if (!groups) return null;

  return (
    <Page title="Groups" className="tw-max-w-3xl">
      {groups.map((i) => (
        <div key={i.id} className="tw-flex tw-mb-3">
          <Switch
            checked={!!groupRegistered[i.id]}
            onChange={(checked) => {
              setGroupRegistered((v) => ({
                ...v,
                [i.id]: checked ? i : null,
              }));
            }}
          />
          <Link to={`/groups/${i.id}`} className="tw-ml-3">
            {i.name}
          </Link>
        </div>
      ))}
    </Page>
  );
};

const SubHeading = ({ children, hideRule, margin = "tw-mb-6" }) => (
  <>
    {!hideRule ? <hr className="tw-mt-8" /> : null}
    <h3
      className={classNames("tw-font-medium tw-opacity-75 tw-text-xl", margin)}
    >
      {children}
    </h3>
  </>
);

const FamilyRegisteredRow = ({ value, remove }) => {
  const { id: dietaryId, Tooltip: DietaryTooltip } = useTooltip();

  return (
    <tr>
      <td>
        <Link to={`/registered/${value.id}`}>{value.name}</Link>
      </td>
      <td>{age(value.date_of_birth)}</td>
      <td>{value.gender}</td>
      <td>
        {value.has_dietary_requirements && value.dietary_requirements ? (
          <>
            <span
              id={dietaryId}
              className="fa fa-cutlery fa-lg fa-fw text-danger tw-cursor-pointer"
            />
            <DietaryTooltip placement="bottom">
              <div className="tw-whitespace-pre-line">
                {value.dietary_requirements}
              </div>
            </DietaryTooltip>
          </>
        ) : (
          <span className="fa fa-cutlery fa-lg text-muted tw-opacity-25 fa-fw" />
        )}
      </td>
      <td>
        <SupportIcon
          active={value.accessing_wider_support}
          label={value.accessing_wider_support_type_label}
        />
      </td>
      <td className="text-right">
        <PendingButton outline color="tlg-orange" size="sm" onClick={remove}>
          <span className="fa fa-fw fa-trash" />
        </PendingButton>
      </td>
    </tr>
  );
};

const FamilyRegisteredField = ({ name, validate, registered }) => {
  const [, { value }] = useField({
    name,
    validate,
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const indexedValue = useMemo(
    () =>
      value.map((i, idx) => ({
        ...i,
        idx,
      })),
    [value]
  );
  const sortedValue = useMemo(() => sortBy(indexedValue, "name"), [
    indexedValue,
  ]);
  const keyedValue = useMemo(() => keyBy(sortedValue, "id"), [sortedValue]);
  const notRelated = useMemo(
    () => registered.filter((i) => !keyedValue[i.id] && !i.soft_archived),
    [registered, keyedValue]
  );

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <>
          <FormGroup label="Add Related Family Member">
            <BaseSelect
              options={notRelated}
              onChange={(i) => {
                if (i) {
                  push(i);
                  setIsMenuOpen(true);
                }
              }}
              menuIsOpen={isMenuOpen}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
              getOptionLabel={(v) => v.name}
              getOptionValue={(v) => v.id}
              className="react-select"
              classNamePrefix="react-select"
            />
          </FormGroup>

          {sortedValue.length ? (
            <table className="table table-borderless table-sm">
              <thead>
                <tr>
                  <th />
                  <th>Age</th>
                  <th>Gender</th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {sortedValue.map((i) => (
                  <FamilyRegisteredRow
                    key={i.id}
                    value={i}
                    remove={() => remove(i.idx)}
                  />
                ))}
              </tbody>
            </table>
          ) : null}
        </>
      )}
    </FieldArray>
  );
};

const HelpText = ({ className, ...props }) => {
  return (
    <p className={classNames("form-text text-muted small", className)} {...props} />
  )
}

export const RegisterForm = ({
  values,
  setFieldValue,
  setValues,
  submitForm,
  setStatus,
  options,
  registered,
  isValid,
  accessingSupportOptions,
  initialValues,
}) => {
  const {
    has_dietary_requirements,
    has_medical_conditions,
    parcel_additional_children,
    parcel_additional_adults,
    is_adult,
    accessing_wider_support_type,
    family_entries,
    parent_entries,
    english_first_language
  } = values;
  const {
    AddressSubmitModal,
    setIsAddressSubmitOpen,
    isAddressSubmitOpen,
  } = useModal("AddressSubmit");
  const {
    ParcelDetailsModal,
    setIsParcelDetailsOpen,
    isParcelDetailsOpen,
  } = useModal("ParcelDetails");
  const {
    FamilyCheckModal,
    setIsFamilyCheckOpen,
    isFamilyCheckOpen,
  } = useModal("FamilyCheck");
  const [isDetailsOpen, setIsDetailsOpen] = useQueryState("details_expanded");
  const [isParcelDeliveryOpen, setIsParcelDeliveryOpen] = useQueryState(
    "parcel_delivery_expanded"
  );
  const [isStaffUseOnlyOpen, setIsStaffUseOnlyOpen] = useQueryState(
    "staff_use_only_expanded"
  );
  const [
    isRelatedFamilyMembersOpen,
    setIsRelatedFamilyMembersOpen,
  ] = useQueryState("related_family_members");
  const { Popover: ConsentPopover } = usePopover();
  const { isBoxesOfHope } = useUserContext();

  let title = values.id ? `Edit Registered ` : "Register ";
  title += values.is_adult ? " Adult" : "Child";

  const resetField = (target) => (e) => {
    const { checked, name } = e.target;
    setFieldValue(name, checked, true);

    if (!checked) {
      setTimeout(() => {
        setFieldValue(target, "", true);
      }, 0);
    }
  };

  const [total_adults, total_children] = useMemo(() => {
    return [
      Number(parcel_additional_adults) +
        1 +
        family_entries.filter((i) => i.is_adult).length,
      Number(parcel_additional_children) +
        family_entries.filter((i) => !i.is_adult).length,
    ];
  }, [parcel_additional_adults, parcel_additional_children, family_entries]);

  const initialFamily = useRef();

  if (!initialFamily.current) {
    initialFamily.current = {
      children: total_children,
      adults: total_adults
    };
  }

  function checkConstraints(checkDetails = true, checkContact = true, checkFamily = true) {
    if (!isValid) return false;
    const { children, adults } = initialFamily.current;

    if (
      checkDetails &&
      values.parcel_delivery_contact &&
      total_children === 0 &&
      total_adults === 1
    ) {
      setIsParcelDetailsOpen(true);
    } else if (
      checkContact &&
      values.parcel_delivery_contact &&
      !values.address_1
    ) {
      setIsAddressSubmitOpen(true);
    } else if (checkFamily && values.is_adult && values.id && (total_adults !== adults || total_children !== children)) {
      setIsFamilyCheckOpen(true);
    } else {
      return true;
    }
    return false;
  }

  const accessingWiderSupportLabel = useMemo(() => {
    const obj = find(accessingSupportOptions, {
      value: accessing_wider_support_type,
    });
    return obj ? obj.label : "";
  }, [accessingSupportOptions, accessing_wider_support_type]);

  const valuesRef = useRef();
  valuesRef.current = values;

  const handleRegistrationReasonsChange = useCallback((values) => {
    setValues({
        ...valuesRef.current,
        ...values
    });
  }, [setValues, valuesRef]);

  const name = (
    <>
      <Row>
        <Col>
          <FormGroup name="first_name" label="First Name">
            <Field
              name="first_name"
              autoComplete={autoComplete}
              className="form-control"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup name="last_name" label="Surname">
            <Field
              name="last_name"
              autoComplete={autoComplete}
              className="form-control"
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );

  const contact = (
    <>
      <FormGroup name="address_1" label="Address">
        <Field
          name="address_1"
          placeholder="Address 1"
          autoComplete={autoComplete}
          className="form-control"
        />
      </FormGroup>
      <FormGroup name="address_2">
        <Field
          name="address_2"
          placeholder="Address 2"
          autoComplete={autoComplete}
          className="form-control"
        />
      </FormGroup>
      <FormGroup name="address_3">
        <Field
          name="address_3"
          placeholder="Address 3"
          autoComplete={autoComplete}
          className="form-control"
        />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup name="town" label="Town">
            <Field
              name="town"
              autoComplete={autoComplete}
              className="form-control"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup name="county" label="County">
            <Field
              name="county"
              autoComplete={autoComplete}
              className="form-control"
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup name="postcode" label="Postcode">
        <Field
          autoComplete={autoComplete}
          name="postcode"
          className="form-control"
        />
      </FormGroup>

      <Row>
        <Col>
          <FormGroup name="contact_telephone" label="Telephone">
            <InputGroup prependClassName="fa fa-phone fa-fw">
              <Field
                name="contact_telephone"
                autoComplete={autoComplete}
                className="form-control"
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup name="contact_mobile" label="Mobile">
            <InputGroup prependClassName="fa fa-mobile fa-fw">
              <Field
                name="contact_mobile"
                autoComplete={autoComplete}
                className="form-control"
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup name="email" label="Email">
        <InputGroup prependClassName="fa fa-envelope fa-fw">
          <Field
            name="email"
            autoComplete={autoComplete}
            className="form-control"
          />
        </InputGroup>
      </FormGroup>
    </>
  );

  const family = (
    <>
      <Row>
        <Col>
          <FormGroup
            label="Additional Children in Home"
            name="parcel_additional_children"
          >
            <PopoverInput
              popover={<>Add number of children not already listed.</>}
            >
              <Field
                name="parcel_additional_children"
                validate={validateInt()}
                className="form-control"
              />
            </PopoverInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup
            label="Additional Adults in Home"
            name="parcel_additional_adults"
          >
            <PopoverInput
              popover={
                <>
                  Add number of adults not already listed. Do not include
                  current registrant in this number.
                </>
              }
            >
              <Field
                name="parcel_additional_adults"
                validate={validateInt()}
                className="form-control"
              />
            </PopoverInput>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup
            label="Total Children"
            labelClassName="tw-font-semibold"
          >
            <div className="tw--mt-2">
              {isNaN(total_children) ? "0" : total_children}
            </div>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup
            label="Total Adults"
            labelClassName="tw-font-semibold"
          >
            <div className="tw--mt-2">
              {isNaN(total_adults) ? "1" : total_adults}
            </div>
          </FormGroup>
        </Col>
      </Row>
    </>
  );

  const modals = (
    <>
      {isFamilyCheckOpen ? (
          <FamilyCheckModal>
            <ModalBody>
              When you connect this child's registration record to this family, it will update your parcel provision numbers to include meals for an additional child for every parcel you have recorded this year. Please ensure you check and edit the total numbers of family members associated with this family to ensure your Golden Numbers remain accurate. You will find the total family numbers in the 'parcel delivery details' section of the registration record for the main contact in this family - if you need further advice please contact your Church Relationship Lead.
            </ModalBody>
            <ModalFooter>
              <PendingButton
                  color="default"
                  onClick={() => setIsFamilyCheckOpen(false)}
              >
                Close
              </PendingButton>
              <div className="mx-auto" />
              <SubmitButton
                  onClick={async () => {
                    if (checkConstraints(false, false, false)) {
                      await submitForm();
                    }
                  }}
              >Confirm</SubmitButton>
            </ModalFooter>
          </FamilyCheckModal>
      ) : null}
      {isAddressSubmitOpen ? (
        <AddressSubmitModal>
          <ModalBody>
            You have not provided contact information for this person. Without
            contact information, your Parcel Delivery list will be incomplete.
            Are you sure you want to continue?
          </ModalBody>
          <ModalFooter>
            <PendingButton
              color="default"
              onClick={() => setIsAddressSubmitOpen(false)}
            >
              Close
            </PendingButton>
            <div className="mx-auto" />
            <SubmitButton
                onClick={async () => {
                  if (checkConstraints(false, false)) {
                    await submitForm();
                  }
                }}
            >
              Continue Anyway
            </SubmitButton>
          </ModalFooter>
        </AddressSubmitModal>
      ) : null}
      {isParcelDetailsOpen ? (
        <ParcelDetailsModal>
          <ModalBody>
            You have not entered Parcel Delivery Details. You will not be able
            to track Golden Numbers around parcel deliveries without this
            information. Are you sure you want to continue?
          </ModalBody>
          <ModalFooter>
            <PendingButton
              color="default"
              onClick={() => setIsParcelDetailsOpen(false)}
            >
              Close
            </PendingButton>
            <div className="mx-auto" />
            <SubmitButton
              onClick={async () => {
                if (checkConstraints(false)) {
                  await submitForm();
                }
              }}
            >
              Continue Anyway
            </SubmitButton>
          </ModalFooter>
        </ParcelDetailsModal>
      ) : null}
    </>
  )

  if (isBoxesOfHope) {
    return (
      <Page
        title="Register BoH Family"
        className="tw-max-w-3xl"
      >
        {name}
        {family}
        <FormGroup
          check
          name="parcel_additional_dietary_requirements"
          label="Dietary Requirements (for food parcel)?"
          className="form-group"
        >
          <Field
            component={Checkbox}
            name="parcel_additional_dietary_requirements"
            className="form-check-input"
          />
        </FormGroup>

        {values.parcel_additional_dietary_requirements ? (
          <FormGroup
            name="parcel_additional_dietary_requirements_notes"
            className="mt-2"
          >
            <Field
              component="textarea"
              name="parcel_additional_dietary_requirements_notes"
              validate={(v) => !v}
              className="form-control"
            />
          </FormGroup>
        ) : null}
        <hr/>
        {contact}
        <div className="tw-flex tw-justify-end tw-mt-6">
          <SubmitButton
            color="light"
            className="tw-mr-3"
            onClick={async () => {
              setStatus({ addAnother: ANOTHER_ADULT });
              await submitForm();
            }}
          >
            Save & add another Family
          </SubmitButton>
          <SubmitButton
            onClick={async () => {
              setStatus({ addAnother: ANOTHER_NONE });
              await submitForm();
            }}
          >
            Save
          </SubmitButton>
        </div>
      </Page>
    )
  }

  return (
    <Page
      title={
        <>
          {title}
          {values.id ? (
            <span className="ml-2 tw-text-lg tw-pb-1">
              <SupportIcon
                active={accessing_wider_support_type !== "no"}
                label={accessingWiderSupportLabel}
              />
            </span>
          ) : null}
        </>
      }
      className="tw-max-w-3xl"
    >
      {parent_entries.length ? (
        <div className="tw--mt-3 text-muted tw-mb-4 tw-text-sm">
          Part of {parent_entries.length === 1 ? "family" : "families"}:{" "}
          {parent_entries.map((i, idx) => (
            <React.Fragment key={i.id}>
              {idx ? ", " : ""}
              <Link to={`/registered/${i.id}`} key={i.id}>
                {i.name}
              </Link>
            </React.Fragment>
          ))}
        </div>
      ) : null}
      {modals}
      {!values.id ? (
        <FormGroup label="Copy from existing registered person">
          <Field
            name="__copy"
            component={Select}
            options={registered}
            labelKey="name"
            valueKey="id"
            onChange={(v) => {
              if (!v) return null;

              setValues({
                ...values,
                ...pick(v, [
                  "address_1",
                  "address_2",
                  "address_3",
                  "county",
                  "last_name",
                  "postcode",
                  "town",
                  "allow_contact",
                  "contact_telephone",
                  "contact_mobile",
                  "email",
                  "local_promotion",
                  "tlg_nation_promotion",
                ]),
                ...(!is_adult
                  ? pick(v, [
                      "gp_name",
                      "gp_address",
                      "gp_phone_number",
                      "parent_carer_name",
                      "parent_carer_relationship",
                      "pick_up_1",
                      "pick_up_2",
                      "pick_up_3",
                      "password",
                      "consent_name",
                      "consent_date"
                    ])
                  : {}),
                __copy: null,
              });
              NotificationManager.success("Copied from " + v.name);
            }}
          />
          <p className="form-text text-muted small">
            Select a registered person to copy basic details
          </p>
        </FormGroup>
      ) : null}
      {name}
      {values.is_adult ? (
        <FormGroup name="attendance_type" label="Attendance Type">
          <Field
            className="tw-flex-1"
            name="attendance_type"
            component={Select}
            options={options.attendance_type}
            simpleValue
          />
        </FormGroup>
      ) : null}

      {!values.is_adult ? (
        <>
          <FormGroup name="date_of_birth" label="Date of Birth">
            <Field
              name="date_of_birth"
              dateFormat="dd/MM/yyyy"
              className="form-control"
              placeholderText="dd/mm/yyyy"
              component={DatePicker}
            />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup name="gender" label="Gender">
                <Field
                  name="gender"
                  component={Select}
                  options={options.gender}
                  simpleValue
                />
              </FormGroup>
            </Col>
            {values.gender === "Other" ? (
              <Col>
                <FormGroup name="gender_other" label="Other Gender">
                  <Field
                    name="gender_other"
                    autoComplete={autoComplete}
                    validate={(v) => !v}
                    className="form-control"
                  />
                </FormGroup>
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}

      {!is_adult ? (
        <>
          <FormGroup name="school" label="School">
            <Field
              name="school"
              autoComplete={autoComplete}
              className="form-control"
            />
          </FormGroup>
        </>
      ) : null}

      <FormGroup
        check
        name="has_dietary_requirements"
        label="Dietary Requirements?"
      >
        <Field
          component={Checkbox}
          onChange={resetField("dietary_requirements")}
          className="form-check-input"
          name="has_dietary_requirements"
        />
      </FormGroup>
      {has_dietary_requirements ? (
        <FormGroup name="dietary_requirements" className="tw-mb-2">
          <Field
            component="textarea"
            rows={2}
            autoComplete={autoComplete}
            name="dietary_requirements"
            className="form-control"
          />
        </FormGroup>
      ) : null}
      <FormGroup
        check
        name="has_medical_conditions"
        label="Medical Conditions?"
      >
        <Field
          component={Checkbox}
          onChange={resetField("medical_conditions")}
          className="form-check-input"
          name="has_medical_conditions"
        />
      </FormGroup>
      {has_medical_conditions ? (
        <FormGroup name="medical_conditions" className="tw-mb-2">
          <Field
            component="textarea"
            rows={2}
            autoComplete={autoComplete}
            name="medical_conditions"
            className="form-control"
          />
        </FormGroup>
      ) : null}

      <FormGroup name="english_first_language" label="Is English your first language?">
        <FormGroup check name="english_first_language" htmlFor="english_first_language_yes" label="Yes" className="mb-0">
          <Field id="english_first_language_yes" className="form-check-input" name="english_first_language" value="Yes" component={Radio} />
        </FormGroup>
        <FormGroup check name="english_first_language" label="No" className="mb-0" htmlFor="english_first_language_no">
          <Field id="english_first_language_no" className="form-check-input" name="english_first_language" value="No" component={Radio} />
        </FormGroup>
      </FormGroup>

      {english_first_language === 'No' ? (
        <FormGroup name="first_language" label="What is your first language?">
          <Field
            autoComplete={autoComplete}
            name="first_language"
            className="form-control"
            validate={v => !v}
          />
        </FormGroup>
      ) : null}

      {is_adult ? (
        <>
          <FormGroup check name="core_team_member" label="Core Team Member?">
            <Field
              component={Checkbox}
              className="form-check-input"
              name="core_team_member"
            />
          </FormGroup>
          <FormGroup
            check
            name="qualified_first_aider"
            label="Qualified First Aider?"
          >
            <Field
              component={Checkbox}
              className="form-check-input"
              name="qualified_first_aider"
            />
          </FormGroup>
          <FormGroup
            check
            name="food_and_hygiene_trained"
            label="Food & Hygiene Level 2 Trained?"
          >
            <Field
              component={Checkbox}
              className="form-check-input"
              name="food_and_hygiene_trained"
            />
          </FormGroup>
          <FormGroup
            check
            name="parcel_delivery_contact"
            label="Parcel Delivery Contact"
          >
            <Field
              component={Checkbox}
              className="form-check-input"
              name="parcel_delivery_contact"
            />
          </FormGroup>
          <FormGroup
            check
            name="helper"
            label="Helper?"
          >
            <Field
              component={Checkbox}
              className="form-check-input"
              name="helper"
            />
          </FormGroup>
        </>
      ) : null}

      <FormGroup
        check
        name="soft_archived"
        label="Archived"
        className="form-group"
      >
        <Field
          component={Checkbox}
          name="soft_archived"
          className="form-check-input"
        />
      </FormGroup>
      <HelpText className="tw--mt-2">
        Archiving will hide this registration from the Add Attendance dropdowns and Registration list,
        it does not affect existing data on your dashboard and can be undone at any time by un ticking
        the Archive tick box
      </HelpText>

      {values.parcel_delivery_contact ? (
        <>
          <CollapseHeader
            isOpen={isParcelDeliveryOpen}
            setIsOpen={setIsParcelDeliveryOpen}
            title="Parcel Delivery Details"
          />

          <Collapse isOpen={isParcelDeliveryOpen} className="tw-py-4">
            <p className="text-muted tw-text-sm">
              Enter family information below to enable parcel deliveries.
            </p>

            {family}

            <FormGroup
              check
              name="parcel_additional_dietary_requirements"
              label="Additional Dietary Requirements (for food parcel)?"
              className="tw-mb-0"
            >
              <Field
                component={Checkbox}
                name="parcel_additional_dietary_requirements"
                className="form-check-input"
              />
            </FormGroup>

            {values.parcel_additional_dietary_requirements ? (
              <FormGroup
                name="parcel_additional_dietary_requirements_notes"
                className="mt-2"
              >
                <Field
                  component="textarea"
                  name="parcel_additional_dietary_requirements_notes"
                  validate={(v) => !v}
                  className="form-control"
                />
              </FormGroup>
            ) : null}
          </Collapse>
        </>
      ) : null}

      <CollapseHeader
        isOpen={isDetailsOpen}
        setIsOpen={setIsDetailsOpen}
        title="Additional Details"
      />

      <Collapse isOpen={isDetailsOpen} className="tw-py-4">
        {values.is_adult ? (
          <>
            <FormGroup name="date_of_birth" label="Date of Birth">
              <Field
                name="date_of_birth"
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="dd/mm/yyyy"
                component={DatePicker}
              />
            </FormGroup>
            <Row>
              <Col>
                <FormGroup name="gender" label="Gender">
                  <Field
                    name="gender"
                    component={Select}
                    options={options.gender}
                    simpleValue
                  />
                </FormGroup>
              </Col>
              {values.gender === "Other" ? (
                <Col>
                  <FormGroup name="gender_other" label="Other Gender">
                    <Field
                      name="gender_other"
                      autoComplete={autoComplete}
                      validate={(v) => !v}
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
          </>
        ) : null}

        {!is_adult && (
          <>
            <SubHeading hideRule>Medical Details</SubHeading>

            <Row>
              <Col>
                <FormGroup name="gp_name" label="GP Name">
                  <Field
                    autoComplete={autoComplete}
                    name="gp_name"
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup name="gp_phone_number" label="GP Phone Number">
                  <Field
                    autoComplete={autoComplete}
                    name="gp_phone_number"
                    className="form-control"
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup name="gp_address" label="GP Address">
              <Field
                name="gp_address"
                component="textarea"
                rows={3}
                autoComplete={autoComplete}
                className="form-control"
              />
            </FormGroup>
          </>
        )}

        <SubHeading hideRule={is_adult}>Contact Details</SubHeading>

        {!is_adult && (
          <Row>
            <Col>
              <FormGroup name="parent_carer_name" label="Parent/Carer Name">
                <Field
                  autoComplete={autoComplete}
                  name="parent_carer_name"
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup
                name="parent_carer_relationship"
                label="Parent/Carer Relationship to Child"
              >
                <Field
                  name="parent_carer_relationship"
                  className="form-control"
                  autoComplete={autoComplete}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {contact}

        {!is_adult ? (
          <>
            <SubHeading>Pick up Information</SubHeading>

            <Row>
              <Col>
                <FormGroup name="pick_up_1" label="Pick up 1">
                  <Field
                    name="pick_up_1"
                    autoComplete={autoComplete}
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup name="pick_up_2" label="Pick up 2">
                  <Field
                    name="pick_up_2"
                    autoComplete={autoComplete}
                    className="form-control"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup name="pick_up_3" label="Pick up 3">
                  <Field
                    name="pick_up_3"
                    autoComplete={autoComplete}
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup name="password" label="Password">
                  <Field
                    name="password"
                    autoComplete={autoComplete}
                    className="form-control"
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <SubHeading>Registration Reasons</SubHeading>
            <RegistrationReasons defaultValues={initialValues} onChange={handleRegistrationReasonsChange} />
          </>
        )}

        <SubHeading margin="tw-mb-2">Photo Permission</SubHeading>

        <p className="text-muted tw-text-sm">
          These images may appear in printed or digital promotions. Please
          indicate consent for the following:
        </p>

        <Row>
          <Col>
            <FormGroup
              check
              name="local_promotion"
              label="Local Promotion"
              className="tw-mb-0"
            >
              <Field
                component={Checkbox}
                name="local_promotion"
                className="form-check-input"
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup
              check
              className="tw-mb-0"
              name="tlg_nation_promotion"
              label="TLG National Promotion"
            >
              <Field
                component={Checkbox}
                name="tlg_nation_promotion"
                className="form-check-input"
              />
            </FormGroup>
          </Col>
        </Row>

        <SubHeading>Marketing</SubHeading>

        <p className="text-muted tw-text-sm tw-mb-2">
          Would you be happy to be contacted by the local Make Lunch Team occasionally to be notified of upcoming events and activities?
        </p>

        <FormGroup check name="allow_contact" htmlFor="allow_contact_yes" label="Yes" className="mb-0">
          <Field id="allow_contact_yes" className="form-check-input" name="allow_contact" value="Yes" component={Radio} />
        </FormGroup>
        <FormGroup check name="allow_contact" label="No" className="mb-0" htmlFor="allow_contact_no">
          <Field id="allow_contact_no" className="form-check-input" name="allow_contact" value="No" component={Radio} />
        </FormGroup>

        {!is_adult && (
          <>
            <SubHeading>Consent</SubHeading>
            <Row>
              <Col>
                <FormGroup name="consent_date" label="Date">
                  <Field
                    name="consent_date"
                    dateFormat="dd/MM/yyyy"
                    component={DatePicker}
                    className="form-control"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup name="consent_name" label="Name">
                  <Field
                    name="consent_name"
                    autoComplete={autoComplete}
                    id="consent_name"
                    className="form-control"
                  />
                </FormGroup>
                <ConsentPopover
                  target="consent_name"
                  placement="top"
                  trigger="focus"
                  className="popover--lg"
                  flip={false}
                >
                  <div className="tw-p-2">
                    <p>
                      <strong>
                        I give permission for the named child to take part in
                        the normal activities of this group.
                      </strong>
                      <br />I understand that separate permission will be sought
                      for certain activities, including swimming, and outings
                      lasting longer than the normal meeting times of the group.
                      I understand that while involved he/she will be under the
                      control and care of the group leader and/or other adults
                      approved by the place of worship/organisation leadership
                      and that, while the staff in charge of the group will take
                      all reasonable care of the children, they cannot
                      necessarily be held responsible for any loss, damage or
                      injury suffered by my child during, or as a result of, the
                      activity.
                    </p>
                    I understand:
                    <ul>
                      <li>
                        My child will receive medication as instructed before or
                        during the event.
                      </li>
                      <li>
                        Every effort will be made to contact me as soon as
                        possible should my child become ill or have an accident.
                      </li>
                      <li>
                        My child will be given medical/dental treatment as
                        necessary.
                      </li>
                    </ul>
                  </div>
                </ConsentPopover>
              </Col>
            </Row>
          </>
        )}
      </Collapse>

      <CollapseHeader
        isOpen={isStaffUseOnlyOpen}
        setIsOpen={setIsStaffUseOnlyOpen}
        title="Church Connection status"
      />

      <Collapse isOpen={isStaffUseOnlyOpen} className="tw-py-4">
        <FormGroup
          name="accessing_wider_support_type"
          label="Church Connection status"
        >
          <div className="tw-text-sm mt-2 tw-text-gray-700">
            <p>
              Please indicate the individuals connection to the wider support of
              the church:
            </p>
            <p>
              <strong>Wider support of the church is defined as:</strong>
            </p>
            <p>
              Taking part in a faith based or non-faith based direct church led
              activity e.g. Sunday service, small group, youth group, holiday
              club weekend away
            </p>
            <p>OR</p>
            <p>
              Accessing a project linked to the Church i.e. foodbank, debt
              counselling, Toddler Group etc.
            </p>
          </div>
          <Field
            component={Select}
            options={accessingSupportOptions}
            name="accessing_wider_support_type"
            simpleValue
          />
        </FormGroup>

        {!is_adult ? (
          <>
            <hr />
            <FormGroup name="referral_source" label="Referral Source">
              <Field
                autoComplete={autoComplete}
                name="referral_source"
                className="form-control"
              />
            </FormGroup>
          </>
        ) : null}
      </Collapse>

      {is_adult ? (
        <>
          <CollapseHeader
            isOpen={isRelatedFamilyMembersOpen}
            setIsOpen={setIsRelatedFamilyMembersOpen}
            title="Related Family Members"
          />

          <Collapse isOpen={isRelatedFamilyMembersOpen} className="tw-py-4">
            {values.id ? (
              <FamilyRegisteredField
                name="family_entries"
                registered={registered}
              />
            ) : (
              <Alert color="warning">
                Please save before adding related family members.
              </Alert>
            )}
          </Collapse>
        </>
      ) : null}

      <div className="tw-flex tw-justify-end tw-mt-6">
        <SubmitButton
          color="light"
          className="tw-mr-3"
          onClick={async () => {
            setStatus({ addAnother: ANOTHER_CHILD });
            if (checkConstraints()) {
              await submitForm();
            }
          }}
        >
          Save & add another Child
        </SubmitButton>
        <SubmitButton
          color="light"
          className="tw-mr-3"
          onClick={async () => {
            setStatus({ addAnother: ANOTHER_ADULT });

            if (checkConstraints()) {
              await submitForm();
            }
          }}
        >
          Save & add another Adult
        </SubmitButton>
        <SubmitButton
          onClick={async () => {
            setStatus({ addAnother: ANOTHER_NONE });
            if (checkConstraints()) {
              await submitForm();
            }
          }}
        >
          Save
        </SubmitButton>
      </div>
    </Page>
  );
};

export const Register = ({ client }) => {
  const {
    validationObject,
    setInitialValues,
    initialValues,
    load,
    save,
    replacePathId,
    pushPathId,
  } = useFormHelpers({ url: "registered" });
  const { query, history } = useRouter();

  const { session, parcel_provision_session } = query;
  const formikRef = useRef();
  const [options, setOptions] = useState();
  const [accessingSupportOptions, setAccessingSupportOptions] = useState();
  const [registered, setRegistered] = useState();
  const { allowMultipleGroups, isBoxesOfHope } = useUserContext();

  useAsyncEffect(async () => {
    const [
      options,
      accessingSupportOptions,
      initialValues,
      registered,
    ] = await Promise.all([
      client.get("registered/options").get("data"),
      client.get("registered/accessing_support_types").get("data"),
      load({
        gender: "",
        consent_name: "",
        consent_date: null,
        first_name: "",
        last_name: "",
        gender_other: "",
        upn: "",
        school_name: "",
        school_name_other: "",
        attendance_type: isBoxesOfHope ? "Participant" : "",
        accessing_wider_support_type: "no",
        is_adult: query.adult !== undefined,
        date_of_birth: null,
        address_1: "",
        address_2: "",
        address_3: "",
        town: "",
        county: "",
        postcode: "",
        contact_telephone: "",
        contact_mobile: "",
        email: "",
        dietary_requirements: "",
        has_dietary_requirements: false,
        medical_conditions: "",
        has_medical_conditions: false,
        local_promotion: false,
        tlg_nation_promotion: false,
        school: "",
        referral_source: "",
        gp_name: "",
        gp_address: "",
        gp_phone_number: "",
        parent_carer_name: "",
        parent_carer_relationship: "",
        pick_up_1: "",
        pick_up_2: "",
        pick_up_3: "",
        password: "",
        allow_contact: "",
        why_fun_activities: false,
        why_new_friends: false,
        why_lunch: false,
        why_cost: false,
        why_other: false,
        why_other_detail: "",
        true_statements_special_educational_needs: false,
        true_statements_child_disability: false,
        true_statements_free_school_meals: false,
        true_statements_looked_after: false,
        true_statements_young_carers: false,
        true_statements_none_1: false,
        true_statements_refugees: false,
        true_statements_lone_parent: false,
        true_statements_struggling_financially: false,
        true_statements_adult_disability: false,
        true_statements_mental_health: false,
        true_statements_isolated: false,
        true_statements_none_2: false,
        parcel_delivery_contact: isBoxesOfHope,
        parcel_additional_children: "0",
        parcel_additional_adults: "0",
        parcel_additional_dietary_requirements: false,
        parcel_additional_dietary_requirements_notes: "",
        family_entries: [],
        parent_entries: [],
        soft_archived: false,
        helper: false,
        english_first_language: "",
        first_language: ""
      }),
      client.get("registered").get("data"),
    ]);
    setAccessingSupportOptions(accessingSupportOptions);
    setOptions(
      mapValues(options, (i) => i.map((j) => ({ label: j, value: j })))
    );
    setRegistered(
      initialValues.id
        ? registered.filter((i) => i.id !== initialValues.id)
        : registered
    );
    setInitialValues(initialValues);
  }, []);

  async function onSubmit(x, { setSubmitting }) {
    const addAnother = get(formikRef, "current.status.addAnother");

    try {
      const resp = await save({
        ...x,
        school_name: x.school_name || "",
        school_name_other: x.school_name === "Other" ? x.school_name_other : "",
        gender_other: x.gender === "Other" ? x.gender_other : "",
        family_entries: x.family_entries.map((i) => i.id),
      });

      if (parcel_provision_session) {
        history.replace(
          `/parcel_provision_sessions/${parcel_provision_session}`,
          {
            fromRegister: true,
          }
        );
      } else if (session) {
        history.replace(`/sessions/${session}`, {
          fromRegister: true,
        });
      } else {
        replacePathId(resp.id);

        if (addAnother === ANOTHER_ADULT) {
          pushPathId("new?adult=true");
        } else if (addAnother === ANOTHER_CHILD) {
          pushPathId("new");
        }
      }

      NotificationManager.success("Saved successfully");
    } finally {
      setSubmitting(false);
    }
  }

  if (!initialValues) return null;

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validationObject({
          gender: (v, { is_adult }) => (!is_adult ? !v : false),
          first_name: (v) => !v,
          last_name: (v) => !v,
          medical_conditions: (v, { has_medical_conditions }) =>
            has_medical_conditions ? !v : false,
          dietary_requirements: (v, { has_dietary_requirements }) =>
            has_dietary_requirements ? !v : false,
          email: (v) => (v ? !isEmail(v || "") : false),
          attendance_type: (v, { is_adult }) => (is_adult ? !v : false),
        })}
      >
        {(formik) => {
          formikRef.current = formik;
          return (
            <Form autoComplete={autoComplete}>
              <RegisterForm
                registered={registered}
                initialValues={initialValues}
                accessingSupportOptions={accessingSupportOptions}
                options={options}
                {...formik}
              />
            </Form>
          );
        }}
      </Formik>
      {!!(initialValues.id && allowMultipleGroups) && (
        <Groups value={initialValues} />
      )}
    </>
  );
};

export default ({ match, ...props }) => (
  <Register {...props} match={match} key={match.params.id} />
);
