import React from "react";
import { useQuery } from "react-query";
import { useClient } from "hooks";
import { NotificationManager } from "react-notifications";
import { useRouter } from "hooks";
import {FormGroup, Page, SubmitButton} from "components/common";
import {Field, FormikProvider, useFormik} from "formik";
import {isFloat} from "validator";
import {isEmpty} from "lodash";

export const TeamSectionEdit = () => {
  const client = useClient();
  const { match, history } = useRouter();
  const { id } = match.params;
  const formik = useFormik({
    onSubmit,
    initialValues: {}
  })
  const { handleSubmit, setValues, values } = formik;

  const url = "team_sections";

  const { refetch } = useQuery(
    [`${url}/${id}`],
    args => client.get(args).get("data"),
    {
      onSuccess: (values) => setValues(values),
    }
  );

  async function onSubmit(x) {
    const { id: _id } = await (
      x.id ? client.put(`${url}/${x.id}`, x) : client.post(url, x)
    ).get('data');
    NotificationManager.success("Section successfully saved");

    if (id === `${_id}`) {
      await refetch({ throwOnError: true });
    } else {
      history.replace(match.path.replace(":id", _id));
    }
  }

  if (isEmpty(values)) return null;

  return (
    <Page title="Team Section">
      <FormikProvider value={formik}>
        <form noValidate onSubmit={handleSubmit}>
          <FormGroup name="name" label="Name">
            <Field name="name" className="form-control" validate={(v) => !v} />
          </FormGroup>
          <FormGroup name="order" label="Order">
            <Field name="order" className="form-control" validate={(v) => !isFloat(v + "")} />
          </FormGroup>
          <div className="tw-flex tw-justify-end">
            <SubmitButton>Save</SubmitButton>
          </div>
        </form>
      </FormikProvider>
    </Page>
  );
};
