import React from "react";
import {
  useOnMount,
  useOnUpdate,
  useFormikContext,
  useUserContext,
} from "hooks";
import {
  Page,
  FormGroup,
  Select,
  DatePicker,
  SubmitButton,
  Checkbox,
} from "components/common";
import { Formik, Field } from "formik";
import { find } from "lodash";
import { Table } from "reactstrap";
import { YEAR_OPTIONS, THIS_YEAR } from "config/contants";
import { NotificationManager } from "react-notifications";

const STATUS_OPTIONS = ["Open", "Closed"].map((i) => ({ label: i, value: i }));

const SetupForm = ({ client }) => {
  const { values, setFieldValue } = useFormikContext();
  const { year, data } = values;

  async function load() {
    const [_allocation, holidays] = await Promise.all([
      client.get(`holiday_allocation/${year}`).get("data"),
      client.get(`holiday_allocation/${year}/holidays`).get("data"),
    ]);

    const allocation = holidays.map((holiday) => {
      const existing = find(_allocation, { holiday });
      if (existing) {
        existing.status = "Open";
        return existing;
      }

      return {
        year,
        status: "Closed",
        holiday,
        start_date: null,
        end_date: null,
      };
    });
    setFieldValue("data", allocation);
  }

  useOnMount(load);
  useOnUpdate(load, [year], !year);

  if (!data) return null;

  return (
    <div>
      <FormGroup name="year">
        <Field
          name="year"
          component={Select}
          options={YEAR_OPTIONS}
          simpleValue
          searchable={false}
        />
      </FormGroup>
      <Table size="sm">
        <thead>
          <tr>
            <th />
            <th>Status</th>
            <th>Start date</th>
            <th>End date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((i, idx) => (
            <tr key={i.holiday}>
              <td className="tw-font-medium">{i.holiday}</td>
              <td>
                <FormGroup name={`data.${idx}.status`} className="tw-mb-0">
                  <Field
                    className="tw-w-32"
                    component={Select}
                    options={STATUS_OPTIONS}
                    simpleValue
                    name={`data.${idx}.status`}
                    searchable={false}
                  />
                </FormGroup>
              </td>
              <td>
                {i.status === "Open" && (
                  <FormGroup
                    name={`data.${idx}.start_date`}
                    className="tw-mb-0"
                  >
                    <Field
                      validate={(v) => !v}
                      component={DatePicker}
                      name={`data.${idx}.start_date`}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                  </FormGroup>
                )}
              </td>
              <td>
                {i.status === "Open" && (
                  <FormGroup name={`data.${idx}.end_date`} className="tw-mb-0">
                    <Field
                      validate={(v) => !v}
                      component={DatePicker}
                      name={`data.${idx}.end_date`}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                  </FormGroup>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="tw-flex tw-justify-end">
        <SubmitButton>Save</SubmitButton>
      </div>
    </div>
  );
};

const SettingsForm = ({ client }) => {
  const { values, setValues } = useFormikContext();

  async function load() {
    const [me] = await Promise.all([client.get("me").get("data")]);
    setValues(me);
  }

  useOnMount(load);

  if (!values) return null;

  return (
    <div>
      <FormGroup check label="Enable 2-step Attendance?">
        <Field
          component={Checkbox}
          name="two_step_attendance"
          className="form-check-input"
        />
      </FormGroup>
      <p className="form-text text-muted small tw--mt-4">
        Enable 2-step attendance if you want to pre-book your lunch club
        sessions
      </p>
      <div className="tw-flex tw-justify-end">
        <SubmitButton>Save</SubmitButton>
      </div>
    </div>
  );
};

export const Setup = ({ client, location }) => {
  const { reload } = useUserContext();
  const { state } = location;

  async function onSubmit({ data, year }, { setSubmitting }) {
    try {
      await client.post(
        `holiday_allocation/${year}`,
        data.filter((i) => i.status === "Open")
      );
      NotificationManager.success("Holidays successfully saved");
    } finally {
      setSubmitting(false);
    }
  }

  async function onSettingsSubmit(x, { setSubmitting }) {
    try {
      await client.post("me", x);
      await reload();
      NotificationManager.success("Settings successfully saved");
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Page title="Holiday Dates" className="tw-max-w-2xl">
        <Formik
          onSubmit={onSubmit}
          initialValues={{ year: (state && state.year) ? state.year : THIS_YEAR, data: null }}
        >
          <SetupForm client={client} />
        </Formik>
      </Page>
      <Page title="General Settings" className="tw-max-w-2xl">
        <Formik onSubmit={onSettingsSubmit}>
          <SettingsForm client={client} />
        </Formik>
      </Page>
    </>
  );
};

export default Setup;
