import React from "react";
import { TextQuestion } from "./TextQuestion";
import { WordCount } from "../application_form/components/WordCount";
import produce from "immer";
import { set } from "lodash";
import wordsCount from "words-count";

export const TextareaQuestion = ({
  name,
  minWords,
  label,
  required,
  help,
  helpPosition,
  rows = 3,
  defaultValue = "",
  autoFocus,
  labelClassName
}) => {
  const getInputProps = produce((props) => {
    props.as = "textarea";
    props.rows = rows;

    if (minWords && required) {
      set(props, "rules.validate.minWords", (v) => {
        return wordsCount(v) >= minWords;
      });
    }
  });

  return (
    <TextQuestion
      name={name}
      label={label}
      required={required}
      help={help}
      helpPosition={helpPosition}
      getInputProps={getInputProps}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      labelClassName={labelClassName}
    >
      {minWords ? <WordCount name={name} minWords={minWords} /> : null}
    </TextQuestion>
  );
};
