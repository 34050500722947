import { formatDateTime } from "../../util";
import { ConfirmDeleteButton } from "./ConfirmDeleteButton";
import React from "react";

export const Note = ({ value, onDelete, allowDelete = true }) => {
  const { user_email, created, note } = value;

  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-start">
        <p className="text-muted">
          Note created by <strong>{user_email}</strong> on{" "}
          <strong>{formatDateTime(created)}</strong>
        </p>
        {allowDelete ? (
          <ConfirmDeleteButton onConfirmed={() => onDelete(value)} />
        ) : null}
      </div>
      <p>{note}</p>
      <hr />
    </div>
  );
};
