import {useQuery} from "react-query";
import {useClient} from "../../hooks";

export function useInactiveReasons() {
  const queryKey = ['users/pastors/inactive_reasons'];
  const client = useClient();

  return useQuery({
    queryKey,
    queryFn: () => client.get(...queryKey).get('data')
  })
}