export const QUESTIONS = {
  how_long: "How long have you been coming to Make Lunch?",
  anonymous: "Tick this box if you prefer your answers to be anonymous",
  agreement_food_nutritious:
    "Make Lunch provides nutritious and enjoyable meals for my family",
  agreement_activities_engaging:
    "My children are more active and social in the holidays because of Make Lunch",
  agreement_vital_support:
    "Make Lunch is a vital support to my family in the holidays",
  agreement_volunteers_friendly:
    "Make Lunch volunteers are friendly and supportive",
  agreement_community:
    "I feel like I am part of a community because of Make Lunch",
  agreement_wellbeing:
    "Make Lunch has had a positive impact on my family's well-being",
  agreement_attend_activities:
    "I feel more welcome to attend other events and activities happening at the local church because of Make Lunch",
  agreement_recommend: "I would recommend Make Lunch to other families",
  gender: "Gender",
  name: "Your name",
  appreciate_most: "What do you like best about Make Lunch?",
  suggestions: "How could Make Lunch be even better?",
  memorable_moment: "Can you tell us about a happy experience or special moment at Make Lunch?",
  favourite_foods_activities_games: 'Use this space to tell us about your favourite foods, activities and games',
  anything_else:
    "Is there anything else you would like to say?",
};

export const GENDERS = {
  male: "Male",
  female: "Female",
  other: "Other",
  prefer_not_to_say: "Prefer not to say",
};

export const HOW_LONG = {
  first_time: "This is my first time",
  less_than_1_year: "Less than 1 year",
  "1_3_years": "1-3 years",
  more_than_3_years: "More than 3 years",
};

export const AGREEMENT_LEVELS = {
  strongly_agree: "Strongly Agree",
  agree: "Agree",
  neither_agree_or_disagree: "Neither Agree or Disagree",
  disagree: "Disagree",
  strongly_disagree: "Strongly Disagree",
};
