import React, {useEffect} from 'react';
import {useQuery} from "react-query";
import {useClient, useModal} from "../../hooks";
import {ModalBody} from "reactstrap";
import {Link} from "react-router-dom";
import {EthnicityCaptureForm} from "./EthnicityCaptureForm";
import {NotificationManager} from "react-notifications";
import {ethnicityCaptureUrl} from "./constants";

const captureRequiredUrl = 'ethnicity_capture/capture_required'
export const EthnicityCapture = () => {
  const client = useClient();
  const { data } = useQuery([captureRequiredUrl], () => client.get(captureRequiredUrl).get('data'));
  const { capture_required, is_existing_user } = data || {};
  const { Modal, isOpen, setIsOpen } = useModal();

  useEffect(() => {
    if (capture_required) {
      setIsOpen(true)
    }
  }, [capture_required, setIsOpen]);

  async function onSubmit(x) {
    await client.post(ethnicityCaptureUrl, x);
    NotificationManager.success('Saved successfully');
    setIsOpen(false);
  }

  return (
    <>
      {isOpen && (
        <Modal size="lg" toggle={null}>
          <ModalBody>
            {is_existing_user ? (
              <p>
                Welcome back to Kitchen Cupboard! Before you get started today, please can you help us to gain an
                accurate picture of cultural representation among our volunteer base, by selecting which of the below
                statements best reflects your ethnicity.
              </p>
            ) : (
              <p>
                Welcome to Kitchen Cupboard! We are really excited for you to get connected and begin to explore all our
                resources. Before you get started, please can you help us to gain an accurate picture of cultural
                representation among our volunteer base, by selecting which of the below statements best reflects your
                ethnicity
              </p>
            )}
            <p>
              By answering this question, you are giving your consent for us to collect and hold this data according to our Privacy
              Notice which can be viewed at: <Link to="https://www.tlg.org.uk/other/privacy-policy/volunteer-programmes-privacy-notice">https://www.tlg.org.uk/other/privacy-policy/volunteer-programmes-privacy-notice</Link>
            </p>
            <p className="tw-font-medium">
              If you prefer not to give this information, please choose the last option ‘prefer not to say’
            </p>
            {is_existing_user ? (
              <p>
                Thank you for all you are doing to support the families in your community.
              </p>
            ) : null}
            <p className="tw-text-lg tw-font-medium">
              Which of these statements best describes your Ethnicity? (Please check the appropriate box):
            </p>
            <EthnicityCaptureForm onSubmit={onSubmit} />
          </ModalBody>
        </Modal>
      )}

    </>
  )
}