import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { Alert } from "reactstrap";
import React, { useEffect } from "react";
import { useClient } from "hooks";

export const ExistingEntryCheck = ({ url, id }) => {
  const { getValues } = useFormContext();
  const client = useClient();
  const { year, organisation, holiday } = getValues();

  const [mutate, { reset, data }] = useMutation(
    async ({ year, holiday, organisation }) => {
      const data = await client
        .get(url, {
          params: {
            year,
            holiday,
            organisation,
          },
        })
        .get("data");
      if (!data.length) return false;
      return data[0].id !== id;
    }
  );

  useEffect(() => {
    reset();
    if (!(year && organisation && holiday)) return;
    mutate({ year, holiday, organisation });
  }, [year, organisation, holiday, mutate, reset]);

  if (data) {
    return (
      <Alert color="warning">
        A manual entry for this Club/Year/Holiday already exists.
        Submitting the form will overwrite previous entry.
      </Alert>
    );
  }

  return null;
};
