import React from 'react';
import {formatDateTime, getFormStatus} from "../../util";
import {ConfirmButton} from "../../components/ConfirmButton";
import cx from 'classnames';
import {Link} from "react-router-dom";
import {ReferenceChecked} from "./ReferenceChecked";
import {useUserContext} from "../../../../hooks";

export const ReferenceRow = ({ match, allowSend, value, onSendEmail, onCheckedChanged }) => {
  const { isCoreTeamMember } = useUserContext();
  const { full_name, organisation, email, position, phone, email_sent, form, checked, id } = value;
  const { status, submitted } = form;

  return (
    <tr>
      <td>
        <ul className="pl-0 tw-list-none">
          <li>
            {isCoreTeamMember ? (
              full_name
            ) : (
              <Link to={{
                pathname: `${match.url}/reference_form`,
                state: form
              }}>
                {full_name}
              </Link>
            )}
          </li>
          {email ? (
            <li className="text-muted small">
              {email}
            </li>
          ) : null}
          {position ? (
            <li className="text-muted small">
              {position}
            </li>
          ) : null}
          {phone ? (
            <li className="text-muted small">
              {phone}
            </li>
          ) : null}
        </ul>
      </td>
      <td>{organisation}</td>
      <td>
        <ul className="plain-ul">
          <li>
            {email_sent ? (
              <>
                <span className="fa fa-check-circle text-success" /> Reference request sent on <strong>{formatDateTime(email_sent)}</strong>
              </>
            ) : (
              <>
                <span className="fa fa-times-circle text-danger" /> Reference request not sent
              </>
            )}
          </li>
          <li>
            <span className={cx("fa", {
              'fa-times-circle text-danger': !submitted,
              'fa-check-circle text-success': submitted
            })} />
            {' '}
            Reference response <strong>{getFormStatus(status)}</strong>
            {' '}
            {submitted ? (
              <>
                at <strong>{formatDateTime(submitted)}</strong>
              </>
            ) : null}
            {' '}
          </li>
          {submitted ? (
            <li>
              <ReferenceChecked value={checked} id={id} onChange={checked => {
                onCheckedChanged({
                  id,
                  checked
                })
              }} />
            </li>
          ) : null}
        </ul>
      </td>
      <td>
        {allowSend ? (
          <ConfirmButton size="sm" onClick={() => onSendEmail(value)} confirmProps={{
            color: 'warning',
            children: (
              <>
                <span className="fa fa-envelope mr-2" /> Confirm send?
              </>
            )
          }}>
            <span className="fa fa-envelope mr-2" /> Send reference request
          </ConfirmButton>
        ) : null}
      </td>
    </tr>
  )
}