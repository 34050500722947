import cx from "classnames";
import React from "react";
import { APPLICATION_STATUSES } from "../constants";

export const getFormStatus = (status) => {
  const className = cx({
    "text-success": status === APPLICATION_STATUSES.SUBMITTED,
    "text-warning": status === APPLICATION_STATUSES.STARTED,
    "text-danger": status === APPLICATION_STATUSES.NOT_STARTED,
  });
  return <span className={className}>{status}</span>;
};
