import { useState } from "react";
import { useCommonContext, useRouter } from "hooks";
import { chain } from "lodash";
import { getIn } from "formik";

export const validationObject = (validators) => (data) => {
  const topLevelErrors = validators[""] ? validators[""](data) : {};

  const fieldErrors = chain(validators)
    .mapValues((fn, key) => {
      if (key === "") return false;
      return fn(getIn(data, key), data);
    })
    .pickBy()
    .value();

  return {
    ...topLevelErrors,
    ...fieldErrors,
  };
};

export const useFormHelpers = ({
  url,
  client: clientProp,
  resource,
  initialValues: initialValuesProp,
} = {}) => {
  const { client: _client, reload } = useCommonContext();
  const { history, match } = useRouter();
  const client = clientProp || _client;
  const [initialValues, setInitialValues] = useState(initialValuesProp);
  const { id } = match.params;
  const isNew = id === "new";
  const pageTitle = id === "new" ? `Create ${resource}` : `Edit ${resource}`;

  function save(x, { patch = false } = {}) {
    const { id } = x;
    const isNew = !id;
    return isNew
      ? client.post(url, x).get("data")
      : client[patch ? "patch" : "put"](`${url}/${id}`, x).get("data");
  }

  function load(defaultValue = {}) {
    if (id === "new") return Promise.resolve(defaultValue);
    return client.get(`${url}/${id}`).get("data");
  }

  function replacePathId(id, { reload: reloadProp = true } = {}) {
    history.replace(match.path.replace(":id", id));
    if (reloadProp) reload();
  }

  function pushPathId(id, { reload: reloadProp = true } = {}) {
    history.push(match.path.replace(":id", id));
    if (reloadProp) reload();
  }

  return {
    save,
    load,
    pageTitle,
    pushPathId,
    validationObject,
    initialValues,
    setInitialValues,
    parseError: client.parseError,
    reload,
    replacePathId,
    id,
    isNew,
  };
};

export default useFormHelpers;
