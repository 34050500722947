import React, {useEffect} from "react";
import {useMutation, useQuery} from "react-query";
import { useClient } from "hooks";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useRouter } from "hooks";
import { Page } from "components/common";
import {FormGroup, Select, SubmitButton, DatePicker} from "@coherent/react-hook-form";

export const HolidayTextsEdit = () => {
  const client = useClient();
  const { match, history } = useRouter();
  const { id } = match.params;

  const form = useForm({
    mode: "onBlur",
    criteriaMode: "all",
  });
  const url = "holiday_texts";

  const { reset, register, handleSubmit } = form;

  const { data: regions } = useQuery("organisations/regions", () =>
    client.get("organisations/regions").get("data")
  );

  const { data: holidays } = useQuery("holiday_allocation/holidays", (url) =>
    client
      .get(url)
      .get("data")
      .map((i) => ({
        label: i,
        value: i,
      }))
  );

  const [mutate, { data }] = useMutation(async (id) => client.get(`${url}/${id}`).get('data'), {
    onSuccess: values => reset(values)
  })

  useEffect(() => void mutate(id), [mutate, id]);

  async function onSubmit(x) {
    const { id } = x.id ? await client.put(`${url}/${x.id}`).get('data') : await client.post(url, x).get('data');
    NotificationManager.success("Successfully saved");
    await mutate(id);
    history.replace(match.path.replace(":id", id));
  }

  if (!data) return null;

  return (
    <Page title="Holiday Text">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label="Year" name="year">
            <input
              type="number"
              className="form-control"
              ref={register({
                required: true,
                min: 2022,
                valueAsNumber: true,
              })}
              name="year"
            />
          </FormGroup>
          <FormGroup label="Holiday" name="holiday">
            <Select
              name="holiday"
              rules={{ required: true }}
              options={holidays}
              simpleValue
            />
          </FormGroup>
          <FormGroup label="Include regions" name="include_regions" help={
            'Leave blank to include all regions, or specify regions to include'
          }>
            <Select name="include_regions" isMulti simpleValue options={regions} />
          </FormGroup>
          <FormGroup label="Exclude regions" name="exclude_regions" help={
            'Leave blank to not exclude any regions, or specify regions to exclude'
          }>
            <Select name="exclude_regions" isMulti simpleValue options={regions} />
          </FormGroup>
          <FormGroup label="Start date" name="start_date" help={
            "If a user replies 'Yes', the club will be 'Open' for the selected holiday with a start date of the above"
          }>
            <DatePicker rules={{
              required: true
            }} name="start_date" className="form-control" />
          </FormGroup>
          <FormGroup label="End date" name="end_date" help={
            "If a user replies 'Yes', the club will be 'Open' for the selected holiday with an end date of the above"
          }>
            <DatePicker rules={{
              required: true
            }} name="end_date" className="form-control" />
          </FormGroup>
          <FormGroup label="First text date" name="first_text_date" help={
            'The date the first text should be sent'
          }>
            <DatePicker rules={{
              required: true
            }} name="first_text_date" className="form-control" />
          </FormGroup>
          <FormGroup label="Second text date" name="second_text_date" help={
            'The date the second text (reminder) should be sent'
          }>
            <DatePicker rules={{
              required: true
            }} name="second_text_date" className="form-control" />
          </FormGroup>
          <FormGroup label="Third text date" name="third_text_date" help={
            'The date the reminder to record ML holiday activity should be sent'
          }>
            <DatePicker rules={{
              required: true
            }} name="third_text_date" className="form-control" />
          </FormGroup>
          <div className="tw-flex tw-justify-end">
            <SubmitButton>Submit</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
