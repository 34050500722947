import { FormProvider, useForm } from "react-hook-form";
import React from "react";
import {Step1Form, Step2Form, Step3Form} from "../../reference_form/components";

export const ApplicationReferenceForm = ({ defaultValues }) => {
  const methods = useForm({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <fieldset disabled>
        <Step1Form required={false} />
        <hr />
        <Step2Form required={false} />
        <hr />
        <Step3Form required={false} />
      </fieldset>
    </FormProvider>
  );
};
