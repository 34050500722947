import { useEffect } from "react";

export const useOnMount = (fn) => {
  useEffect(() => {
    void fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnMount;
