import { get } from "lodash";
import { NotificationManager } from "react-notifications";

export const handleNonFieldErrors = (ex) => {
  const nonFieldError = get(ex, "response.data.detail.non_field_errors.0");

  if (nonFieldError) {
    NotificationManager.error(nonFieldError);
    ex.handled = true;
    throw ex;
  } else {
    throw ex;
  }
};
