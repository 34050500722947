import React, { useEffect } from "react";
import {useTable} from "hooks";
import { Page } from "components/common";
import {Alert, Col, Row} from "reactstrap";
import {VimeoVideo} from "../../../components/VimeoVideo";
import {Section} from "./components/Section";
import {Heading} from "./components/Heading";
import {Content} from "./components/Content";

export const List = ({ match, directory }) => {
  const { TableSearch, TableDataFetch, data, search, setFilters } = useTable({
    match,
    url: "video_resource_files",
    resource: "Video Resources",
    limit: 99999,
    ordering: "name",
  });

  useEffect(() => {
    setFilters((v) => ({
      ...v,
      resource_directory: directory ? directory.key : undefined,
    }));
  }, [directory, setFilters]);

  return (
    <div>
      <Page title="Video Resources">
        <TableSearch className="fa-min" />
        <TableDataFetch />
      </Page>
      <Section
        title={
          search
            ? "Search Results"
            : directory
            ? `Video Resources for ${directory.title}`
            : "New Video Resources"
        }
      >
        {data.results.length === 0 ? (
          <Alert color="info" className="mb-0">
            No resources found
          </Alert>
        ) : null}
        {data.results.map((i, idx) => (
          <div key={i.id}>
            {idx > 0 && <hr />}
            <Heading>{i.name}</Heading>
            <div className="mt-2">
              <Row form>
                <Col xs={false} lg="auto">
                  <VimeoVideo videoId={i.vimeo_video_id} />
                </Col>
                <Col>
                  <Content value={i.content} />
                </Col>
              </Row>
            </div>
          </div>
        ))}
      </Section>
    </div>
  );
};

export default List;
