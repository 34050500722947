import React from 'react';
import {reduce, pick, uniq} from "lodash";
import {
  Bar, BarChart,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import {useMemo} from "react";
import {Col, Row} from "reactstrap";
import numeral from 'numeral';

const defaultColors =`#3366cc
#dc3912
#ff9900
#109618
#990099
#0099c6
#dd4477
#66aa00
#b82e2e
#316395
#994499
#22aa99
#aaaa11
#6633cc
#e67300
#8b0707
#651067
#329262
#5574a6
#3b3eac
#b77322
#16d620
#b91383
#f4359e
#9c5935
#a9c413
#2a778d
#668d1c
#bea413
#0c5922
#743411`.split('\n');

const keyMapping = {
  "why_fun_activities": "The fun activities for the kids",
  "why_new_friends": "The opportunity to make new friends",
  "why_lunch": "The delicious lunch",
  "why_cost": "The chance to do something fun in the holidays that doesn’t cost money",
  "why_other": "Other",
  "true_statements_special_educational_needs": "We have children with Special Educational Needs (SEN)",
  "true_statements_child_disability": "We have children with a disability",
  "true_statements_free_school_meals": "We have children eligible for Free School Meals",
  "true_statements_looked_after": "We have children who have \"looked-after\" status",
  "true_statements_young_carers": "We have children who are young carers",
  "true_statements_none_1": "None of the above",
  "true_statements_refugees": "We are refugees/asylum seekers",
  "true_statements_lone_parent": "I am a lone parent",
  "true_statements_struggling_financially": "We are struggling financially",
  "true_statements_adult_disability": "We have children who have \"looked-after\" status",
  "true_statements_mental_health": "I/my partner has a disability",
  "true_statements_isolated": "I/my partner sometimes feel isolated",
  "true_statements_none_2": "None of the above",
}

const renderLegendText = (value, entry) => {
  return <span style={{ fontSize: '0.75rem' }}>{value}</span>;
};

const Graph = ({ data: dataProp, total, colors: colorsProp, title }) => {
  const colors = colorsProp || defaultColors;

  const data = useMemo(() => {
    const ret = reduce(dataProp, (acc, value, key) => {

      const percentage = (value / total) || 0;
      let label = keyMapping[key] + `: ${value}`;
      label = percentage ? label + ` (${numeral(percentage).format('0[.][0]%')})` : label;
      acc[label] = value;
      acc.labels.push(label);
      return acc;
    }, {
      labels: [],
      name: "",
      total: 0
    });

    ret.labels = uniq(ret.labels);

    return ret;
  }, [dataProp, total]);

  return (
    <div>
      <h6>
        {title}
      </h6>
      <ResponsiveContainer minHeight={450}>
        <BarChart data={[data]}>
          <YAxis allowDecimals={false} />
          <XAxis dataKey="name" />
          {data.labels.map((i, index) => (
            <Bar key={`bar-${index}`} dataKey={i} fill={colors[index % colors.length]} />
          ))}
          <Legend layout="vertical" formatter={renderLegendText} height={200} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export const RegistrationStatementsGraph = ({
                                 data: dataProp,
                                 colors: colorsProp,
                               }) => {
  const { total } = dataProp;
  const [keyAttractions, childNeeds, familyNeeds] = useMemo(() => {
    return [
      pick(dataProp, [
        "why_fun_activities",
        "why_new_friends",
        "why_lunch",
        "why_cost",
        "why_other"
      ]),
      pick(dataProp, [
        "true_statements_special_educational_needs",
        "true_statements_child_disability",
        "true_statements_free_school_meals",
        "true_statements_looked_after",
        "true_statements_young_carers",
        "true_statements_none_1"
      ]),
      pick(dataProp, [
        "true_statements_refugees",
        "true_statements_lone_parent",
        "true_statements_struggling_financially",
        "true_statements_adult_disability",
        "true_statements_mental_health",
        "true_statements_isolated",
        "true_statements_none_2"
      ])
    ]
  }, [dataProp]);

  return (
    <div>
      <h5 className="tw-text-left mb-4">
        Attendance Motivation Insights{' '}
        <span className="text-muted">(total: {dataProp.total})</span>
      </h5>
      <Row form className="tw-gap-y-4">
        <Col>
          <Graph title="Key Attractions for Attendance" total={total} data={keyAttractions} />
        </Col>
        <Col>
          <Graph title="Child Needs Indicated" total={total} data={childNeeds} />
        </Col>
        <Col>
          <Graph title="Family Needs Indicated" total={total} data={familyNeeds} />
        </Col>
      </Row>
    </div>
  );
};