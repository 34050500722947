import React from 'react';
import {useQuery} from "react-query";
import {useClient} from "../../hooks";
import {FormProvider, useForm} from "react-hook-form";
import { map } from 'lodash'
import {FormGroup, Input, Radio, SubmitButton} from "../../@coherent/react-hook-form";
import {Alert} from "reactstrap";
import {ethnicitiesUrl, otherLabels, otherLabelsMapping, preferNotToSay} from "./constants";

export const EthnicityCaptureForm = ({ onSubmit: onSubmitProp }) => {
  const client = useClient();
  const { data: ethnicities } = useQuery([ethnicitiesUrl], () => client.get(ethnicitiesUrl).get('data'));
  const form = useForm({
    defaultValues: {
      ethnicity: '',
      other_detail: '',
      other_group: '',
      prefer_not_to_say: false
    },
    shouldUnregister: false
  });
  const { watch, setValue, errors, register, handleSubmit } = form;

  async function onSubmit({ ethnicity, ...rest }) {
    const isPreferNotToSay = ethnicity === preferNotToSay

    await onSubmitProp({
      ...rest,
      ethnicity: !isPreferNotToSay && !otherLabels.includes(ethnicity) ? ethnicity : '',
      prefer_not_to_say: isPreferNotToSay,
    })
  }

  const ethnicity = watch('ethnicity');

  register('ethnicity', {
    required: true
  })

  return (
    <FormProvider {...form}>
      {map(ethnicities, (values, group) => (
        <div key={group} className="mb-4">
          <div className="tw-font-medium">{group}</div>
          {values.map(i => (
            <Radio name="ethnicity" value={i} label={i} id={i} />
          ))}
          <Radio name="ethnicity"
                 value={otherLabelsMapping[group]}
                 label={otherLabelsMapping[group]}
                 id={otherLabelsMapping[group]}
                 onChange={e => {
                   setValue('other_group', group);
                   setValue('other_detail', '');
                   setValue(e.target.name, e.target.value)
                 }}
          />
          {ethnicity === otherLabelsMapping[group] && (
            <FormGroup name="other_detail" required label="Please provide details" className="mt-2">
              <Input className="form-control" defaultValue="" name="other_detail" rules={{
                required: true
              }} />
            </FormGroup>
          )}
        </div>
      ))}
      <div className="tw-font-medium">
        <Radio name="ethnicity" value={preferNotToSay} label={preferNotToSay} />
      </div>

      {errors.ethnicity ? (
        <Alert color="danger" className="mt-2">
          An ethnicity or "Prefer not to say" selection is required
        </Alert>
      ) : null}
      <div className="tw-flex tw-justify-end">
        <SubmitButton onClick={handleSubmit(onSubmit)}>
          Confirm
        </SubmitButton>
      </div>
    </FormProvider>
  )
}