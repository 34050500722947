import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Checkbox, FormGroup} from "../../@coherent/react-hook-form";
import {CheckboxOption} from "./CheckboxOption";
import {TextQuestion} from "../applications/components/TextQuestion";
import {useFormContext, useWatch} from "react-hook-form";

export const RegistrationReasons = () => {
  const { t } = useTranslation();
  const { register, setValue, formState, trigger } = useFormContext();
  const { submitCount, errors } = formState;
  const values = useWatch({});
  const {
    why_other,
    true_statements_none_1,
    true_statements_none_2
  } = values;

  const why = [
    'why_fun_activities',
    'why_new_friends',
    'why_lunch',
    'why_cost',
    'why_other'
  ];

  const true_1 = [
    'true_statements_special_educational_needs',
    'true_statements_child_disability',
    'true_statements_free_school_meals',
    'true_statements_looked_after',
    'true_statements_young_carers',
    'true_statements_none_1'
  ];

  const true_2 = [
    'true_statements_refugees',
    'true_statements_lone_parent',
    'true_statements_struggling_financially',
    'true_statements_adult_disability',
    'true_statements_mental_health',
    'true_statements_isolated',
    'true_statements_none_2'
  ]

  useEffect(() => {
    if (submitCount) {
      trigger('_why');
    }
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...why.map(i => values[i]),
    trigger,
    submitCount
  ]);

  useEffect(() => {
    if (submitCount) {
      trigger('_true_1');
    }
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...true_1.map(i => values[i]),
    trigger,
    submitCount
  ]);

  useEffect(() => {
    if (submitCount) {
      trigger('_true_2');
    }
  },
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ...true_2.map(i => values[i]),
    trigger,
    submitCount
  ])

  register('_why', {
    validate: () => {
      return why.some(i => values[i]);
    }
  });

  register('_true_1', {
    validate: () => true_1.some(i => values[i])
  });

  register('_true_2', {
    validate: () => true_2.some(i => values[i])
  });

  function handleNone(e) {
    const { name, checked } = e.target;

    if (name === 'true_statements_none_1') {
      for (const i of true_1) {
        setValue(i, false);
      }
    } else if (name === 'true_statements_none_2') {
      for (const i of true_2) {
        setValue(i, false);
      }
    }

    setValue(name, checked);
  }

  return (
    <section>
      <FormGroup required help={
        errors._why ? 'Please select at least 1 option' : ''
      } label={t('Why did you decide to visit Make Lunch today (tick at least 1 option)')} name="_why">
        <FormGroup htmlFor="why_fun_activities" className="mb-0" check label={t('The fun activities for the kids')}>
          <Checkbox id="why_fun_activities" name="why_fun_activities" className="form-check-input" />
        </FormGroup>
        <FormGroup htmlFor="why_new_friends" className="mb-0" check label={t('The opportunity to make new friends')}>
          <Checkbox id="why_new_friends" name="why_new_friends" className="form-check-input" />
        </FormGroup>
        <FormGroup htmlFor="why_lunch" className="mb-0" check label={t('The delicious lunch')}>
          <Checkbox id="why_lunch" name="why_lunch" className="form-check-input" />
        </FormGroup>
        <FormGroup htmlFor="why_cost" className="mb-0" check label={t('The chance to do something fun in the holidays that doesn’t cost money')}>
          <Checkbox id="why_cost" name="why_cost" className="form-check-input" />
        </FormGroup>
        <FormGroup htmlFor="why_other" className="mb-0" check label={t('Other')}>
          <Checkbox id="why_other" name="why_other" className="form-check-input" />
        </FormGroup>
        {why_other ? (
          <TextQuestion
            name="why_other_detail"
            label={t("Please provide details")}
            required
          />
        ) : null}
      </FormGroup>
      <FormGroup required help={
        errors._true_1 ? 'Please select at least 1 option' : ''
      } name="_true_1" label={
        t('Are any of the following statements true for your children? (tick at least 1 option)')
      }>
        <CheckboxOption disabled={!!true_statements_none_1} name="true_statements_special_educational_needs" label={t('We have children with Special Educational Needs (SEN)')} />
        <CheckboxOption disabled={!!true_statements_none_1} name="true_statements_child_disability" label={t('We have children with a disability')} />
        <CheckboxOption disabled={!!true_statements_none_1} name="true_statements_free_school_meals" label={t('We have children eligible for Free School Meals')} />
        <CheckboxOption disabled={!!true_statements_none_1} name="true_statements_looked_after" label={t('We have children who have "looked-after" status')} />
        <CheckboxOption disabled={!!true_statements_none_1} name="true_statements_young_carers" label={t('We have children who are young carers')} />
        <CheckboxOption onChange={handleNone} name="true_statements_none_1" label={t('None of the above')} />
      </FormGroup>
      <FormGroup required help={
        errors._true_2 ? 'Please select at least 1 option' : ''
      } name="_true_2" label={
        t('Are any of the following statements true for you and/or your partner? (tick at least 1 option)')
      }>
        <CheckboxOption disabled={!!true_statements_none_2} name="true_statements_refugees" label={t('We are refugees/asylum seekers')} />
        <CheckboxOption disabled={!!true_statements_none_2} name="true_statements_lone_parent" label={t('I am a lone parent')} />
        <CheckboxOption disabled={!!true_statements_none_2} name="true_statements_struggling_financially" label={t('We are struggling financially')} />
        <CheckboxOption disabled={!!true_statements_none_2} name="true_statements_adult_disability" label={t('We have children who have "looked-after" status')} />
        <CheckboxOption disabled={!!true_statements_none_2} name="true_statements_mental_health" label={t('I/my partner has a disability')} />
        <CheckboxOption disabled={!!true_statements_none_2} name="true_statements_isolated" label={t('I/my partner sometimes feel isolated')} />
        <CheckboxOption onChange={handleNone} name="true_statements_none_2" label={t('None of the above')} />
      </FormGroup>
    </section>
  )
}