import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useMemo } from "react";
import { Col, Row } from "reactstrap";
import { QUESTIONS } from "./constants";
import { map } from "lodash";

const defaultColors = `#3366cc
#dc3912
#ff9900
#109618`.split("\n");

const ratingsColors = `#109618
#66aa00
#ff9900
#dc3912
#8b0707`.split("\n");

const Graph = ({ data, colors: colorsProp }) => {
  const colors = colorsProp || defaultColors;

  return (
    <div>
      <ResponsiveContainer minHeight={310}>
        <PieChart>
          <Pie data={data} label dataKey="count">
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Legend />
          <Tooltip formatter={() => ""} separator="" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export const ParticipantFeedbackGraph = ({ participantFeedback }) => {
  const { num_results } = participantFeedback;

  const graphs = useMemo(() => {
    const { result, fields } = participantFeedback;

    return map(result, (values, questionKey) => {
      return {
        title: QUESTIONS[questionKey],
        key: questionKey,
        data: map(values, (count, optionKey) => ({
          name: `${fields[questionKey][optionKey]}: ${count}`,
          count,
        })),
      };
    });
  }, [participantFeedback]);

  return (
    <div>
      <h5 className="tw-text-left">
        Participant Feedback{" "}
        <span className="text-muted">(total: {num_results})</span>
      </h5>
      <Row form className="tw-gap-y-4">
        {graphs
          .filter((i) => i.key !== "gender" && i.key !== "how_long")
          .map((i, idx) => (
            <Col key={i.key} lg={6}>
              <h6>{i.title}</h6>
              <Graph data={i.data} colors={ratingsColors} />
            </Col>
          ))}
      </Row>
      <hr />
      <Row form className="tw-gap-y-4">
        {graphs
          .filter((i) => i.key === "gender" || i.key === "how_long")
          .map((i, idx) => (
            <Col key={i.key} lg={6}>
              <h6>{i.title}</h6>
              <Graph data={i.data} colors={defaultColors} />
            </Col>
          ))}
      </Row>
    </div>
  );
};
