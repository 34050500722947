import { useContext } from "react";

import { CommonContext } from "contexts/CommonContext";

export const useClient = () => {
  const { client } = useContext(CommonContext);
  return client;
};

export default useClient;
