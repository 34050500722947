import React, { createContext, useMemo, useState } from "react";
import { ApiClient, Auth } from "services";
import { useUserContext } from "hooks";

export const CommonContext = createContext();

export const CommonContextProvider = ({ children }) => {
  const { user } = useUserContext();
  const [key, setKey] = useState(false);

  function reload() {
    setKey((key) => !key);
  }

  const value = useMemo(
    () => ({
      client: ApiClient(),
      user,
      ApiClient,
      Auth,
      reload,
    }),
    [user]
  );

  return (
    <CommonContext.Provider value={value}>
      <React.Fragment key={key}>{children}</React.Fragment>
    </CommonContext.Provider>
  );
};

export default CommonContext;
