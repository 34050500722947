import React, { useState, useEffect } from "react";
import { useOnMount, usePopover } from "hooks";
import { Formik, Field } from "formik";
import { Page, SubmitButton, FormGroup } from "components/common";
import { Row, Col, Button, PopoverBody } from "reactstrap";
import Tree from "rc-tree";
import { NotificationManager } from "react-notifications";
import { Route } from "react-router-dom";
import List from "./List";
import Edit from "./Edit";
import { without } from "lodash";
import Checkbox from "../../../components/common/Checkbox";
import useUserContext from "../../../hooks/useUserContext";

const DeleteDirectory = ({ onSubmit, directory }) => {
  const { Popover, id, isOpen, setIsOpen } = usePopover();

  return (
    <>
      <Button
        disabled={!directory}
        className="text-danger"
        color="link"
        size="sm"
        onClick={() => setIsOpen(true)}
        id={id}
      >
        <span className="fa fa-trash mr-1" /> Delete
      </Button>
      {isOpen && (
        <Formik
          initialValues={{}}
          onSubmit={async (...args) => {
            await onSubmit(...args);
            setIsOpen(false);
          }}
        >
          {() => (
            <Popover target={id}>
              <PopoverBody>
                <p>
                  Are you sure you want to delete the {directory} directory?
                </p>
                <div className="tw-flex tw-justify-end">
                  <SubmitButton color="danger">Confirm</SubmitButton>
                </div>
              </PopoverBody>
            </Popover>
          )}
        </Formik>
      )}
    </>
  );
};

const EditDirectory = ({ onSubmit, initialValues }) => {
  const { Popover, id, isOpen, setIsOpen } = usePopover();
  const { isTLG } = useUserContext();

  return (
    <>
      <Button
        disabled={!initialValues.name}
        color="link"
        size="sm"
        onClick={() => setIsOpen(true)}
        id={id}
      >
        <span className="fa fa-edit mr-1" /> Edit
      </Button>
      {isOpen && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (...args) => {
            await onSubmit(...args);
            setIsOpen(false);
          }}
        >
          {() => (
            <Popover target={id}>
              <PopoverBody>
                <p className="text-muted form-text">Edit directory</p>
                <FormGroup name="name" label="Directory name">
                  <Field
                    name="name"
                    validate={(v) => !v}
                    className="form-control"
                  />
                </FormGroup>
                {isTLG && (
                  <FormGroup check label="Boxes of Hope directory">
                    <Field component={Checkbox} name="is_boxes_of_hope" className="form-check-input" />
                  </FormGroup>
                )}

                <div className="tw-flex tw-justify-end">
                  <SubmitButton>Confirm</SubmitButton>
                </div>
              </PopoverBody>
            </Popover>
          )}
        </Formik>
      )}
    </>
  );
};

const CreateDirectory = ({ onSubmit, parent }) => {
  const { Popover, id, isOpen, setIsOpen } = usePopover();
  const { isTLG } = useUserContext();

  return (
    <>
      <Button color="link" size="sm" onClick={() => setIsOpen(true)} id={id}>
        <span className="fa fa-plus mr-1" /> Create
      </Button>
      {isOpen && (
        <Formik
          initialValues={{ name: "" }}
          onSubmit={(...args) => {
            onSubmit(...args);
            setIsOpen(false);
          }}
        >
          {() => (
            <Popover target={id}>
              <PopoverBody>
                <p className="text-muted form-text">
                  Create directory in {parent || "root"}
                </p>
                <FormGroup name="name" label="Directory name">
                  <Field
                    name="name"
                    validate={(v) => !v}
                    className="form-control"
                  />
                </FormGroup>
                {isTLG && (
                  <FormGroup check label="Boxes of Hope directory">
                    <Field component={Checkbox} name="is_boxes_of_hope" className="form-check-input" />
                  </FormGroup>
                )}
                <div className="tw-flex tw-justify-end">
                  <SubmitButton>Create</SubmitButton>
                </div>
              </PopoverBody>
            </Popover>
          )}
        </Formik>
      )}
    </>
  );
};

export const KitchenSink = ({ client, formik, history, match, url='resource_directories', singular, plural = 'Resources', editComponent: EditComponent = Edit }) => {
  const { values, resetForm } = formik;
  const [directories, setDirectories] = useState();
  const [selectedDirectory, setSelectedDirectory] = useState();
  const [expandedKeys, setExpandedKeys] = useState([]);

  async function load() {
    const traverse = (i) => {
      const item = {
        key: i.id,
        is_boxes_of_hope: i.is_boxes_of_hope,
        title: i.name,
        unpublished: i.unpublished,
        children: i.children.map(traverse),
        isLeaf: false,
      };

      if (selectedDirectory && selectedDirectory.key === item.key) {
        setSelectedDirectory(item);
      }

      return item;
    };

    const values = await client.get(url).get("data").map(traverse);

    const directories = values.reduce((acc, i) => {
      const process = (parents, j) => {
        const items = [...parents, j];
        const item = {
          value: j.key,
          label: items.map((k) => k.title).join(" > "),
        };

        acc.push(item);

        j.children.forEach((k) => process(items, k));
      };

      process([], i);
      return acc;
    }, []);

    setDirectories(directories);

    resetForm({ values });
  }

  useOnMount(load);

  useEffect(() => {
    if (!selectedDirectory) {
      history.replace(match.url);
    } else {
      history.replace(`${match.url}/${selectedDirectory.key}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDirectory]);

  async function onCreateDirectory(x, { setSubmitting }) {
    try {
      await client.post(url, {
        ...x,
        parent: selectedDirectory ? selectedDirectory.key : null,
      });
      await load();
      NotificationManager.success("Successfully created");
    } finally {
      setSubmitting(false);
    }
  }

  async function onDeleteDirectory(x, { setSubmitting }) {
    try {
      await client.delete(`${url}/${selectedDirectory.key}`);
      setSelectedDirectory(null);
      await load();
      NotificationManager.success("Successful");
    } catch (ex) {
      NotificationManager.error(
        "Failed to delete, make sure no files/subdirectories exist"
      );
    } finally {
      setSubmitting(false);
    }
  }

  async function onRenameDirectory(x) {
    await client.patch(`${url}/${selectedDirectory.key}`, x);
    await load();
    NotificationManager.success("Successful");
  }

  if (!values) return null;

  return (
    <div className="container">
      <Row form>
        <Col xs="auto">
          <Page title={plural}>
            <CreateDirectory
              parent={selectedDirectory ? selectedDirectory.title : ""}
              onSubmit={onCreateDirectory}
            />
            <EditDirectory
              initialValues={{
                name: selectedDirectory ? selectedDirectory.title : "",
                is_boxes_of_hope: selectedDirectory ? selectedDirectory.is_boxes_of_hope : false
              }}
              onSubmit={onRenameDirectory}
            />
            <DeleteDirectory
              directory={selectedDirectory ? selectedDirectory.title : ""}
              onSubmit={onDeleteDirectory}
            />

            <Tree
              autoExpandParent={true}
              showIcon={false}
              expandedKeys={expandedKeys}
              treeData={values}
              onSelect={(_, event) => {
                const { eventKey: key, title, unpublished, is_boxes_of_hope, ...rest } = event.node.props;
                console.log(rest);
                if (!event.selected) {
                  setSelectedDirectory(null);
                  setExpandedKeys((v) => without(v, key));
                } else if (event.selected) {
                  setSelectedDirectory({
                    key: parseInt(key, 10),
                    title,
                    unpublished,
                    is_boxes_of_hope
                  });
                  setExpandedKeys((v) => [...v, key]);
                }
              }}
            />
          </Page>
        </Col>
        <Col>
          {selectedDirectory ? (
            <>
              <Route
                exact
                path={`${match.url}/${selectedDirectory.key}`}
                render={(props) => (
                  <List
                    {...props}
                    directories={directories}
                    singular={singular}
                    plural={plural}
                    url={url}
                    directory={selectedDirectory}
                    onUnpublishedChanged={async (unpublished) => {
                      await client.patch(`${url}/${selectedDirectory.key}`, {
                        unpublished,
                      });
                      await load();
                    }}
                  />
                )}
              />
              <Route
                exact
                path={`${match.url}/${selectedDirectory.key}/:id`}
                render={(props) => (
                  <EditComponent
                    directoryName={selectedDirectory.title}
                    directoryId={selectedDirectory.key}
                    {...props}
                  />
                )}
              />
            </>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default (props) => {
  return (
    <Formik>{(formik) => <KitchenSink {...props} formik={formik} />}</Formik>
  );
};
