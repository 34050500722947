import React, { Children, useEffect, useState } from "react";
import { Page, Checkbox, FormGroup } from "components/common";
import { Field, Formik } from "formik";
import { useFormikContext, useOnMount, useOnUpdate } from "hooks";
import { keys } from "lodash";
import classNames from "classnames";
import { Alert } from "reactstrap";
import { useUserContext } from "../hooks";

const Title = ({ children, complete, inProgress }) => {
  return (
    <h2
      className={classNames("tw-font-medium tw-text-lg tw-mb-2", {
        "text-success": complete,
        "text-danger": !complete && !inProgress,
        "text-warning": inProgress,
      })}
    >
      {children}
      {complete ? <span className="fa fa-check-circle tw-ml-2" /> : null}
    </h2>
  );
};

const Item = ({ children, form, field }) => {
  return (
    <li>
      <FormGroup label={children} check className="tw-mb-1">
        <Checkbox
          form={form}
          field={field}
          className="form-check-input"
          onChange={(e) => {
            form.setFieldValue(field.name, e.target.checked ? true : undefined);
          }}
        />
      </FormGroup>
    </li>
  );
};

const Section = ({ title, children, field, form }) => {
  const { value, name } = field;
  const numChildren = Children.count(children);
  const numKeys = keys(value || {}).length;
  const complete = numChildren === numKeys;
  const inProgress = !complete && numKeys > 0;
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(name + "_complete", complete, {
      shouldValidate: true
    });
  }, [complete, name, setFieldValue]);

  return (
    <div>
      <Title complete={complete} inProgress={inProgress}>
        {title}
      </Title>
      <ul className="tw-list-none tw-pl-2">{children}</ul>
    </div>
  );
};

export const ReadySteadyCookFields = () => {
  return (
    <>
      <Field name="ready" component={Section} title="READY">
        <Field name="ready.coordinator_appointed" component={Item}>
          Coordinator appointed and trained
        </Field>
        <Field name="ready.core_team_applications" component={Item}>
          Core team applications and references completed through TLG
        </Field>
        <Field name="ready.public_liability" component={Item}>
          Public Liability Insurance in place
        </Field>
        <Field name="ready.safeguarding" component={Item}>
          Church safeguarding policy in place
        </Field>
        <Field name="ready.food_business" component={Item}>
          Register as a Food Business
        </Field>
        <Field name="ready.business_pack" component={Item}>
          Download, print and use Safer Food, Better Business
        </Field>
        <Field name="ready.risk_assessment" component={Item}>
          Risk Assessment Completed{" "}
        </Field>
      </Field>
      <Field name="steady" component={Section} title="STEADY">
        <Field name="steady.church_application" component={Item}>
          Church application forms in for all Lunch Makers
        </Field>
        <Field name="steady.references" component={Item}>
          2 References for all Lunch Makers
        </Field>
        <Field name="steady.dbs_pvg_access" component={Item}>
          DBS/PVG/Access NI in place for all volunteers
        </Field>
        <Field name="steady.core_team_training" component={Item}>
          All Core team have completed TLG Training
        </Field>
        <Field name="steady.basic_safeguarding_training" component={Item}>
          All Volunteer Lunch makers have completed basic Safeguarding Training
        </Field>
        <Field name="steady.kitchen_team" component={Item}>
          Kitchen team trained in basic food handling, hygiene and allergen
          awareness
        </Field>
      </Field>
      <Field name="cook" component={Section} title="COOK">
        <Field name="cook.fire_safety" component={Item}>
          All volunteers aware of Fire safety procedures at every session
        </Field>
        <Field name="cook.child_team_ratio" component={Item}>
          Core team aware of and able to implement appropriate child/team ratio
        </Field>
        <Field name="cook.food_hygiene" component={Item}>
          Core team aware of need for a Qualified Food & Hygiene Level 2
          volunteer to be present at every session
        </Field>
        <Field name="cook.first_aider" component={Item}>
          Core team aware of need for a Qualified First Aider to be present at
          every session
        </Field>
        <Field name="cook.core_team" component={Item}>
          Core team aware that at least one Core Team member should be present
          at every session
        </Field>
      </Field>
    </>
  )
}

const Form = ({ client }) => {
  const { values } = useFormikContext();
  const { setUser } = useUserContext();
  const complete =
    values.ready_complete && values.steady_complete && values.cook_complete;

  useOnUpdate(async () => {
    await client.post("me", {
      ready_steady_cook: values,
    });
  }, [values]);

  useOnUpdate(() => {
    setUser((v) => ({
      ...v,
      ready_steady_cook: values,
    }));
  }, [complete, setUser]);

  return (
    <Page title="Ready, Steady, Cook" className="tw-max-w-3xl">
      <p className="form-text tw-text-sm text-muted">
        Thank you for partnering with TLG! Before you run your first session, be
        sure to indicate you have all of the compliance and safeguarding
        policies in place to ensure a safe and healthy Lunch Club. When your
        Ready Steady Cook icons have turned Green you are ready to run your
        first session of Make Lunch!
      </p>
      <ReadySteadyCookFields />
      {complete ? (
        <Alert color="success">
          <strong>Congratulations!</strong> You are ready to run your first
          session of Make Lunch!
        </Alert>
      ) : null}
    </Page>
  );
};

export const ReadySteadyCook = ({ client }) => {
  const [initialValues, setInitialValues] = useState();

  useOnMount(async () => {
    const { ready_steady_cook } = await client.get("me").get("data");
    setInitialValues(ready_steady_cook);
  });

  if (!initialValues) return null;

  return (
    <Formik initialValues={initialValues}>
      <Form client={client} />
    </Formik>
  );
};

export default ReadySteadyCook;
