import { useQuery } from "react-query";
import { useClient } from "../../../../hooks";

export const useApplicationFormQuery = (url) => {
  const client = useClient();

  return useQuery(
    [url],
    () => {
      return client.get(url).get("data");
    },
    {
      useErrorBoundary: false,
    }
  );
};
