import React, { useState } from "react";
import { get } from "lodash";
import { useFormik, FormikProvider, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import { Page, FormGroup, SubmitButton } from "components/common";

const PasswordReset = ({ client, match }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  async function onSubmit(x) {
    setError("");
    setSuccess(false);
    try {
      await client.post("/password_reset", {
        password: x.password,
        token: match.params.token,
      });
      setSuccess(true);
    } catch (ex) {
      switch (get(ex, "response.data.code")) {
        case "PasswordFailsValidation":
          setError(
            "Password must be at least 8 characters and be a mix of letters and numbers"
          );
          break;
        case "InvalidToken":
          setError("This token has already been used to change the password");
          break;
        case "TokenExpired":
          setError("The password change token has expired");
          break;
        default:
          throw ex;
      }
    }
  }

  const formik = useFormik({
    onSubmit,
    validate: ({ password, confirm_new_password }) => {
      const errors = {};

      if (password !== confirm_new_password) {
        errors.password_mismatch = true;
      }

      return errors;
    },
    initialValues: {
      password: "",
      confirm_new_password: "",
    },
  });
  const { touched, submitCount, errors } = formik;

  const passwordMismatch =
    errors.password_mismatch &&
    (submitCount || (touched.password && touched.confirm_new_password));

  return (
    <Page title="Set a new password" className="tw-max-w-xl">
      {success ? (
        <Alert color="success">
          Password successfully changed. You may now{" "}
          <Link to="/login">sign in</Link>.
        </Alert>
      ) : (
        <FormikProvider value={formik}>
          <Form>
            <FormGroup name="password" label="New password">
              <Field
                name="password"
                type="password"
                className="form-control"
                validate={(v) => !v}
              />
            </FormGroup>
            <FormGroup name="confirm_new_password" label="Confirm password">
              <Field
                name="confirm_new_password"
                type="password"
                className="form-control"
                validate={(v) => !v}
              />
            </FormGroup>
            {passwordMismatch ? (
              <Alert color="warning">Passwords do not match</Alert>
            ) : null}
            <div className="tw-flex tw-justify-end">
              <SubmitButton>Reset Password</SubmitButton>
            </div>
            {error && (
              <Alert color="warning" className="tw-mt-4">
                {error}
              </Alert>
            )}
          </Form>
        </FormikProvider>
      )}
    </Page>
  );
};

export default PasswordReset;
