import {
  InputGroup as ReactstrapInputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import React from "react";
import { usePopover } from "hooks";

const PopoverInput = ({ children, popover }) => {
  const { Popover, id } = usePopover();

  return (
    <>
      <ReactstrapInputGroup>
        {children}
        <InputGroupAddon
          addonType="append"
          id={id}
          className="tw-cursor-pointer"
        >
          <InputGroupText>
            <span className="fa fa-question text-primary" />
          </InputGroupText>
        </InputGroupAddon>
      </ReactstrapInputGroup>
      <Popover trigger="hover" placement="bottom-end" flip={false}>
        <div className="tw-p-2">{popover}</div>
      </Popover>
    </>
  );
};

export default PopoverInput;
