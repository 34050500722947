import React, { useState, useEffect, createContext, useMemo } from "react";
import { ApiClient, Auth } from "services";
import { NotificationManager } from "react-notifications";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [loggedInThisSession, setLoggedInThisSession] = useState(false);

  async function reload() {
    if (!Auth.decodeToken()) {
      setLoading(false);
      setUser(null);
      return;
    }

    setLoading(true);
    const client = ApiClient({ redirectOnUnauthorized: false });
    try {
      const user = await client.get("me").get("data");
      setUser(user);
      setLoading(false);
    } catch (ex) {
      const { status } = client.parseError(ex);

      if (status === 401) {
        setUser(null);
        setLoading(false);
      } else {
        NotificationManager.error(
          "An error occurred, please try refreshing the page"
        );
      }
    }
  }

  useEffect(() => void reload(), []);

  const value = useMemo(
    () => {
      const result = {
        user,
        loggedInThisSession,
        setLoggedInThisSession,
        loading,
        setUser,
        reload,
        isStaff: !!(user && user.is_staff),
        isPastor: !!(user && user.is_pastor),
        isBoxesOfHope: !!(user && (user.is_boxes_of_hope || user.organisation__is_boxes_of_hope)),
        allowMultipleGroups: !!(user && user.allow_multiple_groups),
      };
      const { isStaff, isPastor } = result;
      result.isPartnerships = isStaff && user.role === 'partnerships';
      result.isTLG = !!(user && !result.isBoxesOfHope);
      result.isVPSupport = isStaff && user.role === 'vp_support';
      result.isVPGeneral = isStaff && user.role === 'vp_general';
      result.isTLGStaff = isStaff && (user.role === 'tlg' || result.isPartnerships);
      result.isBoxesOfHopeStaff = isStaff && user.role === 'boxes_of_hope';
      result.isVP = !!(result.isVPSupport || result.isVPGeneral);
      result.isCoordinator = isPastor && user.user_type === 'coordinator';
      result.isCoreTeamMember = isPastor && user.user_type === 'core';
      result.isSafeGuardingLeader = isPastor && user.user_type === 'safeguarding_leader';
      return result;
    },
    [user, loading, loggedInThisSession]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
