import {useModal} from "../../../../hooks";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Notes} from "../../notes";
import React from "react";

export const NotesButton = ({numNotes, application, onChanged}) => {
  const {Modal, toggle} = useModal();

  return (
    <>
      <Modal size="lg">
        <ModalHeader toggle={toggle}>Notes</ModalHeader>
        <ModalBody>
          <Notes onChanged={onChanged} application={application}/>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
      <Button size="sm" onClick={toggle}>
        <span className="fa fa-sticky-note"/> Notes ({numNotes})
      </Button>
    </>
  );
};