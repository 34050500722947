import { useContext, useMemo } from "react";
import { __RouterContext } from "react-router";
import { parse } from "query-string";

export const useRouter = () => {
  const { location, ...rest } = useContext(__RouterContext);
  const query = useMemo(() => parse(location.search), [location.search]);

  return {
    location,
    ...rest,
    query,
  };
};

export default useRouter;
