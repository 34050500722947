import React from "react";
import {
  InputGroup as ReactstrapInputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

export const InputGroup = ({
  appendClassName,
  prependClassName,
  children,
  ...props
}) => {
  return (
    <ReactstrapInputGroup {...props}>
      {prependClassName ? (
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <span className={prependClassName} />
          </InputGroupText>
        </InputGroupAddon>
      ) : null}
      {children}
      {appendClassName ? (
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <span className={appendClassName} />
          </InputGroupText>
        </InputGroupAddon>
      ) : null}
    </ReactstrapInputGroup>
  );
};

export default InputGroup;
