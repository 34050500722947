import React from "react";
import { formatDateTime } from "../../applications/util";
import { QUESTIONS } from "../constants";
import {Table} from "reactstrap";

function SurveyFreeTextAnswerRow({ value, showOrganisation }) {
  const { created, response, organisation } = value;

  return (
    <tr>
      <td className="tw-whitespace-no-wrap" style={{ width: 1 }}>
        {formatDateTime(created)}
      </td>
      {showOrganisation ? (
        <td className="tw-whitespace-no-wrap" style={{ width: 1 }}>
          {organisation}
        </td>
      ) : null}
      <td className="tw-whitespace-pre-wrap">
        {response}
      </td>
    </tr>
  );
}

const freeTextQuestions = [
  "appreciate_most",
  "suggestions",
  "memorable_moment",
  "favourite_foods_activities_games",
  "anything_else",
];

export function SurveyFreeTextAnswers({ data, showOrganisation }) {
  return (
    <>
      {freeTextQuestions.map((freeTextQuestion) =>
        data[freeTextQuestion].length ? (
          <div key={freeTextQuestion} className="mb-4">
            <p className="mb-2">
              <strong>{QUESTIONS[freeTextQuestion]}</strong>
            </p>
            <Table bordered striped size="sm" className="tw-text-xs">
              <thead>
                <tr>
                  <th>Created</th>
                  {showOrganisation ? (
                    <th>Organisation</th>
                  ) : null}
                  <th>Response</th>
                </tr>
              </thead>
              <tbody>
                {data[freeTextQuestion].map((answer, idx) => (
                  <SurveyFreeTextAnswerRow showOrganisation={showOrganisation} value={answer} key={idx} />
                ))}
              </tbody>
            </Table>
          </div>
        ) : null,
      )}
    </>
  );
}
