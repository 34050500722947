import React from 'react';
import {Radio, SubmitButton} from "../../../../@coherent/react-hook-form";
import {FormProvider, useForm} from "react-hook-form";

export const DBSForm = ({ data, required = true, onSubmit }) => {
  const methods = useForm({
    defaultValues: data
  });
  const { handleSubmit } = methods;
  const { application__form__full_name } = data;

  return (
    <FormProvider {...methods}>
      <p>
        Please can you confirm their status by selecting one of the following options:
      </p>
      <Radio className="mb-2" name="confirmed" value="enhanced_certificate" label={(
        <>
          I can confirm that <i>{application__form__full_name}</i> has an up to date Enhanced Certificate and have been notified that check is complete and clear through our Church’s processes OR that I have seen an original copy of their Certificate (issued within the last 3 years).
        </>
      )} id="enhanced_certificate" />
      <Radio rules={{
        rules: {
          required
        }
      }} name="confirmed" value="dbs_update_service" label={(
        <>
          I can confirm that <i>{application__form__full_name}</i> is subscribed to the DBS Update Service and I have checked that their Certificate is clear.
        </>
      )} id="dbs_update_service" />
      <div className="tw-flex tw-justify-end">
        <SubmitButton onClick={handleSubmit(onSubmit)}>
          Confirm
        </SubmitButton>
      </div>
    </FormProvider>
  )
}