import React from "react";
import { useTable } from "hooks";
import { Page } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

export const GoldenNumbersManualEntryList = ({ match }) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
  } = useTable({
    match,
    url: `golden_numbers_manual_entries`,
    resource: "Golden Number Manual Entry",
  });

  return (
    <Page title="Golden Number Manual Entries">
      <TableSearch className="fa-min" />
      <div className="tw-flex tw-justify-between tw-mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="created">Created</th>
              <th sortBy="organisation__organisation_name">Club</th>
              <th sortBy="year">Year</th>
              <th sortBy="holiday">Holiday</th>
              <th sortBy="date">Dashboard date</th>
              <th sortBy="created_by__email">Created by</th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>
                    {moment(i.created).format("DD/MM/YYYY  HH:mm")}
                  </Link>
                </td>
                <td>
                  <Link to={`/organisations/${i.organisation}`}>
                    {i.organisation__organisation_name}
                  </Link>
                </td>
                <td>{i.year}</td>
                <td>{i.holiday}</td>
                <td>{i.date ? moment(i.date).format('DD/MM/YYYY') : ''}</td>
                <td>{i.created_by__email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};
