import React, { useState } from "react";
import { useTable, useOnMount, useRouter } from "hooks";
import { Page, FormGroup } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import StartSession from "components/StartParcelProvisionSession";
import Select from "react-select";

export const List = ({ match, client }) => {
  const [holiday, setHoliday] = useState();
  const { query } = useRouter();
  const [registered, setRegistered] = useState();
  const [holidays, setHolidays] = useState();
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableDataFetch,
    BulkDeleteButton,
    data,
    refreshData,
    setFilters,
    filters,
  } = useTable({
    match,
    resource: "Parcel Provision",
    url: "parcel_provisions",
    filters: {
      registered: query.registered || undefined,
    },
  });

  useOnMount(async () => {
    if (query.registered) {
      const registered = await client
        .get(`registered/${query.registered}`)
        .get("data");
      setRegistered(registered);
    }
    const holidays = await client.get("parcel_provisions/holidays").get("data");
    setHolidays(holidays);
  });

  if (!holidays) return null;

  return (
    <Page
      title={
        registered
          ? `Parcel Provision for ${registered.name}`
          : "Parcel Provision"
      }
    >
      <div className="tw-flex tw-mb-4">
        <StartSession onBulkCreated={refreshData}>
          Create New Parcel Provision(s)
        </StartSession>
        <div className="tw-mx-auto" />
        <BulkDeleteButton />
      </div>
      <FormGroup label="Months">
        <Select
          isClearable
          options={holidays}
          onChange={(v) => {
            setHoliday(v);
            setFilters({
              ...filters,
              holiday: v ? v.value : undefined,
            });
          }}
          value={holiday}
          getValueLabel={(v) => v.value}
          getOptionLabel={(v) => v.label}
        />
      </FormGroup>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="date">Delivery Date</th>
              <th sortBy="holiday">Holiday</th>
              <th className="text-right">Children</th>
              <th className="text-right">Adults</th>
              <th className="text-right">Total</th>
              <th className="text-right">Total Family Meals Provided</th>
              <th className="text-right">Total Parcels</th>
              <th className="text-right">Total Portions</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>
                    {moment(i.date).format("DD/MM/YY")}
                  </Link>
                </td>
                <td>{i.holiday}</td>
                <td className="text-right">{i.total_children}</td>
                <td className="text-right">{i.total_adults}</td>
                <td className="text-right">
                  {i.total_children + i.total_adults}
                </td>
                <td className="text-right">{i.total_meals}</td>
                <td className="text-right">{i.total_parcels}</td>
                <td className="text-right">{i.total_portions}</td>
                <td className="text-right">
                  <StartSession
                    clone={i.id}
                    size="sm"
                    className="m-0"
                    onBulkCreated={refreshData}
                  >
                    Clone Parcel Provision
                  </StartSession>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
