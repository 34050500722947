import React from "react";
import { useQuery } from "react-query";
import { useClient } from "hooks";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useRouter } from "hooks";
import { Page } from "components/common";
import {
  ExistingEntryCheck,
} from "../components";
import {
  DatePicker,
  FormGroup,
  Select,
  SubmitButton,
} from "@coherent/react-hook-form";

export const GoldenNumbersManualEntryEdit = () => {
  const client = useClient();
  const { match, history } = useRouter();
  const { id } = match.params;

  const form = useForm({
    mode: "onBlur",
    criteriaMode: "all",
  });
  const url = "golden_numbers_manual_entries";

  const { reset, register, handleSubmit } = form;

  const { data: organisations } = useQuery("organisations", () =>
    client.get("organisations").get("data")
  );

  const { data: holidays } = useQuery("holiday_allocation/holidays", (url) =>
    client
      .get(url)
      .get("data")
      .map((i) => ({
        label: i,
        value: i,
      }))
  );

  const { refetch, data } = useQuery(
    [url, id],
    async (url, id) => {
      return await client.get(`${url}/${id}`).get("data");
    },
    {
      enabled: organisations && holidays,
      onSuccess: (values) => reset(values),
    }
  );

  async function onSubmit(x) {
    const { id: _id } = await client.post(url, x).get("data");
    NotificationManager.success("Manual entry successfully saved");

    if (id === `${_id}`) {
      await refetch({ throwOnError: true });
    } else {
      history.replace(match.path.replace(":id", _id));
    }
  }

  if (!data) return null;

  return (
    <Page title="Golden Numbers Manual Entry">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label="Year" name="year">
            <input
              type="number"
              className="form-control"
              ref={register({
                required: true,
                min: 2015,
                valueAsNumber: true,
              })}
              name="year"
            />
          </FormGroup>
          <FormGroup label="Club" name="organisation" required>
            <Select
              name="organisation"
              rules={{ required: true }}
              options={organisations}
              labelKey="organisation_name"
              valueKey="id"
              simpleValue
            />
          </FormGroup>
          <FormGroup label="Holiday" name="holiday" required>
            <Select
              name="holiday"
              rules={{ required: true }}
              options={holidays}
              simpleValue
            />
          </FormGroup>
          <FormGroup label="Dashboard date" name="date" help={
            'This date is used to determine if it should be included in the Dashboard custom date range. Please select a single date that is representative of the holiday period in which these numbers were recorded.'
          }>
            <DatePicker
              name="date"
              className="form-control"
            />
          </FormGroup>
          <ExistingEntryCheck
            url="golden_numbers_manual_entries"
            id={Number(id)}
          />
          <FormGroup
            label="Number of sessions"
            name="number_of_sessions"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="number_of_sessions"
            />
          </FormGroup>
          <FormGroup
            label="Child Meals"
            name="eligible_child_meals"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="eligible_child_meals"
            />
          </FormGroup>
          <FormGroup
            label="Unique Children"
            name="unique_eligible_children"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="unique_eligible_children"
            />
          </FormGroup>
          <FormGroup label="Parent Meals" name="eligible_parent_meals">
            <input
              type="number"
              ref={register({ required: false, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="eligible_parent_meals"
            />
          </FormGroup>
          <FormGroup
            label="Unique Parent"
            name="unique_eligible_parents"
          >
            <input
              type="number"
              ref={register({ required: false, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="unique_eligible_parents"
            />
          </FormGroup>
          <FormGroup
            label="Unique Volunteers"
            name="unique_volunteers"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="unique_volunteers"
            />
          </FormGroup>
          <FormGroup
            label="Children who have accessed the wider support of the church"
            name="children_accessed_wider_support_of_church"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="children_accessed_wider_support_of_church"
            />
          </FormGroup>
          <FormGroup
            label="Parents who have accessed the wider support of the church"
            name="parents_accessed_wider_support_of_church"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="parents_accessed_wider_support_of_church"
            />
          </FormGroup>
          <hr />
          <FormGroup
            label="Year to Date Unique Children"
            name="ytd_unique_eligible_children"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="ytd_unique_eligible_children"
            />
          </FormGroup>
          <FormGroup
            label="Year to Date Unique Parents"
            name="ytd_unique_eligible_parents"
          >
            <input
              type="number"
              ref={register({ required: false, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="ytd_unique_eligible_parents"
            />
          </FormGroup>
          <FormGroup
            label="Year to Date Unique Volunteers"
            name="ytd_unique_volunteers"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="ytd_unique_volunteers"
            />
          </FormGroup>
          <FormGroup
            label="Year to Date children who have accessed the wider support of the church"
            name="ytd_children_accessed_wider_support_of_church"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="ytd_children_accessed_wider_support_of_church"
            />
          </FormGroup>
          <FormGroup
            label="Year to Date parents who have accessed the wider support of the church"
            name="ytd_parents_accessed_wider_support_of_church"
            required
          >
            <input
              type="number"
              ref={register({ required: true, valueAsNumber: true, min: 0 })}
              className="form-control"
              name="ytd_parents_accessed_wider_support_of_church"
            />
          </FormGroup>

          <div className="tw-flex tw-justify-end">
            <SubmitButton>Submit</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
