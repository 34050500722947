import { FormProvider, useForm, useWatch } from "react-hook-form";
import React from "react";
import {FormGroup, Radio, SubmitButton} from "../../../../@coherent/react-hook-form";
import { TextQuestion } from "../../components/TextQuestion";
import {Button, Table} from "reactstrap";
import { YesNoRadioQuestion } from "../../../../@coherent/react-hook-form/YesNoRadioQuestion";
import {TextareaQuestion} from "../../components/TextareaQuestion";
import {JobDescriptionLink} from "../../components/JobDescriptionLink";

const criteriaQuestions = {
  integrity: "Personal Integrity",
  relationship_peers: "Relationship with peers/colleagues",
  relationship_others: "Relationship with others",
  team_work: "Team work",
  reliability: "Reliability",
  punctuality: "Punctuality",
  quality_of_work: "Quality of work",
  health_record: "Health record/Attendance",
  honesty: "Honesty",
}

export const Step3Form = ({ required = true }) => {
  const { disciplinary, suitability, allegations, criminal, reasons_not_accept } = useWatch({
    name: ["disciplinary", "suitability", "allegations", "criminal", "reasons_not_accept"],
  });

  return (
    <>
      <h3 className="tw-text-lg mb-4">Your Reference</h3>
      <FormGroup required={required} label="Please indicate your response for each of the criteria on the left by marking the most appropriate assessment up to your best knowledge.">
        <Table size="sm" striped>
          <thead>
            <tr>
              <th/>
              <th className="text-center">Excellent</th>
              <th className="text-center">Good</th>
              <th className="text-center">Unsatisfactory</th>
            </tr>
          </thead>
          <tbody>
          {Object.entries(criteriaQuestions).map(([k, v]) => (
            <tr key={k}>
              <td>
                <FormGroup name={k} label={v} className="mb-0" />
              </td>
              <td className="text-center">
                <Radio name={k} value="Excellent" />
              </td>
              <td className="text-center">
                <Radio name={k} value="Good" />
              </td>
              <td className="text-center">
                <Radio name={k} value="Unsatisfactory" rules={{
                  required: true
                }} />
              </td>
            </tr>
          ))}
          </tbody>

        </Table>
      </FormGroup>
      <YesNoRadioQuestion required={required} name="disciplinary" label="To the best of your knowledge has this applicant ever been subject to any disciplinary action?">
        {disciplinary === "Yes" && (
          <TextQuestion
            name="disciplinary_details"
            required={required}
            label="Please provide details"
          />
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion required={required} name="suitability" label="Are you completely satisfied that the applicant is suitable to work with children?">
        {suitability === "No" && (
          <TextQuestion
            name="suitability_details"
            required={required}
            label="Please provide details"
          />
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion required={required} name="allegations" label="To the best of your knowledge have any allegations or concerns been raised about the applicant that relate to the safety and welfare of children or young people, or their behaviour towards children or young people?">
        {allegations === "Yes" && (
          <TextQuestion
            name="allegations_details"
            required={required}
            label="Please provide details"
          />
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion required={required} name="criminal" label="To the best of your knowledge, does the applicant have any criminal convictions or criminal proceedings pending which might affect eligibility for the role applied for?">
        {criminal === "Yes" && (
          <TextQuestion
            name="criminal_details"
            required={required}
            label="Please provide details"
          />
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion required={required} name="reasons_not_accept" label="Do you know of any reason why we should not accept this candidate’s application?">
        {reasons_not_accept === "Yes" && (
          <TextQuestion
            name="reasons_not_accept_details"
            required={required}
            label="Please provide details"
          />
        )}
      </YesNoRadioQuestion>
      <TextareaQuestion label="Additional Comments" helpPosition="top" help={(
        <>
          Please click on the appropriate job description as follows to see what this role
          entails: <JobDescriptionLink jobRole="Coordinator">Coordinator</JobDescriptionLink> or <JobDescriptionLink jobRole="Core Team Member">Core Team Member</JobDescriptionLink>. Please highlight any concerns or anything exceptional you think
          we should be aware of when considering them for this role within Make Lunch with TLG.
        </>
      )} name="additional_comments" />
    </>
  );
};

export const Step3 = ({ onSubmit: onSubmitProp, defaultValues, onBack }) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
    shouldUnregister: true,
  });
  const { handleSubmit } = methods;

  async function onSubmit(x) {
    return onSubmitProp({
      ...x,
      submit: true,
    });
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step3Form />
        <div className="tw-flex tw-justify-between">
          <Button onClick={onBack}>Back</Button>
          <SubmitButton>Submit</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
