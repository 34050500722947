import { FormProvider, useForm, useWatch } from "react-hook-form";
import React from "react";
import { SubmitButton } from "../../../../@coherent/react-hook-form";
import { TextQuestion } from "../../components/TextQuestion";
import { Button } from "reactstrap";
import { YesNoRadioQuestion } from "../../../../@coherent/react-hook-form/YesNoRadioQuestion";

export const Step2Form = ({ required = true }) => {
  const { disciplinary, concerns_raised, criminal_record } = useWatch({
    name: ["disciplinary", "concerns_raised", "criminal_record"],
  });

  return (
    <>
      <h3 className="tw-text-lg mb-4">Safeguarding</h3>
      <YesNoRadioQuestion
        name="disciplinary"
        label="Have you ever been subject to any disciplinary action?"
        required={required}
      >
        {disciplinary === "Yes" && (
          <TextQuestion
            name="disciplinary_details"
            label="Please provide details"
            required={required}
          />
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion
        name="concerns_raised"
        label="Have any allegations or concerns been raised about you that relate to the safety and welfare of children or young people, or your behaviour towards children or young people?"
        required={required}
      >
        {concerns_raised === "Yes" && (
          <TextQuestion
            name="concerns_raised_details"
            label="Please provide details"
            required={required}
          />
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion
        name="criminal_record"
        label="Do you have any criminal convictions or criminal proceedings pending which might affect eligibility for the role applied for?"
        required={required}
      >
        {criminal_record === "Yes" && (
          <TextQuestion
            name="criminal_record_details"
            label="Please provide details"
            required={required}
          />
        )}
      </YesNoRadioQuestion>
    </>
  );
};

export const Step2 = ({ onSubmit, defaultValues, onBack }) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
    shouldUnregister: true,
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step2Form />
        <div className="tw-flex tw-justify-between">
          <Button onClick={onBack}>Back</Button>
          <SubmitButton>Continue</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
