import React from "react";
import { NotificationManager } from "react-notifications";
import { useDropzone } from "react-dropzone";

export const ImageUpload = ({
  field,
  form,
  currentImage: currentImageProp,
  imageStyle = {},
}) => {
  const { value, name } = field;
  const { setFieldValue, setFieldTouched, values } = form;

  const currentImage = currentImageProp || values[name + "__name"] || undefined;

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted([file], event) {
      const reader = new FileReader();
      reader.onload = () => {
        setFieldValue(
          name,
          {
            filename: file.name,
            content: reader.result,
          },
          false
        );
        setFieldTouched(name, true);
      };
      reader.readAsDataURL(file);
    },
  });

  return (
    <div>
      <div {...getRootProps()} className="btn btn-outline-secondary">
        <input {...getInputProps()} />
        {value ? (
          <div>
            <img
              alt=""
              className="img-thumbnail tw-w-64 tw-block tw-mb-2"
              src={value.content}
              onError={() => {
                NotificationManager.error("Invalid image selected");
                setFieldValue("image", null);
              }}
              style={imageStyle}
            />
            {value.filename} selected.
          </div>
        ) : currentImage ? (
          <div>
            <img
              alt=""
              className="img-thumbnail tw-w-64 tw-block tw-mb-2"
              src={`/api/media/${currentImage}`}
              style={imageStyle}
            />
            Click here to select a new image.
          </div>
        ) : (
          "Click here to select an image."
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
