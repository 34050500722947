export const ethnicityCaptureUrl = 'ethnicity_capture';
export const ethnicitiesUrl = `${ethnicityCaptureUrl}/ethnicities`;
export const preferNotToSay = 'Prefer not to say';

export const otherLabelsMapping = {
  'White': 'Any other White ethnic group',
  'Mixed/Multiple Ethnic Groups': 'Any other Mixed/Multiple ethnic background',
  'Asian/Asian British': 'Any other Asian background',
  'Black/African/Caribbean/Black British': 'Any other Black/African/Caribbean background',
  'Other Ethnic Group': 'Any other ethnic group'
};
export const otherLabels = [...Object.values(otherLabelsMapping)]