import React from 'react';
import {useController} from "react-hook-form";

export const Input = ({ name, rules, control, defaultValue, onFocus, as: Component = 'input', ...props }) => {
  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
    onFocus,
  })

  return (
    <Component {...field} {...props} />
  )
}