import React, { useState, useCallback } from "react";
import { debounce } from "lodash";

import { FormGroup } from "components/common";

export default ({ onChange, debounce: debounceTimeout = 1000, placeholder }) => {
  const [value, setValue] = useState("");

  const debouncedOnChange = useCallback(debounce(onChange, debounceTimeout), [
    debounceTimeout,
    onChange,
  ]);

  return (
    <FormGroup name="search" component="div">
      <input
        name="search"
        type="text"
        className="form-control"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          debouncedOnChange(e.target.value);
        }}
        placeholder={placeholder || "Search..."}
      />
    </FormGroup>
  );
};
