import React from "react";
import classNames from "classnames";

export const Page = ({ title, className, style, children, titleRef, ...props }) => {
  return (
    <div
      className={classNames(
        "tw-container tw-rounded tw-shadow-md tw-mx-auto tw-px-6 tw-py-4 tw-bg-white tw-mb-4",
        className
      )}
      style={style}
    >
      {title ? (
        <h2 ref={titleRef} className="tw-font-medium tw-text-2xl tw-mb-4">{title}</h2>
      ) : null}
      {children}
    </div>
  );
};

export default Page;
