import React, { useState, useMemo } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { keys } from "lodash";
import { PendingButton } from "components/common";
import { NotificationManager } from "react-notifications";
import { ApiClient } from "services";

const DeleteModal = ({
  isOpen,
  toggle,
  url,
  onDeleted,
  onConfirm: onConfirmProp,
  selected: selectedProp,
  appendBody,
}) => {
  const client = ApiClient();
  const [pending, setPending] = useState(false);

  const selected = useMemo(() => (selectedProp ? keys(selectedProp) : []), [
    selectedProp,
  ]);

  const numSelected = selected.length;

  function onConfirm() {
    setPending(true);
    let promise = null;
    const failed = [];
    if (onConfirmProp) {
      promise = onConfirmProp();
    } else {
      promise = Promise.mapSeries(
        selected,
        (i) => {
          return client.delete(`${url}/${i}`).catch((err) => {
            failed.push(i);
          });
        },
        { concurrency: 1 }
      ).tap(() => {
        if (failed.length) {
          NotificationManager.error(
            `${failed.length} row(s) could not be deleted`
          );
        }
      });
    }

    promise
      .tap(() => {
        if (isOpen) toggle();
        onDeleted(failed.length ? failed : null);
      })
      .finally(() => setPending(false));
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <p>
          Are you sure you want to delete the {numSelected} selected rows?
        </p>
        {appendBody ? appendBody : null}
      </ModalBody>
      <ModalFooter>
        <PendingButton color="primary" pending={pending} onClick={onConfirm}>
          Confirm
        </PendingButton>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
