import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {FormGroup, Input, Radio, Select, SubmitButton} from "../../../../@coherent/react-hook-form";
import {useQuery} from "react-query";
import {useClient} from "../../../../hooks";
import {NotificationManager} from "react-notifications";
import {get} from "lodash";

export const ConvertForm = ({ application, onConverted }) => {
  const { form, id } = application;
  const { first_name, last_name, email, organisation, role } = form;
  const methods = useForm({
    defaultValues: {
      user_type: role === 'Coordinator' ? 'coordinator': 'core',
      organisation,
    }
  });
  const { handleSubmit,  setError, errors } = methods;


  const client = useClient();

  const { data: organisations } = useQuery(['organisations'], () => (
    client.get('organisations').get('data')
  ));

  async function onSubmit(x) {
    try {
      await client.post('users/pastors', {
        ...x,
        is_active: true,
        application: id
      });
    } catch (ex) {
      const { code, detail } = client.parseError(ex);

      if (code === 'invalid') {
        if (detail.password) {
          setError('password', {
            type: 'server',
            message: detail.password,
          });
          return;
        } else if (detail.email) {
          setError('email', {
            type: 'server',
            message: 'A user with this email address already exists'
          });
          return;
        } else {
          throw ex;
        }
      } else {
        throw ex;
      }
    }

    NotificationManager.success('Application converted');
    onConverted();
  }

  if (!organisations) return null;

  return (
    <FormProvider {...methods}>
      <FormGroup name="email" label="Email" required help={
        get(errors, 'email.message')
      }>
        <Input rules={{ required: true }} type="email" name="email" className="form-control" defaultValue={email} />
      </FormGroup>
      <FormGroup name="first_name" label="First Name" required>
        <Input rules={{ required: true }} name="first_name" className="form-control" defaultValue={first_name} />
      </FormGroup>
      <FormGroup name="last_name" label="Last Name" required>
        <Input rules={{ required: true }} name="last_name" className="form-control" defaultValue={last_name} />
      </FormGroup>
      <FormGroup name="password" label="Password" required help={
        get(errors, 'password.message')
      }>
        <Input rules={{ required: true }} name="password" className="form-control" defaultValue="" />
      </FormGroup>
      <FormGroup name="organisation" label="Club" required>
        <Select rules={{ required: true }} simpleValue options={organisations} labelKey="organisation_name" valueKey="id" name="organisation" defaultValue={organisation} />
      </FormGroup>
      <FormGroup label="User Type" required name="user_type">
        <Radio label="Coordinator" name="user_type" value="coordinator" />
        <Radio label="Core Team Member" name="user_type" value="core" rules={{
          required: true
        }} />
      </FormGroup>
      <div className="tw-flex tw-justify-end">
        <SubmitButton onClick={handleSubmit(onSubmit)}>
          Convert
        </SubmitButton>
      </div>
    </FormProvider>
  )
}