import React from 'react';
import {reduce} from "lodash";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import {useMemo} from "react";

const defaultColors =`#3366cc
#dc3912
#ff9900
#109618
#990099
#0099c6
#dd4477
#66aa00
#b82e2e
#316395
#994499
#22aa99
#aaaa11
#6633cc
#e67300
#8b0707
#651067
#329262
#5574a6
#3b3eac
#b77322
#16d620
#b91383
#f4359e
#9c5935
#a9c413
#2a778d
#668d1c
#bea413
#0c5922
#743411`.split('\n');

export const VolunteerAgeGraph = ({
                                 data: dataProp,
                                 colors: colorsProp,
                               }) => {
  const colors = colorsProp || defaultColors;

  const [data, total] = useMemo(() => {
    let total = 0;

    const data = reduce(
      dataProp,
      (acc, i, key) => {
        total += i.value;
        acc.push({
          ...i,
          name: `${i.name}: ${i.value}`
        });
        return acc;
      },
      []
    );

    return [data, total];
  }, [dataProp]);

  return (
    <div>
      <h5 className="tw-text-left">
        Volunteers Ages{" "}
        <span className="text-muted">(total: {total})</span>
      </h5>
      <ResponsiveContainer minHeight={310}>
        <PieChart>
          <Pie data={data} label dataKey="value">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};