import { useFormContext } from "react-hook-form";
import classNames from "classnames/dedupe";
import React from "react";
import { get } from 'lodash';
import cx from 'classnames';
import {Trans} from "react-i18next";

export const FormText = ({ children, className }) => {
  return (
    <small className={cx("form-text text-muted", className)}>
      {children}
    </small>
  )
}

export const FormGroup = ({
  name,
  label,
  check,
  htmlFor,
  className,
  labelClassName,
  children,
  required,
  help,
  helpPosition = 'bottom',
  optional,
  ...props
}) => {
  const { errors } = useFormContext();
  const error = get(errors, name);

  return (
    <div
      className={classNames(
        "form-group",
        {
          "has-error": error,
          "form-check": check
        },
        className
      )}
      {...props}
    >
      {check ? (
        <>
          {children}
          {label ? (
            <label htmlFor={htmlFor} className={classNames("form-check-label", labelClassName)}>
              {label} {required ? <span className="text-danger">*</span> : null}
            </label>
          ) : null}
        </>
      ) : (
        <>
          {label ? (
            <label htmlFor={htmlFor} className={classNames("control-label", labelClassName)}>
              {label}{' '}
              {required ? <span className="text-danger">*</span> : null}
              {optional ? <span className="text-muted tw-text-sm">
                <Trans>
                  (optional)
                </Trans>
              </span> : null}
            </label>
          ) : null}
          {help && helpPosition === 'top' ? (
            <FormText className="tw--mt-2 tw-mb-2">
              {help}
            </FormText>
          ) : null}
          {children}
          {help && helpPosition === 'bottom' ? (
            <FormText>
              {help}
            </FormText>
          ) : null}
        </>
      )}
    </div>
  );
};
