import React, { useState } from "react";
import { Page, FormGroup, SubmitButton } from "components/common";
import { useFormik, Field, FormikProvider, Form } from "formik";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import { isEmail } from "validator";

const PasswordResetRequest = ({ client }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  async function onSubmit(x) {
    setSuccess(false);
    setError("");
    try {
      await client.post("/password_reset_request", x);
      setSuccess(true);
    } catch (ex) {
      ex.handled = true;
      setError("There was a problem with resetting the password");
    }
  }

  const formik = useFormik({
    onSubmit,
    initialValues: {
      email: "",
    },
  });

  return (
    <Page title="Password reset request" className="tw-max-w-xl">
      <p className="tw-text-gray-600 tw-text-sm tw-leading-tight tw--mt-2">
        Enter your email below and a link to reset your password will be sent to
        you.
      </p>
      {success ? (
        <>
          <Alert color="success" className="tw-mb-4">
            An email has been sent to the specified email address.
          </Alert>
          <Link to="/login">Return to Login</Link>
        </>
      ) : (
        <>
          <FormikProvider value={formik}>
            <Form className="tw-mb-4">
              <FormGroup label="Email" name="email">
                <Field
                  validate={(v) => !isEmail(v)}
                  name="email"
                  className="form-control"
                />
              </FormGroup>
              <div className="tw-flex tw-justify-between tw-items-baseline">
                <Link to="/login">Return to Login</Link>
                <SubmitButton>Send Reset Link</SubmitButton>
              </div>
            </Form>
          </FormikProvider>
          {error && <Alert color="danger">{error}</Alert>}
        </>
      )}
    </Page>
  );
};

export default PasswordResetRequest;
