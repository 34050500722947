import React, {useEffect} from 'react';
import {useQuery} from "react-query";
import {useClient} from "../../hooks";
import {
  Alert, Badge, Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import {Page} from "../../components/common";
import {Link} from "react-router-dom";
import moment from "moment";
import {FormProvider, useForm, useFormContext, useWatch} from "react-hook-form";
import {SubmitButton} from "../../@coherent/react-hook-form";
import {NotificationManager} from "react-notifications";

function useRegisteredDuplicates() {
  const client = useClient();

  return useQuery({
    queryKey: ['registered_duplicates'],
    queryFn: () => client.get('registered_duplicates').get('data'),
    config: {
      keepPreviousData: true
    }
  })
}

export function RegisteredDuplicateEntry({ value, isMostRecent }) {
  const { id, is_adult, created, first_name, last_name, date_of_birth, attendance_type, email, participant_self_registration } = value;
  const { primary, dedupe } = useWatch({});
  const { setValue, trigger } = useFormContext();

  const isPrimary = id === primary;

  const isMerge = dedupe.includes(id);

  return (
    <Card outline color={isPrimary ? 'success' : isMerge ? 'danger' : undefined} className="tw-h-full">
      <CardBody>
        {isMostRecent ? (
          <p className="mb-1 small">
            <span className="fa fa-star text-info" /> <strong>Most recent registration</strong>
          </p>
        ) : (
          <p className="mb-1 small">
            <span className="fa fa-warning text-warning" /> Previous registration
          </p>
        )}
        <Link target="_blank" rel="noopener noreferrer" to={`/registered/${id}`}>
          {first_name} {last_name}
        </Link>
        <ul className="small mb-0 tw-list-none pl-0">
          {created ? (
            <li>
              Created {moment(created).format('DD/MM/YYYY')}
            </li>
          ) : null}
          <li>
            {!!participant_self_registration ? (
              <Badge color="tlg-teal" pill>Self-registered</Badge>
            ) : 'Club registered'}
          </li>
          <li>
            {is_adult ? 'Adult' : 'Child'}
            {attendance_type ? `/${attendance_type}` : ''}
          </li>
          {email ? (
            <li>
              {email}
            </li>
          ) : null}
          {date_of_birth ? (
            <li>
              Date of birth: {moment(date_of_birth).format('DD/MM/YYYY')}
            </li>
          ) : null}
        </ul>
        <hr/>
        <FormGroup check>
          <Label check for={`keep_${id}`}>
            <Input id={`keep_${id}`} type="radio" checked={isPrimary} onChange={() => {
              setValue('primary', id);
              setValue('dedupe', dedupe.filter(i => i !== id));
              trigger();
            }} />
            Keep this record
          </Label>
        </FormGroup>
        {!isPrimary ? (
          <FormGroup check>
            <Label check for={`merge_${id}`}>
              <Input id={`merge_${id}`} type="checkbox" checked={isMerge} onChange={(e) => {
                const { checked } = e.target;
                setValue('dedupe', checked ? [...dedupe, id] : dedupe.filter(i => i !== id));
                trigger();
              }} />
              Transfer and Delete this record
            </Label>
          </FormGroup>
        ) : null}
      </CardBody>
    </Card>
  )
}

export function RegisteredDuplicate({ value, onConfirmed }) {
  const { full_name, registered } = value;
  const form = useForm({
    defaultValues: {
      primary: null,
      dedupe: []
    },
    shouldUnregister: false,
    mode: 'all'
  });
  const client = useClient();
  const { handleSubmit, reset, register, getValues, formState } = form;
  const { isValid } = formState;

  async function onSubmit(x) {
    await client.post('registered_duplicates/dedupe', x);
    NotificationManager.success('Registration merged');
    const { primary } = getValues();
    reset({
      primary,
      dedupe: []
    });
    await onConfirmed();
  }

  useEffect(() => {
    const { primary, dedupe } = getValues();
    const { registered } = value;

    reset({
      primary: registered.some(i => i.id === primary) ? primary : registered[0].id,
      dedupe: dedupe.filter(i => registered.some(j => j.id === i))
    })
  }, [value, reset, getValues])

  register('root', {
    validate: () => {
      const { primary, dedupe } = getValues()
      return !!(primary && dedupe.length);
    }
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h3 className="mb-0">{full_name}</h3>
          <p>
            {registered.length} potential duplicates found for {full_name}.
          </p>
          <Row form className="mb-2 tw-gap-y-2">
            {registered.map((i, idx) => (
              <Col xs="auto" key={i.id}>
                <RegisteredDuplicateEntry value={i} isMostRecent={!idx} />
              </Col>
            ))}
          </Row>
          <SubmitButton disabled={!isValid}>
            Confirm
          </SubmitButton>
        </div>
      </form>
    </FormProvider>
  )
}

export function RegisteredDuplicates() {
  const { data, isFetching, isLoading, refetch } = useRegisteredDuplicates();

  return (
    <Page title="Duplications in your register">
      <p>
        The new online registration form is a great way to confirm that the
        personal information captured in the registration records for all your
        club participants is up to date.
      </p>
      <p>To ensure that we maintain consistency
        across the attendance record for each participant, please use this tool
        to <strong>delete old</strong> registration details and <strong>transfer
        the stored attendance data to the new, most up-to-date, registration
        record for each participant.</strong> This will make sure that your
        Golden Numbers remain accurate, and your registers don’t get confusing
        with lots of duplicated names.
      </p>
      <p>
        If you have asked previously registered participants to self-register
        using the new online form, they will now have
        TWO Registration records. These duplications have been identified below
        by matching first and last names.
      </p>
      <p>
        Below is a step-by-step guide to using the tool:
        <ol>
          <li>
            Check the details and ensure you are confident that the system has
            accurately identified a duplication. Check dates of birth etc.
          </li>
          <li>
            Select the most recent record that you would like to
            keep for this individual and tick the box that says "Keep this
            record"
          </li>
          <li>
            The only information that will be transferred to the new record
            will be the individuals attendance data and their Church Connection
            status so you might want to check that the previous record does not
            contain any useful information that is not included in the one you
            are keeping. You can do this by opening the records - just click on
            the name of the participant and it will open in a new window.
            If required, you can manually update the record you have selected
            to "keep" with any missing information that is on the previous
            record. Don't forget to scroll down and click save!
          </li>
          <li>
            Tick the "Transfer and Delete this record" box on the records that
            you wish to be replaced.
          </li>
          <li>
            Click the blue confirm button.
          </li>
        </ol>
      </p>
      <p>
        The new updated registration record will now be accurately connected to
        all attendance data that has been logged for this participant.
      </p>
      <hr/>
      {isFetching ? (
        <p>
          <span className="fa fa-spinner fa-spin mt-2" /> Loading...
        </p>
      ) : (
        <p>
          {data.length} possible duplicates found. <Button size="sm" onClick={refetch}>Refresh</Button>
        </p>
      )}
      <hr/>
      {!isLoading ? (
        <>
          {!data.length ? (
            <Alert color="success">
              No duplicate registrations found.
            </Alert>
          ) : null}
          {data.map(i => (
            <div key={i.id}>
              <RegisteredDuplicate onConfirmed={refetch} value={i} key={i.full_name} />
              <hr/>
            </div>
          ))}
        </>
      ) : null}
    </Page>
  )
}