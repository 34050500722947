import React, { useState } from "react";
import { Router, useRouteMatch } from "react-router-dom";
import history from "config/history";
import { UserContextProvider } from "contexts/UserContext";
import { CommonContextProvider } from "contexts/CommonContext";
import { useUserContext } from "hooks";
import { NotificationContainer } from "react-notifications";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { isUndefined } from "lodash";
import logo from "./assets/logo.png";
import bohLogo from "./assets/boh_logo.png";
import Routes from "./Routes";
import { Auth } from "services";
import artwork from "./assets/ML_IconHeader.png";
import classNames from "classnames";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import {EthnicityCapture} from "./features/ethnicity_capture/EthnicityCapture";
import {RSCCheck} from "./features/ready_steady_cook/RSCCheck";
import moment from "moment";

const queryCache = new QueryCache({
  defaultConfig: {
    shared: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      useErrorBoundary: true,
      structuralSharing: false,
    },
  },
});

const Providers = ({ children }) => {
  return (
    <Router history={history}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <UserContextProvider>{children}</UserContextProvider>
      </ReactQueryCacheProvider>
    </Router>
  );
};

const NavLink = ({ test, ...props }) =>
  isUndefined(test) || !!test ? <Link className="nav-link" {...props} /> : null;

const showRSCCheck = moment().isSameOrAfter('2024-01-01');

const Header = () => {
  const {
    user,
    reload,
    isStaff,
    isPastor,
    isTLG,
    isVP,
    isVPSupport,
    isVPGeneral,
    isTLGStaff,
      isBoxesOfHope,
    isBoxesOfHopeStaff,
    allowMultipleGroups,
    isCoordinator,
    isCoreTeamMember,
    isSafeGuardingLeader,
    isPartnerships,
    loggedInThisSession
  } = useUserContext();
  const [collapsed, setCollapsed] = useState(true);

  function onLogout() {
    Auth.clearToken();
    reload();
  }

  const isSurvey = useRouteMatch("/survey/:id");

  return (
    <>
      <div className={classNames(
        "tw-py-1 navbar position-relative d-none d-lg-block",
        isSurvey ? 'tw-bg-tlg-orange-500' : 'tw-bg-tlg-green'
      )}>
        <Link to="/" className="navbar-brand">
            {isBoxesOfHope ? (
                <img src={bohLogo} alt="Logo" height="50" />
            ) : (
                <img src={logo} alt="Logo" height="50" />
            )}
        </Link>
        <img
          alt=""
          className="d-none d-lg-block"
          src={artwork}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
            width: "auto",
          }}
        />
      </div>
      <Navbar
        dark
        expand="lg"
        className={classNames(
          "tw-py-1 shadow-sm ",
          isSurvey ? 'tw-bg-tlg-orange-500' : 'lg:tw-bg-tlg-green-dark tw-bg-tlg-green',
          {
            "lg:tw-hidden": !user,
          }
        )}
      >
        {user && !isSurvey ? (
          <NavbarToggler onClick={() => setCollapsed((v) => !v)} />
        ) : null}
        <Link to="/" className="navbar-brand d-block d-lg-none">
          <img src={logo} alt="Logo" height="50" />
        </Link>
        {user && !isSurvey ? (
          <Collapse isOpen={!collapsed} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink to="/welcome" test={(isPastor || isVPGeneral || isTLGStaff) && isTLG}>
                  Welcome
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/ready_steady_cook" test={isPastor && isTLG && !isSafeGuardingLeader}>
                  Ready, Steady, Cook
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/dashboard" test={isStaff || isPastor}>
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/users/staff" test={isVPSupport}>
                  Staff
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/users/pastors" test={isVP || isBoxesOfHopeStaff}>
                  Users
                </NavLink>
              </NavItem>
              {isStaff || isBoxesOfHopeStaff ? (
                <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle nav>Clubs</DropdownToggle>
                  <DropdownMenu right>
                    <NavLink test={isVP || isBoxesOfHopeStaff} className="dropdown-item" to="/organisations">
                      View Clubs
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/organisation_status"
                      test={isTLG && isVP}
                    >
                      Club Open Status
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/club_open_list"
                      test={isTLG}
                    >
                      Confirmed Sessions Calendar
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/organisation_status/inbox"
                      test={isTLG && isVP}
                    >
                      Inbox
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/church_relationship_leads"
                      test={isVPSupport && isTLG}
                    >
                      Church Relationship Leads
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/holiday_texts"
                      test={isVPSupport && isTLG}
                    >
                      Holiday Text Setup
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
              <NavItem>
                <NavLink to="/registered" test={isPastor && !isSafeGuardingLeader}>
                  Registration
                </NavLink>
              </NavItem>
              {allowMultipleGroups && (
                <NavItem>
                  <NavLink to="/groups" test={isPastor && !isSafeGuardingLeader}>
                    Groups
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink to="/sessions" test={isPastor && isTLG && !isSafeGuardingLeader}>
                  Lunch Club Sessions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/parcel_provision_sessions" test={isPastor && !isSafeGuardingLeader}>
                  Parcel Provisions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/resources" test={isPastor || isVPGeneral || isTLGStaff}>
                  Resources
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/video_resources" test={isPastor || isVPGeneral || isTLGStaff}>
                  Videos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/setup" test={isPastor && isTLG && !isSafeGuardingLeader}>
                  Setup
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/news" test={isVPSupport && isTLG}>
                  News
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/videos" test={isVPSupport && isTLG}>
                  Videos
                </NavLink>
              </NavItem>
              {isVPSupport && isTLG ? (
                <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle nav>Meet the VP Team</DropdownToggle>
                  <DropdownMenu right>
                    <NavLink
                      className="dropdown-item"
                      to="/team_members"
                    >
                      Team Members
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/team_sections"
                    >
                      Team Sections
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
              <NavItem>
                <NavLink to="/church_connections" test={isVPSupport && isTLG}>
                  Church Connections
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/important_dates" test={isVPSupport && isTLG}>
                  Important Dates
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/admin/resources" test={isVPSupport || isBoxesOfHopeStaff}>
                  Resources
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/admin/video_resources" test={isVPSupport || isBoxesOfHopeStaff}>
                  Video Resources
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/applications" test={isVPSupport || isVPGeneral || isCoordinator || isCoreTeamMember || isSafeGuardingLeader || isPartnerships}>
                  Applications
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/participant_feedback" test={isPastor && isTLG}>
                  Participant Feedback
                </NavLink>
              </NavItem>
              {(isVPSupport || isBoxesOfHopeStaff) ? (
                <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle nav>Manual Entries</DropdownToggle>
                  <DropdownMenu right>
                    <NavLink
                      className="dropdown-item"
                      to="/golden_numbers_manual_entries"
                      test={isTLG}
                    >
                      Golden Numbers
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to="/parcel_provisions_manual_entries"
                    >
                      Parcel Provisions
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
            </Nav>
            <Nav navbar className="ml-auto">
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle nav caret>
                  <span className="fa fa-user" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={onLogout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        ) : null}
      </Navbar>
      <div className="tw-mt-4">
        <CommonContextProvider>
          {(isPastor && loggedInThisSession && showRSCCheck) ? (
            <RSCCheck />
          ) : null}
          {isCoordinator || isCoreTeamMember ? (
            <EthnicityCapture />
          ) : null}
          <Routes />
        </CommonContextProvider>
      </div>
    </>
  );
};

const App = () => {
  return (
    <Providers>
      <NotificationContainer />
      <Header />
    </Providers>
  );
};

export default App;
