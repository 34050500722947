import React from "react";
import { Page } from "../../../components/common";
import { useClient } from "../../../hooks";
import { get } from "lodash";
import { Alert } from "reactstrap";
import {handleNonFieldErrors} from "../util";
import {useDBSFormQuery} from "./hooks";
import {DBSForm} from "./components";

export const DBSFormView = ({ match }) => {
  const { id } = match.params;
  const url = `dbs_forms/${id}`;
  const client = useClient();
  const { error, data, refetch } = useDBSFormQuery(url);

  const container = (children) => (
    <Page title="Make Lunch DBS Check">
      {children}
    </Page>
  );

  if (error && get(error, "response.status") === 404) {
    return container(
      <Alert color="warning">This DBS check form link is not valid.</Alert>
    );
  }

  if (!data) return null;

  async function onSubmit(x) {
    try {
      await client.patch(url, x);
    } catch (ex) {
      handleNonFieldErrors(ex);
    }
    await refetch();
  }

  const { submitted } = data;

  if (submitted) {
    return container(
      <>
        <h3 className="tw-font-medium tw-text-xl tw-mb-4">Thanks!</h3>
        <p>
          Thank you for taking the time to complete this DBS check form!
        </p>
        <p>
          The Make Lunch Team
        </p>
      </>
    );
  }

  return container(
    <>
      <DBSForm data={data} onSubmit={onSubmit} />
    </>
  );
};
