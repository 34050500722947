import React from 'react';
import {Badge} from "reactstrap";

export function ClubHealthStatus({ value }) {
  if (!value) return null;

  const className = {
    'Red': 'tw-bg-red-600',
    'Green': 'tw-bg-green-600',
    'Amber': 'tw-bg-orange-500',
    'Gold': 'tw-bg-yellow-500'
  }[value];

  if (!className) return null;

  return (
    <Badge color="info" pill className={className}>{value}</Badge>
  )
}