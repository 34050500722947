import React, {useState} from 'react';
import {useQuery} from "react-query";
import {useClient, useModal} from "../../hooks";
import {Trans, useTranslation} from "react-i18next";
import {Page} from "../../components/common";
import {FormProvider, useFieldArray, useForm, useWatch} from "react-hook-form";
import {DatePicker, FormGroup, Input, SubmitButton, YesNoRadioQuestion} from "../../@coherent/react-hook-form";
import {isEmail} from "validator";
import {TextQuestion} from "../applications/components/TextQuestion";
import {Alert, Button, Col, ModalBody, Row} from "reactstrap";
import {EthnicityCaptureForm} from "../ethnicity_capture/EthnicityCaptureForm";
import {RegistrationReasons} from "./RegistrationReasons";
import './styles.scss';

function getDefaultChild() {
  return {
    first_name: "",
    surname: "",
    date_of_birth: null,
    gender: "",
    emergency_contact_number: "",
    dietary_requirements: null,
    dietary_requirements_detail: "",
    medical_conditions: null,
    medical_conditions_detail: "",
    school_attended: "",
    photo_consent: null,
  }
}

const Child = ({ name, onRemove, defaultValues }) => {
  const { t } = useTranslation();
  const n = v => `${name}.${v}`;

  const dietary_requirements = useWatch({
    name: n('dietary_requirements'),
  });
  const medical_conditions = useWatch({
    name: n('medical_conditions'),
  })

  return (
    <>
      <Row form>
        <Col lg>
          <FormGroup label={t('Child\'s First Name')} name={n('first_name')} required>
            <Input name={n('first_name')} rules={{
              required: true
            }} className="form-control" defaultValue={defaultValues.first_name} />
          </FormGroup>
        </Col>
        <Col lg>
          <FormGroup label={t('Child\'s Surname')} name={n('surname')} required>
            <Input name={n('surname')} rules={{
              required: true
            }} className="form-control" defaultValue={defaultValues.surname} />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col lg>
          <FormGroup label={t('Child\'s Date of Birth')} name={n('date_of_birth')} required help={
            t("dd/mm/yyyy format")
          }>
            <DatePicker name={n('date_of_birth')} className="form-control" defaultValue={defaultValues.date_of_birth} rules={{
              required: true
            }} />
          </FormGroup>
        </Col>
        <Col lg>
          <FormGroup label={t('Child\'s Gender')} name={n('gender')} required>
            <Input className="form-control" as="select" name={n('gender')} defaultValue={defaultValues.gender} rules={{
              required: true
            }}>
              <option value="" />
              <option value="Male">{t('Male')}</option>
              <option value="Female">{t('Female')}</option>
              <option value="Other">{t('Other')}</option>
              <option value="Prefer not to say">{t('Prefer not to say')}</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col lg>
          <FormGroup label={t('Child\'s Emergency Contact Number')} name={n('emergency_contact_number')} required>
            <Input name={n('emergency_contact_number')} rules={{
              required: true
            }} className="form-control" defaultValue={defaultValues.emergency_contact_number} />
          </FormGroup>
        </Col>
        <Col lg>
          <FormGroup label={t('Child School Attended')} name={n('school_attended')} optional>
            <Input name={n('school_attended')} className="form-control" defaultValue={defaultValues.school_attended} />
          </FormGroup>
        </Col>
      </Row>
      <YesNoRadioQuestion name={n("dietary_requirements")} label={
        t('Does this child have any dietary requirements that we should know about?')
      } required defaultValue={defaultValues.dietary_requirements}>
        {dietary_requirements === "Yes" && (
          <TextQuestion
            name={n("dietary_requirements_detail")}
            label={t("Please provide details")}
            required
            defaultValue={defaultValues.dietary_requirements_detail}
          />
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion name={n("medical_conditions")} label={
        t('Does this child have any Medical conditions we need to be aware of?')
      } required defaultValue={defaultValues.medical_conditions}>
        {medical_conditions === "Yes" && (
          <TextQuestion
            name={n("medical_conditions_detail")}
            label="Please provide details"
            required
            defaultValue={defaultValues.medical_conditions_detail}
          />
        )}
      </YesNoRadioQuestion>
      <YesNoRadioQuestion defaultValue={defaultValues.photo_consent} name={n("photo_consent")} label={t('Do you give your consent for this child to be photographed while at Make Lunch and for those photographs to be used locally and/or online to advertise or celebrate Make Lunch?')} required />

      {onRemove ? (
        <Button color="danger" size="sm" onClick={onRemove}>
          <span className="fa fa-trash mr-2"/>
          <Trans>
            Remove this child
          </Trans>
        </Button>
      ) : null}
    </>
  )
}

const Children = ({ name, control }) => {
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  function handleAddChild() {
    append(getDefaultChild());
  }

  function handleRemoveChild(index) {
    remove(index);
  }

  return (
    <>
      {fields.map((i, index) => (
        <div key={i.id}>
          <Child defaultValues={i} name={`${name}.${index}`} onRemove={index ? () => handleRemoveChild(index) : null} />
          {fields.length > 1 && <hr />}
        </div>

      ))}
      <div className="tw-flex tw-justify-end">
        <Button size="sm" onClick={handleAddChild}>
          <span className="fa fa-plus mr-1" />
          <Trans>
            Add another child
          </Trans>
        </Button>
      </div>
    </>
  )
}

function getDefaultValues() {
  return {
    first_name: "",
    surname: "",
    address_1: "",
    address_2: "",
    address_3: "",
    town: "",
    county: "",
    postcode: "",
    contact_number: "",
    email: "",
    allow_contact: null,
    photo_consent: null,
    dietary_requirements: null,
    dietary_requirements_detail: "",
    medical_conditions: null,
    medical_conditions_detail: "",
    why_fun_activities: false,
    why_new_friends: false,
    why_lunch: false,
    why_cost: false,
    why_other: false,
    why_other_detail: "",
    true_statements_special_educational_needs: false,
    true_statements_child_disability: false,
    true_statements_free_school_meals: false,
    true_statements_looked_after: false,
    true_statements_young_carers: false,
    true_statements_none_1: false,
    true_statements_refugees: false,
    true_statements_lone_parent: false,
    true_statements_struggling_financially: false,
    true_statements_adult_disability: false,
    true_statements_mental_health: false,
    true_statements_isolated: false,
    true_statements_none_2: false,
    english_first_language: "",
    first_language: "",
    children: [getDefaultChild()]
  }
}

export const ParticipantRegistration = ({ match, history, location }) => {
  const { params } = match;
  const { organisation_hashid } = params;
  const client = useClient();
  const { t } = useTranslation();
  const url = 'participant_registration';
  const organisationUrl = `${url}/organisation/${organisation_hashid}`;
  const { Modal, isOpen, setIsOpen } = useModal();
  const [step, setStep] = useState(0);

  const { data: organisation } = useQuery([organisationUrl], () => client.get(organisationUrl).get('data'));
  const form = useForm({
    mode: 'all',
    defaultValues: getDefaultValues()
  });
  const { setValue, watch, register, handleSubmit } = form;
  const { dietary_requirements, medical_conditions, english_first_language } = watch([
    'dietary_requirements',
    'medical_conditions',
    'english_first_language'
  ]);

  register('ethnicity_capture');

  async function onSubmit(x) {
    await client.post(url, {
      organisation_hashid,
      ...x
    });
    setStep(v => v + 1);
  }

  async function handleEthnicityCapture(x) {
    setValue('ethnicity_capture', x);
    await handleSubmit(onSubmit)();
    setIsOpen(false);
  }

  async function handlePreSubmit() {
    setIsOpen(true);
  }

  if (organisation && organisation.organisation === null) {
    return (
      <Page title={t('Welcome to Make Lunch')}>
        <Alert color="warning">
          <Trans>
            This link is not valid, please check with the intended organisation
          </Trans>
        </Alert>
      </Page>
    )
  }

  if (!organisation) {
    return null;
  }

  return (
    <Page title={t('Welcome to Make Lunch')} className="participant-registration">
      {isOpen && (
        <Modal size="lg" toggle={null}>
          <ModalBody>
            <Trans>
              It is important to us that our services are inclusive and reflect the diversity of our communities. Please help us by selecting your ethnicity on the form below. This data is collected anonymously and does not in any way affect your registration.
            </Trans>
            <p className="tw-font-medium">
              <Trans>
                If you prefer not to give this information, please choose the last option ‘prefer not to say’
              </Trans>
            </p>
            <p className="tw-text-lg tw-font-medium">
              Which of these statements best describes your Ethnicity? (Please check the appropriate box):
            </p>
            <EthnicityCaptureForm onSubmit={handleEthnicityCapture} />
          </ModalBody>
        </Modal>
      )}
      {step === 1 && (
        <div>
          <p>
            <Trans>
              Your registration is complete! We hope you and your family have a wonderful time at Make Lunch!
            </Trans>
          </p>
        </div>
      )}
      {step === 0 && (
        <FormProvider {...form}>
          <section>
            <p>
              <Trans>
                Thank you for joining us at <strong>{organisation.organisation}</strong> today. You are most welcome and we hope you have an enjoyable time! In order to attend Make Lunch you will need to complete the following registration form.
              </Trans>
            </p>
            <h2 className="tw-text-lg">
              <Trans>
                Parent/Carer Details
              </Trans>
            </h2>
            <p>
              Please answer the following questions for yourself, we will ask you about the children you are bringing to Make Lunch a bit later.
            </p>
            <Row form>
              <Col lg>
                <FormGroup name="first_name" label={t('First Name')} required>
                  <Input className="form-control" name="first_name" rules={{
                    required: true
                  }} />
                </FormGroup>
              </Col>
              <Col lg>
                <FormGroup name="surname" label={t('Surname')} required>
                  <Input className="form-control" name="surname" rules={{
                    required: true
                  }} />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup label={t('Address')} required>
              <FormGroup name="address_1" className="mb-1">
                <Input className="form-control" name="address_1" rules={{
                  required: true
                }} placeholder={t('Address 1 *')} />
              </FormGroup>
              <FormGroup name="address_2" className="mb-1">
                <Input className="form-control" name="address_2" placeholder={t('Address 2')} />
              </FormGroup>
              <FormGroup name="address_3" className="mb-1">
                <Input className="form-control" name="address_3" placeholder={t('Address 3')} />
              </FormGroup>
              <FormGroup name="town" className="mb-1">
                <Input className="form-control" name="town" placeholder={t('Town')} />
              </FormGroup>
              <FormGroup name="county" className="mb-1">
                <Input className="form-control" name="county" placeholder={t('County')} />
              </FormGroup>
              <FormGroup name="postcode" className="mb-1">
                <Input className="form-control" name="postcode" placeholder={t('Postcode')} />
              </FormGroup>
            </FormGroup>
            <Row form>
              <Col lg>
                <FormGroup name="contact_number" label={t('Contact Number')} required>
                  <Input className="form-control" name="contact_number" rules={{
                    required: true
                  }} />
                </FormGroup>
              </Col>
              <Col lg>
                <FormGroup name="email" label={t('Email')} required>
                  <Input className="form-control" name="email" type="email" rules={{
                    required: true,
                    validate: v => isEmail(v)
                  }} />
                </FormGroup>
              </Col>
            </Row>

            <YesNoRadioQuestion name="allow_contact" label={t(`Would you be happy to be contacted by the local ${organisation.organisation} Team occasionally to be notified of upcoming events and activities?`)} required />

            <YesNoRadioQuestion name="photo_consent" label={t('Do you give your consent to be photographed while at Make Lunch and for those photographs to be used locally and/or online to advertise or celebrate Make Lunch?')} required />

            <YesNoRadioQuestion name="dietary_requirements" label={
              t('Do you have any dietary requirements that we should know about?')
            } required>
              {dietary_requirements === "Yes" && (
                <TextQuestion
                  name="dietary_requirements_detail"
                  label={t("Please provide details")}
                  required
                />
              )}
            </YesNoRadioQuestion>
            <YesNoRadioQuestion name="medical_conditions" label={
              t('Do you have any Medical conditions we need to be aware of?')
            } required>
              {medical_conditions === "Yes" && (
                <TextQuestion
                  name="medical_conditions_detail"
                  label="Please provide details"
                  required
                />
              )}
            </YesNoRadioQuestion>
            <YesNoRadioQuestion required name="english_first_language" label={
              t('Is English your first language?')
            }>
              {english_first_language === 'No' && (
                <TextQuestion
                  name="first_language"
                  label="What is your first language?"
                  required
                />
              )}
            </YesNoRadioQuestion>
          </section>
          <hr/>
          <RegistrationReasons />
          <hr/>
          <section>
            <p>
              <Trans>
                Now it's time to register the individual children you have brought (or might bring) to Make Lunch. Please register all the children in your household even if you don't have them with you today.
              </Trans>
            </p>
            <Children name="children" />
          </section>
          <hr/>
          <section>
            <p>
              <Trans>
                Thank you for completing this form, the personal information you have provided will be used by your local Make Lunch Club to enable them to provide a safe event.
              </Trans>
            </p>
            <p>
              <Trans>
                Anonymous numerical data will also be provided to TLG Make Lunch enabling them to keep generic records around attendance and participation at Make Lunch clubs across the UK.
              </Trans>
            </p>
          </section>
          <div className="tw-flex tw-justify-end">
            <SubmitButton onClick={handleSubmit(handlePreSubmit)}>
              <Trans>
                Submit
              </Trans>
            </SubmitButton>
          </div>
        </FormProvider>
      )}
    </Page>
  )
}