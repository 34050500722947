import "./registerUnhandledRejections";
import "moment-duration-format";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import ReactDOM from "react-dom";
import "react-notifications/lib/notifications.css";
import "font-awesome/scss/font-awesome.scss";
import "react-day-picker/lib/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "rc-tree/assets/index.css";
import "./index.scss";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
