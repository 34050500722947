import {useModal} from "../../../../hooks";
import {Button, ModalBody, ModalHeader} from "reactstrap";
import {ConvertForm} from "./ConvertForm";
import React from 'react';

export const ConvertButton = ({ className, onConverted: onConvertedProp, application, ...props }) => {
  const { isOpen, setIsOpen, toggle, Modal } = useModal();

  function onConverted() {
    setIsOpen(false);
    onConvertedProp();
  }

  return (
    <>
      {isOpen ? (
        <Modal>
          <ModalHeader toggle={toggle}>
            Convert Application to User
          </ModalHeader>
          <ModalBody>
            <ConvertForm onConverted={onConverted} application={application} />
          </ModalBody>
        </Modal>
      ) : null}
      <Button size="sm" color="info" className={className} onClick={toggle} {...props}>
        Convert
      </Button>
    </>
  )
}