import React from "react";
import { usePendingButton, useClient } from "hooks";
import download from "downloadjs";

export const ExportButton = ({ id, delay = 0, ...props }) => {
  const [PendingButton] = usePendingButton();
  const client = useClient();

  async function onClick() {
    const data = await client
      .post(`parcel_provisions/${id}/export`)
      .get("data");
    download(data, "parcel_provision_export.csv", "text/csv");
  }

  return (
    <PendingButton onClick={onClick} {...props}>
      <span className="fa fa-download mr-2" />
      Export
    </PendingButton>
  );
};

export default ExportButton;
