import React from "react";
import { useTable } from "hooks";
import { Page } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

export const TextMessages = ({ match }) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    BooleanIcon,
    data,
  } = useTable({
    match,
    url: `text_messages`,
    filters: {
      type: "received",
    },
  });

  return (
    <Page title="Inbox">
      <TableSearch className="fa-min" />
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th sortBy="user__email">User</th>
              <th sortBy="user__organisation__organisation_name">
                Club
              </th>
              <th>Message</th>
              <th sortBy="timestamp">Received</th>
              <th>Holiday</th>
              <th>Processed</th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td>
                  <Link to={`/users/${i.user}`}>{i.user__email}</Link>
                </td>
                <td>
                  {i.user__organisation ? (
                    <Link to={`/organisations/${i.user__organisation}`}>
                      {i.user__organisation__organisation_name}
                    </Link>
                  ) : null}
                </td>
                <td>{i.message}</td>
                <td>{moment(i.timestamp).format("DD/MM/YYYY HH:mm")}</td>
                <td>
                  {i.holiday_text__year ? (
                    <>
                      {i.holiday_text__holiday} {i.holiday_text__year}
                    </>
                  ) : null}
                </td>
                <td>
                  <BooleanIcon test={i.holiday_confirmation} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};
