import React from 'react';
import cx from 'classnames';
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useModal} from "../hooks";
import {Formik} from "formik";
import {ReadySteadyCookFields} from "../pages/ReadySteadyCook";

export const RSCStatusButton = ({ value, ...props }) => {
  const rscComplete =
    value.ready_complete &&
    value.steady_complete &&
    value.cook_complete;
  const { Modal, setIsOpen, isOpen } = useModal();

  return (
    <>
      <Modal size="lg">
        {isOpen ? (
          <>
            <ModalHeader>
              Ready Steady Cook Status
            </ModalHeader>
            <ModalBody>
              <Formik initialValues={value}>
                <fieldset disabled>
                  <ReadySteadyCookFields />
                </fieldset>
              </Formik>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setIsOpen(false)}>
                Close
              </Button>
            </ModalFooter>
          </>
        ) : null}
      </Modal>
      <Button onClick={() => setIsOpen(true)} {...props}>
        <span className={cx('fa', rscComplete ? 'fa-check-circle text-success' : 'fa-times-circle text-danger')} />
      </Button>
    </>
  )
}