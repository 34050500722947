import React, { useMemo, useState } from "react";
import {
  useModal,
  useClient,
  useRouter,
  useOnMount,
  useFormikContext,
} from "hooks";
import { SubmitButton, FormGroup, Select } from "components/common";
import ReactDayPicker from "react-day-picker";
import { Formik, Field } from "formik";
import {Button} from "reactstrap";
import { without } from "lodash";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { YEAR_OPTIONS, THIS_YEAR } from "config/contants";
import {HolidaySetup} from "../pages/Sessions/HolidaySetup";

const DayPicker = ({ field, disabledDays, form }) => {
  const { name, value } = field;
  const { setFieldValue, setFieldTouched } = form;

  const selectedDays = useMemo(() => value.map((i) => moment(i).toDate()), [
    value,
  ]);

  return (
    <>
      <div className="tw-flex tw-justify-center">
        <ReactDayPicker
          selectedDays={selectedDays}
          disabledDays={disabledDays}
          onDayClick={(day, { selected, disabled }) => {
            if (disabled) return;
            const v = moment(day).format("YYYY-MM-DD");
            setFieldValue(
              name,
              selected ? without(value, v) : [...value, v],
              false
            );
            setFieldTouched(name, true);
          }}
        />
      </div>
    </>
  );
};

const EXTRA_SESSIONS = {
  holiday: 'Extra Sessions'
}

const Form = ({ label, url, client }) => {
  const [_holidayAllocation, setHolidayAllocation] = useState([]);
  const [_holidays, setHolidays] = useState([]);
  const [_disabledDays, setDisabledDays] = useState();
  const { values, setFieldValue } = useFormikContext();
  const { year, holiday } = values;

  useOnMount(async () => {
    const [holidayAllocation, holidays, disabledDays] = await Promise.all([
      client.get("holiday_allocation").get("data"),
      client.get("holiday_allocation/holidays").get('data').map(i => ({
        holiday: i,
      })),
      client.get(`${url}/dates`).get("data"),
    ]);
    setDisabledDays(disabledDays.map((i) => moment(i).toDate()));
    setHolidays(holidays)
    setHolidayAllocation(holidayAllocation);
  });

  const holidayAllocation = useMemo(() => {
    if (!holiday || !year) return null;
    return _holidayAllocation.find(i => i.holiday === holiday.holiday && i.year===year)
  }, [holiday, year, _holidayAllocation]);

  const holidays = useMemo(() => [
    ..._holidays,
    EXTRA_SESSIONS
  ], [_holidays]);

  const disabledDays = useMemo(() => {
    if (!holidayAllocation || holiday === EXTRA_SESSIONS) return _disabledDays;

    return [
      ..._disabledDays,
      {
        before: moment(holidayAllocation.start_date).toDate(),
        after: moment(holidayAllocation.end_date).toDate(),
      },
    ];
  }, [_disabledDays, holiday, holidayAllocation]);

  async function onHolidaySetupSubmitted() {
    const holidayAllocation = await client.get("holiday_allocation").get("data");
    NotificationManager.success('Holiday dates set up successfully');
    setHolidayAllocation(holidayAllocation)
  }

  return (
    <>
      <FormGroup label="Year" name="year">
        <Field
          component={Select}
          simpleValue
          options={YEAR_OPTIONS}
          onChange={(v) => {
            setFieldValue("year", v ? v.value : null, false);
            setFieldValue("holiday", null, false);
            setFieldValue("selectedDays", []);
          }}
          name="year"
        />
      </FormGroup>
      <FormGroup label="Holiday" name="holiday">
        <Field
          component={Select}
          labelKey="holiday"
          valueKey="holiday"
          options={holidays}
          name="holiday"
          onChange={(v) => {
            setFieldValue("holiday", v, false);
            setFieldValue("selectedDays", []);
          }}
        />
      </FormGroup>
      {holiday && (holiday !== EXTRA_SESSIONS && !holidayAllocation) ? (
        <HolidaySetup year={year} holiday={holiday.holiday} onSubmitted={onHolidaySetupSubmitted} />
      ) : null}
      {holiday && (holidayAllocation || holiday === EXTRA_SESSIONS) ? (
        <FormGroup label={label} name="selectedDays">
          <div className="form-control-plaintext">
            <Field
              disabledDays={disabledDays}
              name="selectedDays"
              component={DayPicker}
            />
          </div>
        </FormGroup>
      ) : null}
    </>
  );
};

export const StartSession = ({ groupId, onBulkCreated, ...props }) => {
  const {
    Modal,
    isOpen,
    setIsOpen,
    ModalBody,
    ModalHeader,
    ModalFooter,
  } = useModal();
  const { history } = useRouter();
  const client = useClient();

  const url = groupId ? `groups/${groupId}/sessions` : "sessions";

  async function onSubmit({ selectedDays, holiday, year }, { setSubmitting }) {
    if (selectedDays.length === 1) {
      try {
        const { id } = await client
          .post(url, { date: selectedDays[0], holiday: holiday.holiday, year })
          .get("data");
        history.push(`/sessions/${id}`);
        setIsOpen(false);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        let failed = 0;

        await Promise.map(
          selectedDays,
          (date) =>
            client
              .post(url, { date, holiday: holiday.holiday, year })
              .catch(() => {
                failed++;
              }),
          {
            concurrency: 3,
          }
        );

        if (failed) {
          NotificationManager.error(`${failed} sessions failed be be created`);
        } else {
          NotificationManager.success(
            `${selectedDays.length} sessions created successfully`
          );
        }
        if (onBulkCreated) {
          onBulkCreated();
        }
        setIsOpen(false);
      } finally {
        setSubmitting(false);
      }
    }
  }

  return (
    <>
      {isOpen && (
        <Formik
          validate={({ selectedDays, holiday, year }) => {
            const errors = {};
            if (!selectedDays.length) errors.selectedDays = true;
            if (!year) errors.year = true;
            if (!holiday) errors.holiday = true;
            return errors;
          }}
          initialValues={{ selectedDays: [], year: THIS_YEAR, holiday: null }}
          onSubmit={onSubmit}
        >
          {({ values }) => {
            const numDays = values.selectedDays.length;
            const label = numDays
              ? `${numDays} Session Day(s) Selected`
              : `Select Session Day(s)`;
            return (
              <Modal>
                <ModalHeader>Create New Session(s)</ModalHeader>
                <ModalBody>
                  <Form label={label} url={url} client={client} />
                </ModalBody>
                <ModalFooter>
                  <Button color="light" onClick={() => setIsOpen(false)}>
                    Close
                  </Button>
                  <div className="tw-mx-auto" />
                  <SubmitButton>Confirm</SubmitButton>
                </ModalFooter>
              </Modal>
            );
          }}
        </Formik>
      )}
      <Button color="primary" onClick={() => setIsOpen(true)} {...props}>
        <span className="fa fa-plus tw-mr-1" /> Create New Session(s)
      </Button>
    </>
  );
};

export default StartSession;
