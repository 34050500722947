export const APPLICATION_STATUSES = {
  NOT_STARTED: "Not started",
  STARTED: "Started but incomplete",
  SUBMITTED: "Submitted",
};

export const APPLICATION_STATUS_CHOICES = Object.values(
  APPLICATION_STATUSES
).map((i) => ({
  label: i,
  value: i,
}));
