import React from 'react';
import {Alert, Table} from "reactstrap";
import {useQuery} from "react-query";
import {useClient, useUserContext} from "../../../../hooks";
import {Link} from "react-router-dom";
import {ConfirmButton} from "../../components/ConfirmButton";
import {NotificationManager} from "react-notifications";
import {formatDateTime} from "../../util";
import {get} from "lodash";

const DBSContact = ({ application, onSent }) => {
  const baseUrl = `applications/${application}`
  const dbsContactQueryKey = [`${baseUrl}/dbs_contact`];
  const client = useClient();
  const { isVPSupport } = useUserContext();
  const { data: dbsContact } = useQuery(dbsContactQueryKey, () => (
    client.get(...dbsContactQueryKey).get('data')
  ));

  if (!dbsContact) return null;

  const { organisation_name, organisation, dbs_contact_name, dbs_contact_email } = dbsContact;

  async function onSend() {
    await client.post(`${baseUrl}/send_dbs_form`);
    NotificationManager.success('Email sent');

    if (onSent) {
      onSent();
    }
  }

  if (!organisation) {
    return (
      <Alert color="warning">
        This application does not have a Club selected, please select from the <Link to="application_form">Application</Link> tab.
      </Alert>
    )
  }

  const organisationLink = <Link to={`/organisations/${organisation}`}>{organisation_name}</Link>

  if (!dbs_contact_name || !dbs_contact_email) {
    return (
      <Alert color="warning">
        {organisationLink} requires DBS contact name and email details.
      </Alert>
    )
  }

  return (
    <div>
      <p>
        The DBS contact for <strong>{organisationLink}</strong> is <strong>{dbs_contact_name}</strong> (<strong>{dbs_contact_email}</strong>).
      </p>
      {isVPSupport ? (
        <ConfirmButton onClick={onSend} confirmProps={{
          color: 'warning',
          children: (
            <>
              <span className="fa fa-envelope mr-2" /> Confirm send DBS check?
            </>
          )
        }}>
          <span className="fa fa-envelope mr-2" /> Send DBS check
        </ConfirmButton>
      ) : null}
    </div>
  )
}

export const DBS = ({ application, onChanged }) => {
  const { id, dbs_form } = application;
  const client = useClient();

  if (!dbs_form) {
    return <DBSContact onSent={onChanged} application={id} />
  }

  const { name, email, confirmed__label, submitted, email_sent } = dbs_form;
  const baseUrl = `applications/${id}`;

  async function onResend() {
    try {
      await client.post(`${baseUrl}/resend_dbs_form`);
    } catch (ex) {
      const code = get(ex, 'response.data.code')

      if (code === 'name_or_email_missing') {
        NotificationManager.error('DBS contact name or email missing from organisation');
        return;
      } else {
        throw ex;
      }
    }

    NotificationManager.success('Email sent');
    await onChanged();
  }

  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th></th>
          <th/>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {name}
          </td>
          <td>
            {email}
          </td>
          <td>
            <ul className="pl-0 tw-list-none text-muted">
              <li>
                {email_sent ? (
                  <>
                    <span className="fa fa-check-circle text-success" />
                    {' '}
                    email sent on <strong>{formatDateTime(email_sent)}</strong>
                  </>
                ) : (
                  <>
                    <span className="fa fa-times-circle text-danger" />
                    {' '}
                    email not sent
                  </>
                )}
              </li>
              <li>
                {submitted ? (
                  <>
                    <span className="fa fa-check-circle text-success" />
                    {' '}
                    form submitted on <strong>{formatDateTime(submitted)}</strong> with <strong>{confirmed__label}</strong> option
                  </>
                ) : (
                  <>
                    <span className="fa fa-times-circle text-danger" />
                    {' '}
                    form not submitted
                  </>
                )}
              </li>
            </ul>
          </td>
          <td>
            <ConfirmButton size="sm" onClick={onResend} confirmProps={{
              color: 'warning',
              children: (
                <>
                  <span className="fa fa-envelope mr-2" /> Confirm send?
                </>
              )
            }}>
              <span className="fa fa-envelope mr-2" /> Resend email
            </ConfirmButton>
            <p className="text-muted small">
              Resending email will update and send to the latest DBS Contact for the Organisation.
            </p>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}