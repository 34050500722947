import React from "react";
import { Button } from "reactstrap";

export const PendingButton = ({ disabled, pending, children, ...props }) => {
  return (
    <Button disabled={!!(disabled || pending)} color="tlg-teal" {...props}>
      {pending ? <span className="fa fa-spin fa-spinner tw-mr-2" /> : null}
      {children}
    </Button>
  );
};

export default PendingButton;
