import React, { useRef, useState } from "react";
import { Page } from "../../components/common";
import { useClient } from "../../hooks";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Alert, Button } from "reactstrap";
import {
  Checkbox,
  FormGroup,
  Radio,
  SubmitButton,
} from "../../@coherent/react-hook-form";
import { TextQuestion } from "../applications/components/TextQuestion";
import { TextareaQuestion } from "../applications/components/TextareaQuestion";
import { QUESTIONS } from "./constants";
import logo from "./assets/logo.png";

function RadioOptions({ name, required, options, inline }) {
  const radios = options.map((i, idx) => {
    const { label, value, id } = i;
    return (
      <Radio
        key={idx}
        name={name}
        label={label}
        id={id || `${name}_${value}`}
        value={value}
        rules={
          idx === options.length - 1
            ? {
                required,
              }
            : {}
        }
      />
    );
  });

  return <div>{radios}</div>;
}

const agreementLevels = [
  { label: "Strongly Agree", value: "strongly_agree" },
  { label: "Agree", value: "agree" },
  { label: "Neither Agree or Disagree", value: "neither_agree_or_disagree" },
  { label: "Disagree", value: "disagree" },
  { label: "Strongly Disagree", value: "strongly_disagree" },
];

function SurveyFields1() {
  const { watch, setValue, trigger } = useFormContext();
  const anonymous = watch("anonymous");

  return (
    <>
      <TextQuestion
        name="name"
        label={QUESTIONS["name"]}
        required={!anonymous}
        readOnly={anonymous}
      />
      <FormGroup
        className="tw--mt-2"
        check
        label="Tick this box if you prefer your answers to be anonymous"
        htmlFor="anonymous"
      >
        <Checkbox
          onChange={() => {
            setValue("name", "");
            setTimeout(() => {
              trigger("name");
            }, 100);
          }}
          name="anonymous"
          className="form-check-input"
          id="anonymous"
        />
      </FormGroup>
      <FormGroup name="gender" required label={QUESTIONS["gender"]}>
        <RadioOptions
          name="gender"
          options={[
            {
              label: "Male",
              value: "male",
            },
            {
              label: "Female",
              value: "female",
            },
            {
              label: "Other",
              value: "other",
            },
            {
              label: "Prefer not to say",
              value: "prefer_not_to_say",
            },
          ]}
          required
        />
      </FormGroup>
      <FormGroup name="how_long" required label={QUESTIONS["how_long"]}>
        <RadioOptions
          name="how_long"
          options={[
            {
              label: "This is my first time",
              value: "first_time",
            },
            {
              label: "Less than 1 year",
              value: "less_than_1_year",
            },
            {
              label: "1-3 years",
              value: "1_3_years",
            },
            {
              label: "More than 3 years",
              value: "more_than_3_years",
            },
          ]}
          required
        />
      </FormGroup>
    </>
  );
}

function SurveyFields2() {
  return (
    <>
      <FormGroup
        label={QUESTIONS["agreement_food_nutritious"]}
        required
        name="agreement_food_nutritious"
        labelClassName="tw-font-semibold"
      >
        <RadioOptions
          inline
          name="agreement_food_nutritious"
          options={agreementLevels}
          required
        />
      </FormGroup>
      <FormGroup
        label={QUESTIONS["agreement_activities_engaging"]}
        required
        name="agreement_activities_engaging"
        labelClassName="tw-font-semibold"
      >
        <RadioOptions
          inline
          name="agreement_activities_engaging"
          options={agreementLevels}
          required
        />
      </FormGroup>
      <FormGroup
        label={QUESTIONS["agreement_vital_support"]}
        required
        name="agreement_vital_support"
        labelClassName="tw-font-semibold"
      >
        <RadioOptions
          inline
          name="agreement_vital_support"
          options={agreementLevels}
          required
        />
      </FormGroup>
      <FormGroup
        label={QUESTIONS["agreement_volunteers_friendly"]}
        required
        name="agreement_volunteers_friendly"
        labelClassName="tw-font-semibold"
      >
        <RadioOptions
          inline
          name="agreement_volunteers_friendly"
          options={agreementLevels}
          required
        />
      </FormGroup>
      <FormGroup
        label={QUESTIONS["agreement_community"]}
        required
        name="agreement_community"
        labelClassName="tw-font-semibold"
      >
        <RadioOptions
          inline
          name="agreement_community"
          options={agreementLevels}
          required
        />
      </FormGroup>
      <FormGroup
        label={QUESTIONS["agreement_wellbeing"]}
        required
        name="agreement_wellbeing"
        labelClassName="tw-font-semibold"
      >
        <RadioOptions
          inline
          name="agreement_wellbeing"
          options={agreementLevels}
          required
        />
      </FormGroup>
      <FormGroup
        label={QUESTIONS["agreement_attend_activities"]}
        required
        name="agreement_attend_activities"
        labelClassName="tw-font-semibold"
      >
        <RadioOptions
          inline
          name="agreement_attend_activities"
          options={agreementLevels}
          required
        />
      </FormGroup>
      <FormGroup
        label={QUESTIONS["agreement_recommend"]}
        required
        name="agreement_recommend"
        labelClassName="tw-font-semibold"
      >
        <RadioOptions
          inline
          name="agreement_recommend"
          options={agreementLevels}
          required
        />
      </FormGroup>
    </>
  );
}

function SurveyFields3() {
  return (
    <>
      <TextareaQuestion
        name="appreciate_most"
        label={QUESTIONS["appreciate_most"]}
        labelClassName="tw-font-semibold"
      />
      <TextareaQuestion
        labelClassName="tw-font-semibold"
        name="suggestions"
        label={QUESTIONS["suggestions"]}
      />
      <TextareaQuestion
        name="memorable_moment"
        label={QUESTIONS["memorable_moment"]}
        labelClassName="tw-font-semibold"
      />
      <TextareaQuestion
        name="favourite_foods_activities_games"
        label={QUESTIONS["favourite_foods_activities_games"]}
        labelClassName="tw-font-semibold"
      />
      <TextareaQuestion
        name="anything_else"
        label={QUESTIONS["anything_else"]}
        labelClassName="tw-font-semibold"
      />
    </>
  );
}

export function SurveyFields() {
  return (
    <>
      <SurveyFields1 />
      <hr />
      <SurveyFields2 />
      <hr />
      <SurveyFields3 />
    </>
  );
}

export function SurveyStep({
  children,
  subheading,
  step,
  defaultValues,
  onNext,
  onBack,
  onSubmit,
  text,
}) {
  const form = useForm({
    mode: "all",
    defaultValues,
  });
  const { handleSubmit } = form;

  return (
    <FormProvider {...form}>
      {subheading ? <p className="tw-font-semibold">{subheading}</p> : null}
      {step ? (
        <p className="small text-muted tw--mt-4">Step {step} of 3</p>
      ) : null}
      {text ? <p>{text}</p> : null}
      {children}
      <div className="tw-flex">
        {onBack ? (
          <Button color="primary" outline onClick={onBack}>
            Back
          </Button>
        ) : null}
        {onNext ? (
          <Button
            color="primary"
            onClick={handleSubmit(onNext)}
            className="ml-auto"
          >
            Next
          </Button>
        ) : null}
        {onSubmit ? (
          <SubmitButton
            color="primary"
            onClick={handleSubmit(onSubmit)}
            className="ml-auto"
          >
            Submit
          </SubmitButton>
        ) : null}
      </div>
    </FormProvider>
  );
}

export function Survey({ match }) {
  const { params } = match;
  const { organisation_hashid } = params;
  const client = useClient();
  const { t } = useTranslation();
  const url = "surveys";
  const organisationUrl = `${url}/organisation/${organisation_hashid}`;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const [result, setResult] = useState({});
  const titleRef = useRef();

  const { data: organisation } = useQuery({
    queryKey: [organisationUrl],
    queryFn: () => client.get(organisationUrl).get("data"),
  });

  function updateResult(values) {
    const newResult = {
      ...result,
      ...values,
    };
    setResult(newResult);
    return newResult;
  }

  async function onSubmit(x) {
    const result = updateResult(x);
    await client.post(url, {
      ...result,
      organisation_hashid,
    });
    setIsSubmitted(true);
    setResult({});
    setStep(0);
  }

  if (organisation && organisation.organisation === null) {
    return (
      <Page title={t("Welcome to Make Lunch")}>
        <Alert color="warning">
          <Trans>
            This link is not valid, please check with the intended organisation
          </Trans>
        </Alert>
      </Page>
    );
  }

  if (!organisation) {
    return null;
  }

  function handlePreviousStep() {
    setStep(step - 1);
    titleRef.current.scrollIntoView();
  }

  function handleNextStep(values) {
    updateResult(values);
    setStep(step + 1);
    titleRef.current.scrollIntoView();
  }

  return (
    <Page>
      <div className="text-center mb-4" ref={titleRef}>
        <p className="tw-text-xl mb-0">
          <strong className="lg:tw-inline tw-block lg:tw-text-2xl">
            {organisation.organisation}
          </strong>{" "}
          in partnership with
        </p>
        <img
          src={logo}
          className="img-fluid"
          alt="TLG Make Lunch"
          width="250"
        />
      </div>

      {isSubmitted ? (
        <SurveyStep
          subheading="Thank you so much for taking the time to share your reflections with us."
          text="We look forward to seeing you at Make Lunch again soon."
        />
      ) : (
        <>
          {step === 0 && (
            <>
              <p>
                Thanks for taking the time to fill in this survey - we look
                forward to hearing your feedback and ideas!
              </p>
              <div className="tw-flex tw-justify-end">
                <Button color="primary" onClick={() => setStep(1)}>
                  Start Survey
                </Button>
              </div>
            </>
          )}
          {step === 1 && (
            <SurveyStep
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              defaultValues={result}
              step={1}
            >
              <SurveyFields1 />
            </SurveyStep>
          )}
          {step === 2 && (
            <SurveyStep
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              defaultValues={result}
              step={2}
            >
              <SurveyFields2 />
            </SurveyStep>
          )}
          {step === 3 && (
            <SurveyStep
              onSubmit={onSubmit}
              onBack={handlePreviousStep}
              defaultValues={result}
              step={3}
            >
              <SurveyFields3 />
            </SurveyStep>
          )}
        </>
      )}
    </Page>
  );
}
