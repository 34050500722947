import { FormProvider, useForm } from "react-hook-form";
import React, {useRef, useState} from "react";
import {SubmitButton} from "../../../@coherent/react-hook-form";

export const ConfirmButton = ({ onClick: onClickProp, timeout=5000, confirmProps, ...props }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const [confirmPending, setConfirmPending] = useState(false);
  const timeoutRef = useRef();

  function onClick() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!confirmPending) {
      setConfirmPending(true);

      timeoutRef.current = setTimeout(() => {
        setConfirmPending(false);
      }, timeout);
      return;
    }
    setConfirmPending(false);
    return onClickProp();
  }

  if (confirmPending) {
    props = {
      ...props,
      ...confirmProps
    }
  }

  return (
    <FormProvider {...methods}>
      <SubmitButton
        onClick={handleSubmit(onClick)}
        {...props}
      />
    </FormProvider>
  );
};
